import React, { useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
// components
import Icon from '@components/Icon'
import { Button, Dropdown } from 'semantic-ui-react'

const DatePresets = ({ from, to, onSelection, open }) => {
    const { t } = useTranslation()
    const [year, setYear] = useState(moment().format('YYYY'))
    const [visible, setVisible] = useState(open || false)

    const handlePresetSelection = (month, year) => {
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        onSelection(firstDay, lastDay, year)
    }

    const isPresetSelected = (month, year) => {
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        if (from === firstDay && lastDay === to) {
            return true
        }

        return false
    }

    const isYearlyPresetSelected = (year) => {
        const firstDay = moment(year + '-01-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-12-31')
            .endOf('month')
            .format('YYYY-MM-DD')

        if (from === firstDay && lastDay === to) {
            return true
        }

        return false
    }

    const selectYearRange = (year) => {
        const firstDay = moment(year + '-01-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-12-31')
            .endOf('month')
            .format('YYYY-MM-DD')

        onSelection(firstDay, lastDay, year)
    }

    const selectQuarter = (qx, year) => {
        let firstDay = ''
        let lastDay = ''

        // january, february, march
        if (qx === 1) {
            firstDay = moment(year + '-01-01')
                .startOf('month')
                .format('YYYY-MM-DD')

            lastDay = moment(year + '-03-01')
                .endOf('month')
                .format('YYYY-MM-DD')
        }

        // april, may, jun
        if (qx === 2) {
            firstDay = moment(year + '-04-01')
                .startOf('month')
                .format('YYYY-MM-DD')

            lastDay = moment(year + '-06-01')
                .endOf('month')
                .format('YYYY-MM-DD')
        }

        // july, august, september
        if (qx === 3) {
            firstDay = moment(year + '-07-01')
                .startOf('month')
                .format('YYYY-MM-DD')

            lastDay = moment(year + '-09-01')
                .endOf('month')
                .format('YYYY-MM-DD')
        }

        // october, november, december
        if (qx === 4) {
            firstDay = moment(year + '-10-01')
                .startOf('month')
                .format('YYYY-MM-DD')

            lastDay = moment(year + '-12-01')
                .endOf('month')
                .format('YYYY-MM-DD')
        }

        onSelection(firstDay, lastDay, year)
    }

    const isQXPresetSelected = (qx, year) => {
        let firstDay = ''
        let lastDay = ''

        // determinate qx selection
        if (qx === 1) {
            firstDay = moment(year + '-01-01')
                .startOf('month')
                .format('YYYY-MM-DD')

            lastDay = moment(year + '-03-01')
                .endOf('month')
                .format('YYYY-MM-DD')
        }

        if (qx === 2) {
            firstDay = moment(year + '-04-01')
                .startOf('month')
                .format('YYYY-MM-DD')

            lastDay = moment(year + '-06-01')
                .endOf('month')
                .format('YYYY-MM-DD')
        }

        // july, august, september
        if (qx === 3) {
            firstDay = moment(year + '-07-01')
                .startOf('month')
                .format('YYYY-MM-DD')

            lastDay = moment(year + '-09-01')
                .endOf('month')
                .format('YYYY-MM-DD')
        }

        // october, november, december
        if (qx === 4) {
            firstDay = moment(year + '-10-01')
                .startOf('month')
                .format('YYYY-MM-DD')

            lastDay = moment(year + '-12-01')
                .endOf('month')
                .format('YYYY-MM-DD')
        }

        if (from === firstDay && lastDay === to) {
            return true
        }

        return false
    }

    const generate_years = () => {
        const initialYear = 2021
        const currentYear = parseInt(moment().format('YYYY'), 10)
        const years = [{ key: initialYear, text: `${initialYear}`, value: `${initialYear}` }]

        // Append to "years" array years up until the current year
        for (let year = initialYear + 1; year <= currentYear; year++) {
            years.push({ key: year, text: `${year}`, value: `${year}` })
        }

        return years
    }

    return (
        <div>
            <div style={{ marginBottom: '0.5rem', cursor: 'pointer' }} onClick={() => setVisible(!visible)}>
                <Icon
                    name={!visible ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>{t('show_date_presets')}</span>
            </div>

            {visible && (
                <>
                    <div style={{ marginBottom: '0.5rem' }}>
                        {t('selected_year')}:
                        <Dropdown
                            style={{ marginLeft: '0.5rem' }}
                            inline
                            options={generate_years()}
                            value={year.toString()}
                            onChange={(e, { value }) => setYear(value)}
                        />
                    </div>

                    <Button.Group basic size="small" fluid style={{ borderRadius: 0 }} compact>
                        <Button
                            type="button"
                            active={isPresetSelected('01', year)}
                            onClick={() => handlePresetSelection('01', year)}
                        >
                            {t('january')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('02', year)}
                            onClick={() => handlePresetSelection('02', year)}
                        >
                            {t('february')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('03', year)}
                            onClick={() => handlePresetSelection('03', year)}
                        >
                            {t('march')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('04', year)}
                            onClick={() => handlePresetSelection('04', year)}
                        >
                            {t('april')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('05', year)}
                            onClick={() => handlePresetSelection('05', year)}
                        >
                            {t('may')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('06', year)}
                            onClick={() => handlePresetSelection('06', year)}
                        >
                            {t('june')}
                        </Button>
                    </Button.Group>
                    <Button.Group basic size="small" fluid style={{ marginTop: '0.5rem', borderRadius: 0 }} compact>
                        <Button
                            type="button"
                            active={isPresetSelected('07', year)}
                            onClick={() => handlePresetSelection('07', year)}
                        >
                            {t('july')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('08', year)}
                            onClick={() => handlePresetSelection('08', year)}
                        >
                            {t('august')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('09', year)}
                            onClick={() => handlePresetSelection('09', year)}
                        >
                            {t('september')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('10', year)}
                            onClick={() => handlePresetSelection('10', year)}
                        >
                            {t('october')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('11', year)}
                            onClick={() => handlePresetSelection('11', year)}
                        >
                            {t('november')}
                        </Button>
                        <Button
                            type="button"
                            active={isPresetSelected('12', year)}
                            onClick={() => handlePresetSelection('12', year)}
                        >
                            {t('december')}
                        </Button>
                    </Button.Group>
                    <Button.Group
                        basic
                        size="small"
                        fluid
                        style={{ marginTop: '0.5rem', borderRadius: 0, marginBottom: '1rem' }}
                        compact
                    >
                        <Button
                            type="button"
                            width="10"
                            active={isYearlyPresetSelected(year)}
                            onClick={() => selectYearRange(year)}
                        >
                            {t('year_range') + ' - ' + year}
                        </Button>
                        <Button
                            type="button"
                            active={isQXPresetSelected(1, year)}
                            onClick={() => selectQuarter(1, year)}
                        >
                            {t('Q1')}
                        </Button>
                        <Button
                            type="button"
                            active={isQXPresetSelected(2, year)}
                            onClick={() => selectQuarter(2, year)}
                        >
                            {t('Q2')}
                        </Button>
                        <Button
                            type="button"
                            active={isQXPresetSelected(3, year)}
                            onClick={() => selectQuarter(3, year)}
                        >
                            {t('Q3')}
                        </Button>
                        <Button
                            type="button"
                            active={isQXPresetSelected(4, year)}
                            onClick={() => selectQuarter(4, year)}
                        >
                            {t('Q4')}
                        </Button>
                    </Button.Group>
                </>
            )}
        </div>
    )
}

export default DatePresets
