import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useQueryPage, useIsMount } from '@helpers/hooks'
// store
import { API } from '@store/config'
import { routes } from '@routes'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import { Grid, Header, Modal, Divider } from 'semantic-ui-react'
import SpinnerSegment from '@components/SpinnerSegment'
import EmptyRow from '@components/tables/EmptyRow'
import SortedFileRenderer from '@components/SortedFileRenderer'
import MKEditor from '@components/editors/MKEditor'
import AvatarList from '@components/AvatarList'
import NotificationsFormAssign from './NotificationsFormAssign'
import LabelsList from '@components/lists/LabelsList'
import { Link } from 'react-router-dom'

function NotificationDetail({ openedDetail, closeDetail, setVisible }) {
    const { t } = useTranslation()
    const isMount = useIsMount()
    let page = useQueryPage()
    page = isMount ? 1 : page

    const dateFormat = useSelector((state) => state?.date_format || 'YYYY-MM-DD')
    const canManage = openedDetail?.verb === 'new_case'
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [endpoint, setEndpoint] = useState('')
    const [endpointLabels, setEndpointLabels] = useState('')
    const [linkTo, setLinkTo] = useState('')

    useEffect(() => {
        async function getCase() {
            setLoading(true)
            const request = await requests.get(API.CASES + openedDetail?.id)

            if (request.status === 200) {
                setData(request.response)
                setEndpoint(API.CASES)
                setEndpointLabels(API.CASES + 'labels/')
                setLinkTo(routes.CASES)
            }
            setLoading(false)
        }

        async function getIssue() {
            setLoading(true)
            const request = await requests.get(API.PROJECT_ISSUES + openedDetail?.id)

            if (request.status === 200) {
                setData(request.response)
                setEndpoint(API.PROJECT_ISSUES)
                setEndpointLabels(API.PROJECT_LABELS + `?project=${request?.response?.project?.id}`)
                setLinkTo(routes.PROJECT_DETAIL + request?.response?.project?.id + '/')
            }
            setLoading(false)
        }

        async function getTask() {
            setLoading(true)
            const request = await requests.get(API.TASKS + openedDetail?.id)

            if (request.status === 200) {
                setData(request.response)
                setEndpoint(API.TASKS)
                //setEndpointLabels(API.PROJECT_LABELS + `?project=${request?.response?.project?.id}`)
                setLinkTo(routes.TASKS)
            }
            setLoading(false)
        }

        if (openedDetail?.id && (openedDetail?.verb === 'new_case' || openedDetail?.verb === 'case_assign')) getCase()
        if (openedDetail?.id && openedDetail?.verb === 'issue_assign') getIssue()
        if (openedDetail?.verb === 'task_assigned_person_assign') getTask()
    }, [openedDetail])

    const onRemoveAssigned = async (id) => {
        const request = await requests.patch(API.CASES + openedDetail?.id + '/', {
            assigned_to: { remove: [id] },
        })

        if (request.status === 200) {
            const newList = data.assigned_to.filter((item) => item.id !== id)
            setData({ ...data, assigned_to: newList })
        }
    }

    return (
        <Modal
            key={openedDetail.id}
            //closeIcon
            size={'tiny'}
            open={openedDetail.id}
            onClose={() => closeDetail(openedDetail)}
        >
            <Modal.Header>
                <Grid style={{ padding: 0 }}>
                    <Grid.Row columns="2" style={{ padding: 0 }}>
                        <Grid.Column textAlign="left" width={'10'} style={{ padding: 0 }}>
                            {t(openedDetail?.verb)}
                        </Grid.Column>
                        <Grid.Column textAlign="right" width={'6'} style={{ padding: 0, fontSize: '1rem' }}>
                            <Link to={linkTo} className="ref-link" target="_blank">
                                {openedDetail?.type === 'case'
                                    ? t('go_to') + ' ' + t('cases')
                                    : openedDetail?.type === 'issue'
                                    ? t('go_to') + ' ' + t('issues')
                                    : openedDetail?.type === 'task'
                                    ? t('go_to') + ' ' + t('tasks')
                                    : ''}
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Header>
            <Modal.Content style={{ paddingTop: '0.5rem' }}>
                <SpinnerSegment loading={loading}>
                    <Header as="h3" style={{ marginBottom: '0.5rem' }}>
                        <Grid style={{ color: 'grey', fontSize: '0.8rem', padding: 0, paddingBottom: '0.4rem' }}>
                            <Grid.Column textAlign="left" style={{ padding: 0, height: '0.9rem' }}>
                                <span>
                                    {t('created') +
                                        ' ' +
                                        tzDateTime(openedDetail?.timestamp).format(dateFormat + ' HH:mm') +
                                        ' ' +
                                        t('by') +
                                        ' ' +
                                        openedDetail?.actor}
                                </span>
                            </Grid.Column>
                        </Grid>

                        {data?.title || data?.name}
                    </Header>
                    <Grid>
                        <Grid.Row columns={2} style={{ padding: 0 }}>
                            <Grid.Column width={'9'} style={{ padding: 0 }}>
                                {(data?.label?.length > 0 || data?.labels?.length > 0) && (
                                    <div style={{ marginTop: '0.4rem' }}>
                                        <LabelsList
                                            key={data.label || data.labels}
                                            rowID={data.id}
                                            label_key="label"
                                            labels={data.label || data.labels}
                                            endpoint={endpoint}
                                            endpointLabels={endpointLabels}
                                            cantManage={!canManage}
                                        />
                                    </div>
                                )}
                            </Grid.Column>
                            <Grid.Column textAlign="right" width={'7'} style={{ padding: 0 }}>
                                {(data?.assigned?.length > 0 || data?.assigned_to || canManage) && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'right',
                                            marginRight: canManage ? '-2rem' : '-1rem',
                                            marginTop: data?.assigned_to?.id ? '-2rem' : '',
                                        }}
                                    >
                                        <AvatarList
                                            size="25"
                                            alignDirection="start"
                                            allowAdditions
                                            canManage={canManage}
                                            persons={
                                                data?.assigned_to
                                                    ? data?.assigned_to?.id
                                                        ? [
                                                              {
                                                                  ID: data?.assigned_to?.id,
                                                                  name: data?.assigned_to?.fullname,
                                                                  avatar: data?.assigned_to?.profile_picture,
                                                                  displayName: data?.assigned_to?.fullname,
                                                              },
                                                          ]
                                                        : data?.assigned_to?.map((item) => ({
                                                              ID: item?.id,
                                                              name: item?.fullname,
                                                              avatar: item?.profile_picture,
                                                              displayName: item?.fullname,
                                                          }))
                                                    : data?.assigned?.map((item) => ({
                                                          ID: item?.id,
                                                          name: item?.fullname,
                                                          avatar: item?.profile_picture,
                                                          displayName: item?.fullname,
                                                      }))
                                            }
                                            addForm={<NotificationsFormAssign data={data} setData={setData} />}
                                            addText={t('add_responsible_person')}
                                            onRemove={(id) => onRemoveAssigned(id)}
                                        />
                                    </div>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    {data?.task_type?.id && (
                        <div>
                            <span>{t('type') + ': '}</span>
                            <strong style={{ color: data?.task_type?.color || 'black' }}>
                                {data.task_type?.title}
                            </strong>
                        </div>
                    )}
                    {(data?.due_date || data?.to_date) && (
                        <div>
                            <span>{t('due_date') + ': '}</span>
                            <strong>{tzDateTime(data?.due_date || data?.to_date).format(dateFormat)}</strong>
                        </div>
                    )}
                    {data?.project && (
                        <div>
                            <span>{t('project') + ': '}</span>
                            <strong>{data?.project?.name}</strong>
                        </div>
                    )}
                    <Divider />
                    <MKEditor readOnly={true} markdown={data?.description} styleEditor />
                    <div style={{ minWidth: '400px', paddingTop: '1rem' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'middle',
                                marginBottom: '0.5rem',
                            }}
                        >
                            <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                {t('attachments')} ({data?.attachments?.length})
                            </span>
                        </div>
                        <Divider style={{ marginTop: 0 }} />
                        <EmptyRow length={data?.attachments?.length} />
                        {data?.attachments?.length > 0 &&
                            data?.attachments?.map((attachment) => (
                                <div
                                    key={attachment.id}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'middle',
                                        justifyContent: 'space-between',
                                        marginTop: '0.9rem',
                                    }}
                                >
                                    <SortedFileRenderer
                                        document={attachment}
                                        name={attachment.name}
                                        file={attachment.file}
                                    />
                                </div>
                            ))}
                    </div>
                </SpinnerSegment>
            </Modal.Content>
        </Modal>
    )
}

export default NotificationDetail
