import React, { Fragment, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useIsMount } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import { Label, Form, Popup } from 'semantic-ui-react'
import ShortenText from '@components/ShortenText'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SuperLabelField from '@components/forms/SuperLabelField'

const LabelsList = ({
    labels,
    mark,
    rowID,
    endpoint,
    endpointLabels,
    label_key,
    additionalFilters,
    resourcetype,
    managePerms,
    setDataLabels,
    projectPatch,
    project,
    cantManage,
}) => {
    const { t } = useTranslation()
    const isMount = useIsMount()

    const [data, setData] = useState(labels)
    const maxVisible = 2

    useEffect(() => {
        if (!isMount) {
            setData(labels)
        }
        // eslint-disable-next-line
    }, [labels])

    const onLabelRemove = async (id, labelID) => {
        const request = await requests.patch(
            endpoint + id + `/?query={id, ${label_key}}`,
            resourcetype
                ? {
                      [label_key]: {
                          remove: [labelID],
                      },
                      resourcetype: resourcetype,
                  }
                : {
                      [label_key]: {
                          remove: [labelID],
                      },
                  }
        )

        if (request.status === 200) {
            setData(request.response[label_key])
            if (setDataLabels) {
                setDataLabels((prev) =>
                    prev.filter((item) => {
                        if (item.id === rowID) {
                            item[label_key] = request.response[label_key]
                        }
                        return item
                    })
                )
            }
        }
    }

    return (
        <Fragment>
            <>
                {data?.map((label, idx) => (
                    <>
                        {idx + 1 <= maxVisible && (
                            <Label
                                size="tiny"
                                key={label.id}
                                style={{
                                    background: label.color === '' ? 'var(--light)' : label.color + '80',
                                    //padding: '0.25rem',
                                    color: 'var(--dark)',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'relative',
                                        top: '-0.05rem',
                                        fontSize: '0.8rem',
                                        color: 'var(--dark)',
                                    }}
                                >
                                    <ShortenText text={label.name} textLength={15} />
                                </span>
                                <CanView permissions={managePerms}>
                                    {!cantManage && (
                                        <Icon
                                            name="close"
                                            style={{
                                                marginLeft: '0.3rem',
                                                color: 'var(--dark)',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                            title={t('remove_label')}
                                            onClick={() => onLabelRemove(rowID, label.id)}
                                        />
                                    )}
                                </CanView>
                            </Label>
                        )}
                    </>
                ))}

                {data.length > maxVisible && (
                    <Popup
                        hoverable
                        position="left center"
                        trigger={
                            <Label size="tiny">
                                <span style={{ fontSize: '0.8rem', color: 'var(--dark)' }}>
                                    {data.length - maxVisible}{' '}
                                    {/*<span style={{ textTransform: 'lowercase' }}>
                                        {data.length - maxVisible === 1 ? t('label') : t('labels')}
                                    </span>*/}
                                    ...
                                </span>
                            </Label>
                        }
                        content={
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {data?.map((label, idx) => (
                                    <>
                                        {idx + 1 > maxVisible && (
                                            <Label
                                                size="tiny"
                                                key={label.id}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    background: label.color === '' ? 'var(--dark)' : label.color + 'B3',
                                                    marginBottom: idx + 1 === data.length ? '0' : '0.5rem',
                                                }}
                                            >
                                                <span style={{ fontSize: '0.8rem', color: 'var(--dark)' }}>
                                                    {label.name}
                                                </span>
                                                <CanView permissions={managePerms}>
                                                    {!cantManage && (
                                                        <Icon
                                                            name="close-outline"
                                                            style={{
                                                                marginLeft: '0.3rem',
                                                                color: 'var(--dark)',
                                                                cursor: 'pointer',
                                                            }}
                                                            title={t('remove_label')}
                                                            onClick={() => onLabelRemove(rowID, label.id)}
                                                        />
                                                    )}
                                                </CanView>
                                            </Label>
                                        )}
                                    </>
                                ))}
                            </div>
                        }
                    />
                )}

                <CanView permissions={managePerms}>
                    {!cantManage && (
                        <SuperDuperModal
                            trigger={
                                <Label
                                    size="tiny"
                                    style={{
                                        cursor: 'pointer',
                                        background: 'var(--light)',
                                        color: 'var(--dark)',
                                        border: '1px solid var(--variant5)',
                                    }}
                                    title={t('add_label')}
                                >
                                    <Icon name="add-outline" />
                                </Label>
                            }
                            content={
                                <LabelsForm
                                    data={data}
                                    setData={setData}
                                    endpoint={endpoint}
                                    rowID={rowID}
                                    label_key={label_key}
                                    additionalFilters={additionalFilters}
                                    resourcetype={resourcetype}
                                    mark={mark}
                                    projectPatch={projectPatch}
                                    project={project}
                                    setDataLabels={setDataLabels}
                                    endpointLabels={endpointLabels}
                                />
                            }
                        />
                    )}
                </CanView>
            </>
        </Fragment>
    )
}

const LabelsForm = ({
    data,
    setData,
    endpoint,
    rowID,
    label_key,
    additionalFilters,
    resourcetype,
    mark,
    projectPatch,
    project,
    setDataLabels,
    endpointLabels,
    onClose,
}) => {
    const { t } = useTranslation()
    const [labels, setLabels] = useState(data.map((item) => item.id))
    const [isProcessing, setIsProcessing] = useState(false)

    const onSubmit = async () => {
        setIsProcessing(true)

        const request = await requests.patch(
            endpoint + rowID + `/?query={id, ${label_key}}` + (additionalFilters || ''),
            resourcetype
                ? {
                      [label_key]: {
                          remove: data.map((item) => item.id),
                          add: labels,
                      },
                      resourcetype: resourcetype,
                      [mark]: true,
                  }
                : {
                      [label_key]: {
                          remove: data.map((item) => item.id),
                          add: labels,
                      },
                      resourcetype: resourcetype,
                  }
        )

        if (request.status === 200) {
            setData(request.response[label_key])
            if (projectPatch) {
                await requests.patch(API.PROJECTS + project + '/?query={id, labels}', {
                    labels: {
                        remove: data.map((item) => item.id),
                        add: labels,
                    },
                })
            }
            if (setDataLabels) {
                setDataLabels((prev) =>
                    prev.filter((item) => {
                        if (item.id === rowID) {
                            item[label_key] = request.response[label_key]
                        }
                        return item
                    })
                )
            }
            onClose()
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperLabelField
                value={labels}
                assignedLabels={data}
                mark={mark}
                onChange={(labels) => setLabels(labels)}
                endpoint={
                    endpointLabels
                        ? endpointLabels
                        : label_key === 'tags'
                        ? API.ACCOUNTS_LABELS
                        : label_key === 'labels'
                        ? API.PROJECT_LABELS
                        : API.CONTACT_LABELS
                }
            />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit disabled={isProcessing} loading={isProcessing} text={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export default LabelsList
