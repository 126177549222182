import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// store
import { API } from '@store/config'
import { getRequest, createRequest } from '@services/ServiceCommon'
// components
import { Header, Container, Divider, Form, Button } from 'semantic-ui-react'
import Loading from '@components/general/Loading'
import TemplateEditor from '@components/editors/template-editor/TemplateEditor'

const GDPRAgreementView = () => {
    const params = useParams()

    // eslint-disable-next-line
    const [uuid] = useState(params.uuid)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function fetchGdpr() {
            setLoading(true)
            const request = await getRequest(API.GDPRS_PUBLIC + '?uuid=' + uuid, false)
            if (request.status === 200) {
                setData(request.response[0])
            }

            setLoading(false)
        }

        fetchGdpr()
        // eslint-disable-next-line
    }, [])

    const confirmAction = async (action) => {
        const data = {
            status: action === 'accept' ? 2 : 3,
        }

        const request = await createRequest(API.GDPRS_PUBLIC + uuid + '/extend/', data, false)
        if (request.status === 200) {
            console.log(request)
        }
    }

    return (
        <div style={styles}>
            {loading ? (
                <Loading />
            ) : (
                <Container>
                    <Header as="h1" content={data.title || '--'} style={{ textAlign: 'center' }} />
                    <TemplateEditor value={data.text} readOnly={true} raw={true} />

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <Button type="button" size="large" basic onClick={() => confirmAction('reject')}>
                            Nesuhlasím
                        </Button>
                        <Button type="button" size="large" primary onClick={() => confirmAction('accept')}>
                            Suhlasím s podmienkami
                        </Button>
                    </Form.Field>
                </Container>
            )}
        </div>
    )
}

const styles = {
    margin: '2rem',
}

export default GDPRAgreementView
