import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import CanView from '@components/perms/CanView'
import { Form, Divider, Button } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import MKEditor from '@components/editors/MKEditor'

const TaskForm = ({ record, handleRequest, setData, onClose, setHeader }) => {
    const { t } = useTranslation()

    const user = useSelector((state) => state.user.id)
    const today = moment().format('YYYY-MM-DD')

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [project, setProject] = useState(null)
    const [form, setForm] = useState({
        title: '',
        name: '',
        description: '',
        priority: record?.priority || 1,
        to_date: '',
        task_date: today,
        is_closed: false,
        case: record?.id,
    })

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let data = form
        if (form.to_date === '') data['to_date'] = null
        if (form.task_date === '') data['task_date'] = today

        if (!project) {
            const request = await requests.post(API.TASKS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('task_created'),
                    animation: 'pulse',
                    time: 2000,
                })
                if (handleRequest) handleRequest(request.response, setData)
                onClose()
            }
        } else {
            data['project'] = project
            data['name'] = form.title
            data['assigned'] = { add: form.assigned_to?.length === 0 ? [] : form.assigned_to }
            const request = await requests.post(API.PROJECT_ISSUES, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('project_issue_created'),
                    animation: 'pulse',
                    time: 2000,
                })
                if (handleRequest) handleRequest(request.response, setData)
                onClose()
            }
        }

        setProcessing(false)
    }

    useEffect(() => {
        setHeader(t('make_a_task'))
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="input"
                autoFocus
                required
                label={t('name')}
                error={errors?.title?.[0] || false}
                value={form.title}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, title: value }))}
            />

            <MKEditor
                label={t('description')}
                markdown={form.description}
                onChange={(value) => setForm((prev) => ({ ...prev, description: value }))}
            />

            <div style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{t('priority')}</div>
            <Button.Group basic size="small" fluid style={{ marginBottom: '1rem' }}>
                <Button
                    type="button"
                    active={form.priority === 4}
                    onClick={() => setForm((prev) => ({ ...prev, priority: 4 }))}
                    style={{
                        borderBottom: '2px solid var(--danger)',
                        fontWeight: form.priority === 4 ? 'bold' : 'normal',
                    }}
                >
                    {t('urgent')}
                </Button>
                <Button
                    type="button"
                    active={form.priority === 3}
                    onClick={() => setForm((prev) => ({ ...prev, priority: 3 }))}
                    style={{
                        borderBottom: '2px solid var(--warning)',
                        fontWeight: form.priority === 3 ? 'bold' : 'normal',
                    }}
                >
                    {t('high')}
                </Button>
                <Button
                    type="button"
                    active={form.priority === 2}
                    onClick={() => setForm((prev) => ({ ...prev, priority: 2 }))}
                    style={{
                        borderBottom: '2px solid var(--success)',
                        fontWeight: form.priority === 2 ? 'bold' : 'normal',
                    }}
                >
                    {t('medium')}
                </Button>
                <Button
                    type="button"
                    active={form.priority === 1}
                    onClick={() => setForm((prev) => ({ ...prev, priority: 1 }))}
                    style={{
                        borderBottom: '2px solid var(--info)',
                        fontWeight: form.priority === 1 ? 'bold' : 'normal',
                    }}
                >
                    {t('low')}
                </Button>
            </Button.Group>

            <Form.Group widths="equal">
                <CanView permissions={['projects.c_manage_issues']}>
                    <SuperField
                        as="choice-select"
                        search
                        text="name"
                        label={t('project')}
                        value={project}
                        endpoint={API.PROJECTS}
                        additionalFilters="&query={id, name}"
                        onChange={(e, { value }) => setProject(value)}
                    />
                </CanView>
                <SuperField
                    as="datepicker"
                    label={t('to_date')}
                    value={form.to_date}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, to_date: value }))}
                />
            </Form.Group>

            <Form.Field>
                <SuperField
                    as="choice-select"
                    search
                    key={project}
                    multiple={project}
                    text="fullname_with_titles"
                    label={t('assigned_to')}
                    value={form.assigned_to}
                    error={errors?.assigned_to?.[0] || false}
                    endpoint={API.EMPLOYEES}
                    additionalFilters={
                        project === undefined || project === null || project === ''
                            ? '&only_basic_info=true&has_user=true&is_active=true'
                            : '&project=' + project + '&only_basic_info=true&has_user=true&is_active=true'
                    }
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, assigned_to: value }))}
                />
            </Form.Field>
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={processing || form.title === '' || form.task_date === ''}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default TaskForm
