import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider } from 'semantic-ui-react'
import EmailField from '@components/forms/common/EmailField'
import PhoneField from '@components/forms/common/PhoneField'

const BulkAccountantForm = ({ selected, setSelected, onClose, setData }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)

    const [view, setView] = useState(0)
    const [form, setForm] = useState({
        external_accountant: '',
        accountant_name: '',
        accountant_phone: '',
        accountant_email: '',
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)
        let updated = []

        let data = {
            accountant_email: form?.accountant_email || '',
            accountant_name: form?.accountant_name || '',
            accountant_phone: form?.accountant_phone || '',
            external_accountant: form?.external_accountant || null,
        }

        for (let i = 0; i < selected.length; i++) {
            const request = await requests.patch(API.CONTRACTS + selected[i].id + '/', data)
            if (request.status === 200) {
                updated.push(request.response)
            }
        }

        setData((prev) =>
            prev.filter((item) => {
                const itemToUpdate = updated.find((updatedItem) => updatedItem.id === item.id)
                if (item.id === itemToUpdate?.id) {
                    ;(item.accountant_email = itemToUpdate.accountant_email),
                        (item.accountant_name = itemToUpdate.accountant_name),
                        (item.accountant_phone = itemToUpdate.accountant_phone),
                        (item.external_accountant = itemToUpdate.external_accountant)
                }
                return item
            })
        )

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('accountant_setting_changed'),
            animation: 'pulse',
            time: 2000,
        })

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <SuperField
                    as="radio"
                    label={t('internal_singular')}
                    checked={view === 0}
                    onChange={() => {
                        setView(0)
                        setForm((prev) => ({ ...prev, external_accountant: '' }))
                    }}
                />
                <SuperField
                    as="radio"
                    label={t('external_singular')}
                    checked={view === 1}
                    onChange={() => setView(1)}
                />
            </Form.Group>
            <Divider />

            {view === 1 && (
                <>
                    <SuperField
                        search
                        required
                        as="choice-select"
                        text="name"
                        label={t('select_supplier')}
                        value={form.external_accountant}
                        endpoint={API.ACCOUNTS + 'business_details/'}
                        additionalFilters={'&query={id, name}&is_supplier=true&exclude_unit=true&only_basic_info=true'}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, external_accountant: value }))}
                    />
                    <Divider />
                </>
            )}

            <SuperField
                required
                as="input"
                label={t('person_name')}
                value={form.accountant_name}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, accountant_name: value }))}
            />

            <Form.Group widths="equal">
                <EmailField
                    required
                    label={t('email')}
                    value={form.accountant_email}
                    setValue={(e, { value }) => setForm((prev) => ({ ...prev, accountant_email: value }))}
                />
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={form.accountant_phone}
                    setValue={(e, { value }) => setForm((prev) => ({ ...prev, accountant_phone: value }))}
                />
            </Form.Group>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        (view === 0 && (form.accountant_email === '' || form.accountant_name === '')) ||
                        (view === 1 &&
                            (form.external_accountant === '' ||
                                form.accountant_email === '' ||
                                form.accountant_name === ''))
                    }
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    )
}

export default BulkAccountantForm
