import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Button } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const ContractAdditionSignForm = ({ record, setData, onClose }) => {
    const { t } = useTranslation()
    const [date, setDate] = useState(
        record?.date_of_signature ? moment(record?.date_of_signature).format('YYYY-MM-DD') : ''
    )
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)

        let request = null

        if (record?.is_draft === true) {
            let data = {}
            if (record?.currency) data.currency = record?.currency
            if (record?.salary) data.salary = record?.salary
            if (record?.per_unit) data.per_unit = record?.per_unit
            if (record?.workplace_address?.street) {
                const address = record?.workplace_address
                data.workplace_address = {
                    street: address?.street,
                    city: address?.city,
                    postcode: address?.postcode,
                    country: address?.country,
                    state: address?.state,
                    number: address?.number,
                    orientation_number: address?.orientation_number,
                }
            }
            if (record?.type) data.type = record?.type?.id
            if (record?.fond) data.fond = record?.fond
            if (record?.fond_per_unit) data.fond_per_unit = record?.fond_per_unit
            if (record?.work_position) data.work_position = record?.work_position?.id
            if (record?.work_level) data.work_level = record?.work_level

            if (record?.is_indefinite_period) {
                data.valid_until = null
                data.status = 1
            } else {
                if (record?.valid_until) {
                    data.valid_until = record?.valid_until
                    data.status = 1
                }
            }

            const requestContract = await requests.patch(API.CONTRACTS + record.contract.id + '/', data)
            if (requestContract.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('contract_updates_applied'),
                    animation: 'bounce',
                    time: 5000,
                })
                request = await requests.patch(API.CONTRACTS + 'additions/' + record.id + '/', {
                    date_of_signature: date,
                    is_draft: false,
                })
            }
        } else {
            request = await requests.patch(API.CONTRACTS + 'additions/' + record.id + '/', {
                date_of_signature: date,
                is_draft: false,
            })
        }

        if (request?.status === 200) {
            // update table data
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === record.id) {
                        item.is_draft = request.response.is_draft
                        item.date_of_signature = request.response.date_of_signature
                    }
                    return item
                })
            )
            onClose()
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('unable_to_sign_contract_addition'),
                animation: 'pulse',
                time: 5000,
            })
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="datepicker"
                closable
                required
                label={t('date_of_signature')}
                value={date}
                onChange={(e, { value }) => setDate(value)}
            />

            <Button fluid primary loading={isProcessing} content={t('save')} disabled={isProcessing || date === ''} />
        </Form>
    )
}

export default ContractAdditionSignForm
