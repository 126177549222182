import React from 'react'
import { Switch } from 'react-router-dom'
// routes
import { routes } from '@routes/index'
import { allowedModules as modules } from '@store/permissions/index'
// routes components
import PrivateRoute from '@components/routes/PrivateRoute'
import PublicRoute from '@components/routes/PublicRoute'
// Common
import Profile from './core/profile/views/Profile'
import ProfileCardView from './core/ProfileCardView'
import ComponentExamples from './_views/ComponentExamples'
// Human Resources
import EmployeeGUI from './hr/employees/'
// import EmployeeDetail from './hr/employees/views/EmployeeDetail';
import ProfileCard from '@components/common/profile-card/ProfileCard'
import CreateAnEmployeeForm from './hr/employees/views/CreateAnEmployee'
import CandidateDetail from './hr/hiring-rooms/views/CandidateDetail'
// import HiringList from './hr/hiring-rooms/views/HiringList';
// import HiringRoom from './hr/hiring-rooms/views/HiringRoom';
import CandidatesList from './hr/candidates/views/CandidatesList'
import BenefitsList from './hr/benefits/views/BenefitsList'
import JobListing from './hr/jobs/views/JobListing'
// import JobDetail from './hr/jobs/detail/JobDetail';
import PositionList from './hr/positions/views/PositionList'
import PositionForm from './hr/positions/views/PositionForm'
import JobOfferForm from './hr/jobs/views/JobOfferForm'
//import Onboarding from './hr/onboarding/views/Onboarding'
import ApplyForms from './hr/jobs/views/ApplyForms'
import Contracts from './hr/contracts/'
import ContractAdditions from './hr/contract-additions/'
import Terminations from './hr/terminations/'
// Project Management
import TeamsDetail from './pm/teams/views/TeamsDetail'
import TeamsList from './pm/teams/views/TeamsList'
import ProjectList from './pm/projects/views/ProjectList'
import ProjectDetailView from './pm/projects/v2_views/ProjectDetailView'
import DetailView from './pm/projects/views/DetailView'
// Sales Management
import Invoices from './sm/invoices_v2/index'
// Misc
import Company from './core/company/views/Company'
import UnitDetail from './core/company/views/UnitDetail'
import ReportList from './core/reports/views/ReportList'
import ReportView from './core/reports/views/ReportView'
import SettingsView from './core/settings/views/SettingsView'
import Cases from './core/cases/index'
// Contacts
import Contacts from './sm/contacts/views/Contacts'
import Approvals from './core/approvals/views/ApprovalsList'
import Assets from './core/assets/'
import CostCenters from './core/cost-centers'
import TypesDetail from './core/settings/components/types-management/TypesDetail'
//import Forecasts from './core/forecasts'
import HiringRooms from './hr/hiring-rooms'
import GDPRAgreements from './core/gdpr-agreements'
import GDPRView from '../public/gdprs/GDPRView'
import GDPRAgreementView from '../public/gdprs/GDPRAgreementView'
import Attendance from './hr/attendance'
import TimesheetsView from './pm/timesheets/views/TimesheetsView'
import Payments from './sm/payments'
import Vehicles from './core/vehicles'
import Courses from './hr/courses'
import Tasks from './pm/tasks'
import Logistics from './sm/logistics'
import LogisticImports from './sm/logistics/LogisticImports'
import Leads from './sm/leads'
import Opportunities from './sm/opportunities'
import OrdersGUI from './sm/orders/OrdersGUI'
//import Accommodations from './hr/accommodations/views/Accommodations'
import Accommodation from './hr/accommodations'
import AccommodationDetail from './hr/accommodations/views/AccommodationDetail'
import Accounts from './sm/accounts'
import AccountDetail from './sm/accounts/AccountDetail'
import Warehousing from './core/warehousing'
import Payrolls from './hr/payrolls'
import Abroads from './hr/abroads'
import VisasAndResidences from './hr/foreigners'
import B2BContracts from './sm/b2b_contracts'
import DocumentForm from './core/settings/components/templates/DocumentForm'

/*
# Add any Route you want here to extend dashboard pages
# This component contain all accessible routes for dashboard
*/
const DashboardPages = () => {
    return (
        <Switch>
            {/* Component Examples */}
            <PrivateRoute exact path={routes.COMPONENTS} component={ComponentExamples} />

            <PrivateRoute exact path={routes.POSTING_STAFF} modules={modules.ABROADS} component={Abroads} />
            <PrivateRoute
                exact
                path={routes.VISAS_AND_RESIDENCES}
                modules={modules.VISAS_AND_RESIDENCES}
                component={VisasAndResidences}
            />

            {/* Contracts Pages */}
            <PrivateRoute exact path={routes.CONTRACTS} modules={modules.CONTRACTS} component={Contracts} />
            <PrivateRoute exact path={routes.ADDITIONS} modules={modules.CONTRACTS} component={ContractAdditions} />
            <PrivateRoute exact path={routes.TERMINATIONS} modules={modules.TERMINATIONS} component={Terminations} />

            {/* B2B Contracts Pages */}
            <PrivateRoute exact path={routes.B2B_CONTRACTS} modules={modules.B2B_CONTRACTS} component={B2BContracts} />

            {/* User Profile Pages */}
            <PrivateRoute exact path={routes.PROFILE} modules={modules.PROFILE} component={Profile} />

            {/* User Profile Pages */}
            <PrivateRoute exact path={routes.PROFILE_CARD} modules={modules.PROFILE} component={ProfileCardView} />

            {/* Company Pages */}
            <PrivateRoute exact path={routes.COMPANY} modules={modules.COMPANY} component={Company} />
            <PrivateRoute
                exact
                path={routes.COMPANY + 'unit/:unit_id'}
                modules={modules.COMPANY}
                component={UnitDetail}
            />

            {/* Employees Pages */}
            <PrivateRoute exact path={routes.EMPLYOEES_LIST} modules={modules.EMPLOYEES} component={EmployeeGUI} />
            <PrivateRoute
                exact
                path={routes.CREATE_EMPLOYEE}
                modules={modules.EMPLOYEES}
                component={CreateAnEmployeeForm}
            />
            <PrivateRoute
                exact
                path={routes.EMPLYOEES_DETAIL + ':id'}
                modules={modules.EMPLOYEES}
                component={ProfileCard}
            />

            {/* Candidates Pages */}
            <PrivateRoute exact path={routes.CANDIDATES_LIST} modules={modules.CANDIDATES} component={CandidatesList} />
            <PrivateRoute exact path={routes.CANDIDATES_CARD + ':id'} modules={modules.CANDIDATES}>
                <ProfileCard isCandidate={true} />
            </PrivateRoute>
            <PrivateRoute
                exact
                path={routes.HIRING_ROOM + ':room' + routes.CANDIDATES_DETAIL + ':candidate'}
                modules={modules.HIRING_ROOMS}
                component={CandidateDetail}
            />

            {/* HIRING ROOMS Pages */}
            <PrivateRoute path={routes.HIRING} modules={modules.HIRING_ROOMS} component={HiringRooms} />

            {/* Benefits Pages */}
            <PrivateRoute exact path={routes.BENEFITS_LIST} module={module.BENEFITS} component={BenefitsList} />

            {/* Payrolls Pages */}
            <PrivateRoute exact path={routes.PAYROLLS} modules={modules.PAYROLLS} component={Payrolls} />

            {/* Teams Pages */}
            <PrivateRoute exact path={routes.TEAMS_LIST} modules={modules.TEAMS} component={TeamsList} />
            <PrivateRoute exact path={routes.TEAMS_DETAIL + ':id'} modules={modules.TEAMS} component={TeamsDetail} />

            {/* Project Pages */}
            <PrivateRoute exact path={routes.PROJECT_LIST} modules={modules.PROJECTS} component={ProjectList} />
            <PrivateRoute
                exact
                path={routes.PROJECT_DETAIL + ':id'}
                modules={modules.PROJECTS}
                component={DetailView}
            />
            <PrivateRoute
                exact
                path={routes.PROJECT_OLD_DETAIL + ':id'}
                modules={modules.PROJECTS}
                component={ProjectDetailView}
            />

            {/* Invoices Pages */}
            <PrivateRoute path={routes.INVOICES} modules={modules.INVOICES} component={Invoices} />

            {/* Payments Pages */}
            <PrivateRoute path={routes.PAYMENTS} modules={modules.PAYMENTS} component={Payments} />

            {/* Vehicles Pages */}
            <PrivateRoute path={routes.VEHICLES} modules={modules.VEHICLES} component={Vehicles} />

            {/* Timesheets Pages */}
            <PrivateRoute exact path={routes.TIMESHEETS} modules={modules.TIMESHEETS} component={TimesheetsView} />
            {/* <PrivateRoute exact path={routes.TIMESHEETS + ':id'} component={TimesheetsDetail} /> */}

            {/* Jobs Pages */}
            <PrivateRoute exact path={routes.JOBS} modules={modules.JOBS} component={JobListing} />
            <PrivateRoute exact path={routes.JOBS_APPLY_FORMS} modules={modules.JOBS} component={ApplyForms} />
            <PrivateRoute exact path={routes.CREATE_JOB_OFFER} modules={modules.JOBS} component={JobOfferForm} />
            <PrivateRoute
                exact
                path={routes.UPDATE_JOB_OFFER + ':id'}
                modules={modules.JOBS}
                component={JobOfferForm}
            />

            {/* Jobs Position Pages */}
            <PrivateRoute exact path={routes.JOBS_POSITIONS} modules={modules.POSITIONS} component={PositionList} />
            <PrivateRoute
                exact
                path={routes.CREATE_JOB_POSITION}
                modules={modules.POSITIONS}
                component={PositionForm}
            />
            <PrivateRoute
                exact
                path={routes.UPDATE_JOB_POSITION + ':id'}
                modules={modules.POSITIONS}
                component={PositionForm}
            />

            {/* Orders Pages */}
            <PrivateRoute exact path={routes.ORDERS} modules={modules.ORDERS} component={OrdersGUI} />

            {/* Settings Pages */}
            <PrivateRoute exact path={routes.SETTINGS} modules={modules.SETTINGS} component={SettingsView} />
            <PrivateRoute exact path={routes.SETTINGS + ':slug'} modules={modules.SETTINGS} component={SettingsView} />
            <PrivateRoute
                exact
                path={routes.SETTINGS + 'templates/:id'}
                modules={modules.SETTINGS}
                component={DocumentForm}
            />
            <PrivateRoute
                exact
                path={routes.SETTINGS + 'types/:slug'}
                modules={modules.SETTINGS}
                component={TypesDetail}
            />

            {/* Accounts Pages */}
            <PrivateRoute exact path={routes.ACCOUNTS} modules={modules.ACCOUNTS}>
                <Accounts key={routes.ACCOUNTS} isSuppliers={false} />
            </PrivateRoute>

            <PrivateRoute exact path={routes.ACCOUNTS + ':id'} modules={modules.ACCOUNTS}>
                <AccountDetail isSupplier={false} key={routes.ACCOUNTS} />
            </PrivateRoute>

            {/* Suppliers Pages */}
            <PrivateRoute exact path={routes.SUPPLIERS} modules={modules.ACCOUNTS}>
                <Accounts key={routes.SUPPLIERS} isSuppliers={true} />
            </PrivateRoute>

            <PrivateRoute exact path={routes.SUPPLIERS + ':id'} modules={modules.SUPPLIERS}>
                <AccountDetail isSupplier={true} key={routes.ACCOUNTS} />
            </PrivateRoute>

            {/*<PrivateRoute exact path={routes.ONBOARDING} modules={modules.ACCOUNTS} component={Onboarding} />*/}

            {/* Reports Pages */}
            <PrivateRoute exact path={routes.REPORTS} modules={modules.REPORTS} component={ReportList} />
            <PrivateRoute exact path={routes.REPORTS + ':slug/'} modules={modules.REPORTS} component={ReportView} />

            {/* Courses Pages */}
            <PrivateRoute exact path={routes.COURSES} modules={modules.COURSES} component={Courses} />

            {/* Cases Pages */}
            <PrivateRoute exact path={routes.CASES} modules={modules.CASES} component={Cases} />

            {/* Attendance Pages */}
            <PrivateRoute path={routes.ATTENDANCE} modules={modules.ATTENDANCE} component={Attendance} />

            {/* Contacts page */}
            <PrivateRoute exact path={routes.CONTACTS} modules={modules.CONTACTS} component={Contacts} />

            {/* Approvals Pages */}
            <PrivateRoute path={routes.APPROVALS} modules={modules.APPROVALS} component={Approvals} />

            {/* Assets Pages */}
            <PrivateRoute path={routes.ASSETS} modules={modules.ASSETS} component={Assets} />

            {/* Warehousing Pages */}
            <PrivateRoute path={routes.WAREHOUSING} modules={modules.WAREHOUSING} component={Warehousing} />

            {/* Cost centers Pages */}
            <PrivateRoute path={routes.COST_CENTERS} modules={modules.COST_CENTERS} component={CostCenters} />

            {/* GDPR Pages */}
            <PrivateRoute
                exact
                path={routes.GDPR_AGREEMENTS}
                modules={modules.GDPR_AGREEMENTS}
                component={GDPRAgreements}
            />

            {/* MyTasks Pages */}
            <PrivateRoute exact path={routes.TASKS} modules={modules.TASKS} component={Tasks} />

            {/* Logistics Pages */}
            <PrivateRoute exact path={routes.LOGISTICS} modules={modules.LOGISTICS} component={Logistics} />
            <PrivateRoute
                exact
                path={routes.LOGISTICS + 'bulk-imports/'}
                modules={modules.LOGISTICS}
                component={LogisticImports}
            />

            {/* Lead and opportunities Pages */}
            <PrivateRoute exact path={routes.LEADS} modules={modules.LEADS} component={Leads} />
            <PrivateRoute exact path={routes.OPPORTUNITIES} modules={modules.LEADS} component={Opportunities} />

            {/* Accommodations Pages */}
            <PrivateRoute
                exact
                path={routes.ACCOMMODATIONS}
                modules={modules.ACCOMMODATIONS}
                component={Accommodation}
            />
            <PrivateRoute
                exact
                path={routes.ACCOMMODATIONS + ':id'}
                modules={modules.ACCOMMODATIONS}
                component={AccommodationDetail}
            />

            {/* Forecasts Pages */}
            {/*<PrivateRoute exact path={routes.FORECAST} modules={modules.FORECASTS} component={Forecasts} />*/}

            {/* Gdpr Pages */}
            <PublicRoute path={routes.GDPRS + ':id'} modules={modules.GDPRS} component={GDPRView} />
        </Switch>
    )
}

export default DashboardPages
