import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { renderShortLongAddress, formatPhoneNumber } from '@helpers/functions'
import { thousandsSeparators } from '@helpers/functions'
// components
import { Grid, Header, Divider } from 'semantic-ui-react'

const LogisticRecordDetail = ({ record, onClose }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    return (
        <>
            <Header as="h3" content={t('logistic_record_detail')} />
            <Divider />
            <Grid stackable>
                <Grid.Row style={{ padding: 0 }} columns="2" verticalAlign="top">
                    <Grid.Column style={{ padding: 0 }}>
                        <table style={{ width: '100%', textAlign: 'left' }}>
                            <tr>
                                <th>{t('commodity')}:</th>
                                <td>
                                    {record.order_commodity
                                        ? `${
                                              record.order_commodity?.item?.code
                                                  ? record.order_commodity?.item?.code
                                                  : ''
                                          } ${record.order_commodity?.item?.title}`
                                        : '--'}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('order')}:</th>
                                <td>{record.order ? `${record.order?.order_number}` : '--'}</td>
                            </tr>
                            <tr>
                                <th>{t('loading_address')}:</th>
                                <td>{renderShortLongAddress(record.loading_address)}</td>
                            </tr>
                            <tr>
                                <th>{t('delivery_address')}:</th>
                                <td>{renderShortLongAddress(record.destination_address)}</td>
                            </tr>
                            <tr>
                                <th>{t('distance')}:</th>
                                <td>{record.distance}</td>
                            </tr>
                            <tr>
                                <th>{t('transport_type')}:</th>
                                <td>{record.type_of_transport?.title || '--'}</td>
                            </tr>
                            <tr>
                                <th>{t('transport_information')}:</th>
                                <td>{record.transport_info}</td>
                            </tr>
                            <tr>
                                <th>{t('type')}:</th>
                                <td>{record.type_of_wagon}</td>
                            </tr>
                            <tr>
                                <th>
                                    {t('group')} / {t('RWB')}:
                                </th>
                                <td>{record.group}</td>
                            </tr>
                            <tr>
                                <th>{t('info_for_driver')}:</th>
                                <td>{record.info_for_driver}</td>
                            </tr>
                            <tr>
                                <th>{t('carrier')}:</th>
                                <td>{record.carrier?.name || '--'}</td>
                            </tr>
                            <tr>
                                <th>{t('contact_person')}:</th>
                                <td>{record.contact_person || '--'}</td>
                            </tr>
                            <tr>
                                <th>{t('phone')}:</th>
                                <td>
                                    {record.contact_person_phone
                                        ? formatPhoneNumber(record.contact_person_phone)
                                        : '--'}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('email')}:</th>
                                <td>{record.contact_person_email || '--'}</td>
                            </tr>
                        </table>

                        <Header
                            as="h4"
                            style={{ marginTop: '1rem', marginBottom: '0.2rem', opacity: '0.8' }}
                            content={t('notes')}
                        />
                        <p style={{ opacity: 0.8, maxWidth: '90%' }}>{record.note}</p>
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0 }}>
                        <Grid stackable>
                            <Grid.Row columns={1} style={{ padding: 0 }}>
                                <Grid.Column style={{ padding: 0 }}>
                                    <table style={{ width: '100%', textAlign: 'left' }}>
                                        <tr>
                                            <th>{t('num_of_packages')}:</th>
                                            <td>{record.package_total || '--'} x</td>
                                        </tr>
                                        <tr>
                                            <th>{t('average_package_weight')}:</th>
                                            <td>
                                                {record.average_package_weight || '--'}{' '}
                                                {record.measure_unit?.abbreviation || ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('total_weight')}:</th>
                                            <td>
                                                {record.weight_total || '--'} {record.measure_unit?.abbreviation || ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('width')}:</th>
                                            <td>{record.measurement_x || '--'}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('height')}:</th>
                                            <td>{record.measurement_y || '--'}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('depth')}:</th>
                                            <td>{record.measurement_z || '--'}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('num_of_pallets')}:</th>
                                            <td>{record.pallet_count || '--'}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('weight_without_pallet')}:</th>
                                            <td>
                                                {record.weight_without_pallet || '--'}{' '}
                                                {record.measure_unit?.abbreviation || ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <Divider />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('loading_date')}:</th>
                                            <td>
                                                {record.loading_date
                                                    ? moment(record.loading_date).format(dateFormat)
                                                    : '--'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('delivery_date_expected')}:</th>
                                            <td>
                                                {record.estimated_delivery_date
                                                    ? moment(record.estimated_delivery_date).format(dateFormat)
                                                    : '--'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('delivery_date_real')}:</th>
                                            <td>
                                                {record.delivery_date
                                                    ? moment(record.delivery_date).format(dateFormat)
                                                    : '--'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('transport_price')}:</th>
                                            <td>
                                                {record.transport_price
                                                    ? `${thousandsSeparators(record.transport_price)} ${
                                                          record.currency
                                                      }`
                                                    : '--'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('loading_price')}:</th>
                                            <td>
                                                {record.loading_price
                                                    ? `${thousandsSeparators(record.loading_price)} ${record.currency}`
                                                    : '--'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('loading_and_transport_price')}:</th>
                                            <td>
                                                {record.loading_and_transport_price
                                                    ? `${thousandsSeparators(record.loading_and_transport_price)} ${
                                                          record.currency
                                                      }`
                                                    : '--'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('paid_price')}:</th>
                                            <td>
                                                <strong>
                                                    {record.price_paid
                                                        ? `${thousandsSeparators(record.price_paid)} ${record.currency}`
                                                        : '--'}
                                                </strong>
                                            </td>
                                        </tr>
                                    </table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default LogisticRecordDetail
