import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider } from 'semantic-ui-react'

const BulkVacationFondForm = ({ selected, setSelected, onClose, setData }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)

    const [fond, setFond] = useState(0)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)
        let updated = []

        for (let i = 0; i < selected.length; i++) {
            const request = await requests.patch(API.CONTRACTS + selected[i].id + '/', {
                vacation_fond_per_year: fond,
            })
            if (request.status === 200) {
                updated.push(request.response)
            }
        }

        setData((prev) =>
            prev.filter((item) => {
                const itemToUpdate = updated.find((updatedItem) => updatedItem.id === item.id)
                if (item.id === itemToUpdate?.id) {
                    item.vacation_fond_per_year = itemToUpdate.vacation_fond_per_year
                }
                return item
            })
        )

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('available_vacation_fond_changed'),
            animation: 'pulse',
            time: 2000,
        })

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="input"
                label={t('available_vacation_fond')}
                value={fond}
                onChange={(e, { value }) => setFond(value.replace(',', '.'))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={isProcessing} text={t('save')} />
            </Form.Field>
        </Form>
    )
}

export default BulkVacationFondForm
