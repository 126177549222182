// react
import React, { useEffect, useState } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SemanticToastContainer } from 'react-semantic-toasts'
import { ErrorBoundary } from 'react-error-boundary'
// store
import '@store/i18n'
import {
    addLogo,
    addDateFormat,
    addTimezoneFormat,
    addPrefSwitchToCandidate,
    addAutoTimesheetApproval,
    addAllowJumpOverStages,
} from '@actions'
import { routes } from '@routes'
// import { googleMapsApiKey, libraries } from "@store/config";
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { getRequest } from '@services/ServiceCommon'
import { authService } from '@store/services/ServiceAuth'
import withClearCache from './store/connector/withClearCache'

// styles
import 'semantic-ui-css/semantic.min.css'
import 'react-semantic-toasts/styles/react-semantic-alert.css'
import '@styles/default.css'
import '@styles/themes.css'
import GlobalStyle from '@styles/globalStyles'

// Route Components:
import PrivateRoute from '@components/routes/PrivateRoute'
import PublicRoute from '@components/routes/PublicRoute'

// Public Pages
import Home from '@public/Home'
import Error from '@public/Error'
import GDPRView from '@public/gdprs/GDPRView'
import GdprExtendSuccess from '@public/gdprs/GdprExtendSuccess'

// Auth Pages
import Login from '@public/auth/Login'
import Activation from '@public/auth/Activation'
import ResetPassword from '@public/auth/ResetPassword'
import ConfirmResetPassword from '@public/auth/ConfirmResetPassword'
import PublicApplyForm from '@dashboard/hr/jobs/views/PublicApplyForm'

// Dashboard Pages
import Dashboard from '@dashboard/Dashboard'
import Loading from '@components/general/Loading'
import AttendanceTracker from '@dashboard/trackers/attendance'
import WarehouseTracker from '@dashboard/trackers/warehousing'
import { Container, Grid, Header, Icon, Button } from 'semantic-ui-react'
import {
    addUserPermissions,
    addAllowedModules,
    addCompany,
    addAllowEmailNotifications,
    addAllowQRScanner,
    addAllowedOrderTypes,
    addDefaultOrderTypes,
} from './store/actions'
import GDPRAgreementView from './pages/public/gdprs/GDPRAgreementView'

const SomethingWentWrong = () => {
    return (
        <Container>
            <Grid verticalAlign="middle" centered style={{ height: '100vh' }}>
                <Grid.Row>
                    <Grid.Column style={{ textAlign: 'center' }}>
                        <Icon size="huge" name="warning circle" style={{ width: '100%', color: 'var(--dark)' }} />
                        <Header as="h3">Oops! Application crashed.</Header>
                        <p>
                            We're sorry for the inconvenience and assure you we're working to fix it as fast as
                            possible. <br />
                            <strong>Stay tuned for the comeback!</strong>
                        </p>
                        <Button primary onClick={() => window.location.reload()} style={{ cursor: 'pointer' }}>
                            Reload
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

function App() {
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const mode = useSelector((state) => state?.theme || 'light')
    const user = useSelector((state) => state.user)
    const logo = useSelector((state) => state.logo)
    const company = useSelector((state) => state.company)
    const theme = useSelector((state) => state.global_pref)
    const [initLang] = useState(useSelector((state) => state.language || 'en'))

    const [initialLoad, setInitialLoad] = useState(true)

    // Setup application language
    useEffect(() => {
        i18n.changeLanguage(initLang)
    }, [i18n, initLang])

    async function fetchResources() {
        const permissions = await authService.getUserPermissions()
        if (permissions.status === 200) {
            dispatch(addUserPermissions(permissions.response))
        }

        const allowedModules = await authService.getAllowedModules()
        if (allowedModules.status === 200) {
            dispatch(addAllowedModules(allowedModules.response))
        }
    }

    async function fetchLogo() {
        if (logo === '') {
            const request = await requests.get(API.PUBLIC_LOGO, false)

            if (request.status === 200) {
                dispatch(addLogo(request?.response?.logo || null))
            }
        }
    }

    async function fetchActiveCompany() {
        if (company?.id === undefined) {
            const request = await getRequest(API.COMPANIES + '?is_active=true&query={id, logo, name}')

            if (request.status === 200) {
                if (request.response.length > 0) {
                    dispatch(addCompany(request.response[0]))
                }
            }
        }
    }

    async function fetchPreferences() {
        const globalPreferences = await requests.get(API.PREFERENCES)
        const userPreferences = await requests.get(API.USER_PREFERENCES)

        if (globalPreferences.status === 200) {
            const globalPrefResponse = globalPreferences.response

            const date_format = globalPrefResponse.find(
                (item) => item.identifier === 'date_format__global_date_format_preferences'
            )
            if (date_format) {
                dispatch(addDateFormat(date_format.value))
            }

            const order_types = globalPrefResponse.find((item) => item.identifier === 'display_orders__display_orders')
            if (order_types) {
                dispatch(addAllowedOrderTypes(order_types.value))
            }

            const order_default_types = globalPrefResponse.find(
                (item) => item.identifier === 'display_orders__display_type_orders'
            )
            if (order_default_types) {
                dispatch(addDefaultOrderTypes(order_default_types.value))
            }

            const switch_to_employee = globalPrefResponse.find(
                (item) => item.identifier === 'switch_employee_to_candidate__switch_employee_to_candidate'
            )
            if (switch_to_employee) {
                dispatch(addPrefSwitchToCandidate(switch_to_employee.value))
            }

            const auto_timesheet_approval = globalPrefResponse.find(
                (item) => item.identifier === 'approve_record_automatically__approve_timesheet_record_automatically'
            )
            if (auto_timesheet_approval) {
                dispatch(addAutoTimesheetApproval(auto_timesheet_approval.value))
            }

            const allow_qr_scanner = globalPrefResponse.find(
                (item) => item.identifier === 'allow_to_scan_qr_codes__allow_to_scan_qr_codes'
            )
            if (allow_qr_scanner) {
                dispatch(addAllowQRScanner(allow_qr_scanner.value))
            }

            const allowJumpOverStages = globalPrefResponse.find(
                (item) => item.identifier === 'candidates__allow_jump_over_stages'
            )
            if (allowJumpOverStages) {
                dispatch(addAllowJumpOverStages(allowJumpOverStages.value))
            }
        }

        if (userPreferences.status === 200) {
            const userPrefResponse = userPreferences.response

            const timezone_format = userPrefResponse.find((item) => item.identifier === 'timezone__timezone')
            if (timezone_format) {
                dispatch(addTimezoneFormat(timezone_format.value))
            }

            const allowEmailNotifications = userPrefResponse.find(
                (item) => item.identifier === 'allow_sending_email_notification__allow_sending_email_notification'
            )
            if (allowEmailNotifications) {
                dispatch(addAllowEmailNotifications(allowEmailNotifications.value))
            }
        }
    }

    // fetch neccesary resources
    useEffect(() => {
        async function initialLoad() {
            setInitialLoad(true)
            const isValid = await authService.validateToken()
            if (isValid) {
                await Promise.all([fetchResources(), fetchActiveCompany(), fetchPreferences()])
            }

            await fetchLogo()
            setInitialLoad(false)
        }

        initialLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // 👇 add class to body element
        document.body.classList = ''
        document.body.classList.add(mode)
    }, [mode])

    useEffect(() => {
        if (user?.id) {
            window.pendo.initialize({
                visitor: {
                    id: user.username,
                    email: user.email,
                    role: user.role,
                    role_name: user.role_name,
                    location: window.location.href,
                    language: initLang,
                },

                account: {
                    id: window.location.hostname,
                },
            })
        }
        // eslint-disable-next-line
    }, [user])

    return (
        <>
            <GlobalStyle theme={theme} />
            <ErrorBoundary FallbackComponent={React.memo(SomethingWentWrong)}>
                {initialLoad ? (
                    <Container>
                        <Loading />
                    </Container>
                ) : (
                    <>
                        <Switch>
                            <PublicRoute exact restricted={false} path="/" component={Home} />
                            <PublicRoute exact restricted={false} path={routes.GDPRS + ':id'} component={GDPRView} />
                            <PublicRoute
                                exact
                                restricted={false}
                                path={routes.GDPRS + 'agreement/:uuid'}
                                component={GDPRAgreementView}
                            />
                            <PublicRoute exact restricted={true} path="/login/" component={Login} />
                            {user?.role === 5 ? (
                                <>
                                    <PrivateRoute path="/warehousing/" component={WarehouseTracker} />
                                    <PrivateRoute path="/attendance/" component={AttendanceTracker} />
                                </>
                            ) : (
                                <PrivateRoute path="/dashboard/" component={Dashboard} />
                            )}

                            <PublicRoute
                                exact
                                restricted={false}
                                path={routes.PUBLIC_APPLICATION_FORM + ':id'}
                                component={PublicApplyForm}
                            />
                            <PublicRoute exact restricted={false} path="/activate/:uid/:token" component={Activation} />
                            <PublicRoute exact restricted={false} path="/password-reset" component={ResetPassword} />
                            <PublicRoute
                                exact
                                restricted={false}
                                path="/password/reset/confirm/:uid/:token"
                                component={ConfirmResetPassword}
                            />
                            <PublicRoute exact restricted={false} path="/gdpr/extend/success/">
                                <GdprExtendSuccess logo={logo} />
                            </PublicRoute>
                            <PublicRoute restricted={false} exact path="*">
                                <Error code={'404'} message={'Page not found, Sorry!'} />
                            </PublicRoute>
                        </Switch>

                        <SemanticToastContainer position="top-right" />
                    </>
                )}
            </ErrorBoundary>
        </>
    )
}

export default withRouter(withClearCache(App))
