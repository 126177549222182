import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// store
import { API } from '@store/config'
import { routes } from '@routes'
import { useHasPermissions } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import Action from '@components/general/Action'
import BreadcrumbNav from '@components/BreadcrumbNav'
import SuperField from '@components/forms/SuperField'
import { Divider, Form, Button } from 'semantic-ui-react'
import SuggestionField from '@components/forms/common/SuggestionField'
// module specific Components
import Publications from './Publications'
import JobOfferForm from '../components/forms/JobOfferForm'

const JobListing = () => {
    const { t } = useTranslation()
    const canViewHiringRoom = useHasPermissions(['candidates.c_view_recruitment'])

    const breadcrumb_items = [
        // Put: name, icon and href data inside to create breadcrumb item
        { name: t('jobs'), icon: '', href: '' },
    ]

    const actions = [
        {
            component: (
                <CanView permissions={['jobs.c_manage_application_forms']}>
                    <Button
                        primary
                        as={Link}
                        size="small"
                        to={routes.JOBS_APPLY_FORMS}
                        content={
                            <span
                                style={{
                                    position: 'relative',
                                    top: '-0.2rem',
                                }}
                            >
                                {t('application_forms')}
                            </span>
                        }
                        icon={<Icon name="settings-outline" style={{ marginRight: '0.5rem' }} />}
                    />
                </CanView>
            ),
        },
    ]

    return (
        <>
            <CanView permissions={['jobs.c_view_job_offers']} redirect>
                <BreadcrumbNav items={breadcrumb_items} actions={actions} />
                <ListView
                    as="table"
                    allowSearch
                    // module="jobs"
                    isSoftDeleteAllowed
                    actionsCellWidth="2"
                    endpoint={API.JOB_OFFERS}
                    initialFilters={{
                        country: '',
                        city: '',
                        state: '',
                        title: '',
                        valid_for_disabled_person: '',
                        valid_for_graduate: '',
                        valid_for_student: '',
                        recruitment: '',
                    }}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            <SuperField
                                as="input"
                                label={t('title')}
                                value={filters.title}
                                onChange={(e, { value }) => setFilters((prev) => ({ ...prev, title: value }))}
                            />
                            <Form.Group widths="equal">
                                <SuggestionField
                                    label={t('city')}
                                    endpoint={API.JOBS + 'filter_city/'}
                                    text="city"
                                    search="city"
                                    value={filters.city}
                                    onSelect={(value) =>
                                        setFilters({
                                            ...filters,
                                            city: value,
                                        })
                                    }
                                />

                                <SuggestionField
                                    label={t('region')}
                                    endpoint={API.JOBS + 'filter_state/'}
                                    text="state"
                                    search="state"
                                    value={filters.state}
                                    onSelect={(value) =>
                                        setFilters({
                                            ...filters,
                                            state: value,
                                        })
                                    }
                                />
                            </Form.Group>
                            <SuperField
                                as="choice-select"
                                search
                                value={filters.country}
                                type="countries"
                                label={t('country')}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        country: value,
                                    })
                                }
                            />
                            <SuperField
                                as="choice-select"
                                search
                                value={filters.recruitment}
                                endpoint={API.HIRING_ROOM}
                                additionalFilters="&query={id, name}&is_active=true"
                                text="name"
                                label={t('hiring_rooms')}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        recruitment: value,
                                    })
                                }
                            />
                            <Divider />

                            <Form.Group widths="equal">
                                <SuperField
                                    as="checkbox"
                                    label={t('valid_for_disabled_person')}
                                    // width={10}
                                    checked={filters.valid_for_disabled_person}
                                    onChange={(e, data) =>
                                        setFilters({
                                            ...filters,
                                            valid_for_disabled_person: data.checked,
                                        })
                                    }
                                />
                                <SuperField
                                    as="checkbox"
                                    label={t('valid_for_graduate')}
                                    // width={10}
                                    checked={filters.valid_for_graduate}
                                    onChange={(e, data) =>
                                        setFilters({
                                            ...filters,
                                            valid_for_graduate: data.checked,
                                        })
                                    }
                                />
                                <SuperField
                                    as="checkbox"
                                    label={t('valid_for_student')}
                                    // width={10}
                                    checked={filters.valid_for_student}
                                    onChange={(e, data) =>
                                        setFilters({
                                            ...filters,
                                            valid_for_student: data.checked,
                                        })
                                    }
                                />
                            </Form.Group>
                        </>
                    )}
                    listAdditionActions={(data, setData, total, setTotal, response) => [
                        {
                            as: 'filter',
                            index: 0,
                            name: t('all'),
                            filters: {
                                is_draft: '',
                                is_published: '',
                            },
                            count: response ? response?.draft_count + response?.published_count : 0,
                        },
                        {
                            as: 'filter',
                            index: 1,
                            name: t('drafts'),
                            filters: {
                                is_draft: true,
                                is_published: '',
                            },
                            count: response?.draft_count || 0,
                        },
                        {
                            as: 'filter',
                            index: 2,
                            name: t('published'),
                            filters: {
                                is_draft: '',
                                is_published: true,
                            },
                            count: response?.published_count || 0,
                        },
                    ]}
                    actions={[
                        {
                            as: 'modal',
                            type: 'add',
                            name: t('add_job_offer'),
                            permissions: ['jobs.c_manage_job_offers'],
                            modalSize: 'small',
                            modal: <JobOfferForm />,
                        },
                        /*{
                            as: 'modal',
                            type: 'custom',
                            name: t('show_detail'),
                            icon: 'eye-outline',
                            customIconColor: 'var(--primary)',
                            permissions: ['jobs.c_view_job_offers'],
                            modalSize: 'large',
                            modal: (item) => <JobOfferPreview offer={item} />,
                        },*/
                        {
                            name: t('duplicate'),
                            type: 'custom',
                            as: 'modal',
                            icon: 'copy-outline',
                            //text: t('duplicate_offer_confirm'),
                            permissions: ['jobs.c_manage_job_offers'],
                            modalSize: 'small',
                            modal: <JobOfferForm isDuplicate={true} />,
                        },
                        {
                            as: 'modal',
                            type: 'edit',
                            name: t('edit'),
                            permissions: ['jobs.c_manage_job_offers'],
                            modalSize: 'small',
                            modal: <JobOfferForm />,
                        },
                        {
                            name: t('delete'),
                            type: 'delete',
                            as: 'delete',
                            text: t('are_you_sure'),
                            permissions: ['jobs.c_delete_job_offers'],
                        },
                    ]}
                    tableHeaders={[
                        { title: '' },
                        { title: t('title'), orderBy: 'title' },
                        { title: t('workplace') },
                        { title: t('language') },
                        { title: t('open_positions') },
                        { title: t('hiring_room') },
                        { title: t('publications') },
                    ]}
                    renderCells={(offer, setData) => [
                        {
                            width: 1,
                            content: offer.is_draft && (
                                <span style={{ color: 'var(--danger)', float: 'right', marginRight: '1rem' }}>
                                    ({t('draft')})
                                </span>
                            ),
                        },
                        {
                            width: 3,
                            content: offer.title,
                        },
                        {
                            content: (
                                <span>
                                    {offer?.address?.city && offer?.address?.country_display ? (
                                        <>
                                            <Icon name="location-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {offer?.address?.city}, {offer?.address?.country_display}
                                            </span>
                                        </>
                                    ) : (
                                        '--'
                                    )}
                                </span>
                            ),
                        },
                        { content: offer.in_language_display },
                        {
                            content: (
                                <>
                                    <Icon name="people-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                        {offer.number_of_openings}
                                    </span>
                                </>
                            ),
                        },
                        {
                            content: (
                                <>
                                    {offer.recruitment && canViewHiringRoom ? (
                                        <Link to={routes.HIRING_ROOMS + offer?.recruitment?.id}>
                                            {offer?.recruitment?.name}
                                        </Link>
                                    ) : (
                                        <>{offer?.recruitment?.id && <>{offer?.recruitment?.name} </>}</>
                                    )}
                                </>
                            ),
                        },
                        {
                            content: (
                                <>
                                    <Icon name="pricetags-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                        {offer?.publication_count > 0 ? offer?.publication_count : 0}
                                    </span>

                                    <Action
                                        as="modal"
                                        type="icon"
                                        iconColor="var(--primary)"
                                        paddingLeft="1rem"
                                        icon="eye-outline"
                                        size="large"
                                        modalSize="large"
                                        tooltip={t('show_publications')}
                                        modal={<Publications offer={offer} />}
                                    />
                                </>
                            ),
                        },
                    ]}
                />
            </CanView>
        </>
    )
}

export default JobListing
