import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SuperField from '@components/forms/SuperField'
import { Divider, Form, Header } from 'semantic-ui-react'

const NotificationsFormAssign = ({ data, setData, onClose }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        assigned_to: data?.assigned_to ? data.assigned_to.map((item) => item.id) : [],
        status: data?.status ? data.status : '',
    })

    async function handleSubmit() {
        setLoading(true)
        let new_data = {
            status: form.status,
        }

        if (form.assigned_to.length > 0) {
            new_data = {
                ...new_data,
                assigned_to: { remove: data.assigned_to.map((item) => item.id), add: form.assigned_to },
            }
        } else if (form.assigned_to.length === 0) {
            new_data = { ...new_data, assigned_to: { remove: data.assigned_to.map((item) => item.id) } }
        }

        let request = await requests.patch(`${API.CASES}${data.id}/`, new_data)

        if (request.status === 200) {
            setData({ ...data, assigned_to: request.response.assigned_to })
            onClose()
        }

        setLoading(false)
    }

    async function changeAssigned(value) {
        if (form.status === 1 && value.length > 0) {
            setForm({ ...form, assigned_to: value, status: 2 })
        } else if (form.status === 2 && value.length === 0) {
            setForm({ ...form, assigned_to: value, status: 1 })
        } else {
            setForm({ ...form, assigned_to: value })
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={t('assign_to_subject')} />

            <SuperField
                as="choice-select"
                multiple
                search
                text="fullname"
                label={t('responsible_persons')}
                additionalFilters="&only_basic_info=true&has_user=true&is_active=true"
                value={form.assigned_to}
                endpoint={API.EMPLOYEES}
                onChange={(e, { value }) => changeAssigned(value)}
                initialOptions={{
                    attribute: 'fullname',
                    source: data?.assigned_to,
                }}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit text={t('confirm')} loading={loading} />
            </Form.Field>
        </Form>
    )
}

export default NotificationsFormAssign
