import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
// store
import { API } from '@store/config'
import { getRandomColor } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import TypesManagement from './TypesManagement'
import { Container } from 'semantic-ui-react'
import CanView from '@components/perms/CanView'

const TypesDetail = () => {
    const { t } = useTranslation()
    const params = useParams()

    const languages = ['sk', 'en-us', 'cs']

    return (
        <Container fluid style={{ marginTop: '1rem' }}>
            {params.slug === 'b2b-contract-types' && (
                <CanView
                    permissions={[
                        'contracts.c_view_all_business_contracts',
                        'contracts.c_view_user_business_contracts',
                    ]}
                >
                    <TypesManagement
                        allowSoftDelete
                        title={t('b2b_contract_types')}
                        endpoint={API.B2B_CONTRACTS + 'types/'}
                        permissions={{
                            view: [
                                'contracts.c_view_all_business_contracts',
                                'contracts.c_view_user_business_contracts',
                            ],
                            manage: [
                                'contracts.c_manage_all_business_contracts',
                                'contracts.c_manage_user_business_contracts',
                            ],
                            delete: ['contracts.c_delete_all_business_contracts'],
                        }}
                        definitions={[
                            {
                                name: t('title'),
                                key: 'title',
                                as: 'input',
                                isRequired: true,
                            },
                        ]}
                    />
                </CanView>
            )}
            {params.slug === 'b2b-contract-categories' && (
                <CanView
                    permissions={[
                        'contracts.c_view_all_business_contracts',
                        'contracts.c_view_user_business_contracts',
                    ]}
                >
                    <TypesManagement
                        allowSoftDelete
                        title={t('b2b_contract_categories')}
                        endpoint={API.B2B_CONTRACTS + 'categories/'}
                        permissions={{
                            view: [
                                'contracts.c_view_all_business_contracts',
                                'contracts.c_view_user_business_contracts',
                            ],
                            manage: [
                                'contracts.c_manage_all_business_contracts',
                                'contracts.c_manage_user_business_contracts',
                            ],
                            delete: ['contracts.c_delete_all_business_contracts'],
                        }}
                        definitions={[
                            {
                                name: t('title'),
                                key: 'title',
                                as: 'input',
                                isRequired: true,
                            },
                        ]}
                    />
                </CanView>
            )}
            {params.slug === 'bonus-types' && (
                <CanView permissions={['payrolls.c_view_all_payrolls']}>
                    <TypesManagement
                        allowSoftDelete
                        title={t('bonus_types')}
                        endpoint={API.PAYROLLS + 'bonus_types/'}
                        permissions={{
                            view: ['payrolls.c_view_all_payrolls'],
                            manage: ['payrolls.c_manage_all_payrolls'],
                            delete: ['payrolls.c_delete_all_payrolls'],
                        }}
                        definitions={[
                            {
                                name: t('title'),
                                key: 'title',
                                as: 'input',
                                isRequired: true,
                            },
                        ]}
                    />
                </CanView>
            )}
            {params.slug === 'bonuses' && (
                <CanView permissions={['payrolls.c_view_all_payrolls']}>
                    <TypesManagement
                        allowSoftDelete
                        title={t('bonuses')}
                        endpoint={API.PAYROLLS + 'bonuses/'}
                        permissions={{
                            view: ['payrolls.c_view_all_payrolls'],
                            manage: ['payrolls.c_manage_all_payrolls'],
                            delete: ['payrolls.c_delete_all_payrolls'],
                        }}
                        definitions={[
                            {
                                name: t('title'),
                                key: 'title',
                                as: 'input',
                                isRequired: true,
                            },
                            {
                                search: true,
                                name: t('bonus_type'),
                                key: 'type',
                                as: 'choice',
                                endpoint: API.PAYROLLS + 'bonus_types/',
                                text: 'title',
                                isRequired: true,
                                isNested: true,
                            },
                            {
                                name: t('sum'),
                                key: 'amount',
                                as: 'input',
                                isRequired: true,
                            },
                            {
                                search: true,
                                name: t('currency'),
                                key: 'currency',
                                as: 'choice',
                                type: 'currency_codes',
                                defaultValue: 'EUR',
                                isRequired: true,
                            },

                            {
                                name: t('note'),
                                key: 'note',
                                as: 'textarea',
                                isRequired: false,
                            },
                        ]}
                    />
                </CanView>
            )}
            {params.slug === 'hiring-sources' && (
                <CanView permissions={[]}>
                    <TypesManagement
                        allowSoftDelete
                        title={t('hiring_sources')}
                        endpoint={API.SOURCES}
                        permissions={{
                            view: [],
                            manage: ['common.c_manage_hiring_sources'],
                            delete: ['common.c_delete_hiring_sources'],
                        }}
                        definitions={[
                            {
                                name: t('sources'),
                                key: 'name',
                                as: 'input',
                                isRequired: true,
                            },
                        ]}
                    />
                </CanView>
            )}
            {params.slug === 'cases-types' && (
                <CanView permissions={['cases.c_view_all_cases', 'cases.c_view_assigned_user_cases']}>
                    <TypesManagement
                        allowSoftDelete
                        title={t('cases_types')}
                        endpoint={API.CASES_TYPES}
                        permissions={{
                            view: ['cases.c_view_all_cases', 'cases.c_view_assigned_user_cases'],
                            manage: ['cases.c_manage_all_cases'],
                            delete: ['cases.c_manage_all_cases'],
                        }}
                        definitions={[
                            {
                                name: t('cases_types'),
                                key: 'title',
                                as: 'input',
                                isRequired: true,
                            },
                        ]}
                    />
                </CanView>
            )}
            {params.slug === 'person-types' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('person_types')}
                    endpoint={API.PERSON_TYPE}
                    languages={languages}
                    permissions={{
                        view: [],
                        manage: [],
                        delete: [],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isTranslatable: true,
                            isRequired: true,
                        },
                    ]}
                />
            )}
            {params.slug === 'phone-types' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('phone_types')}
                    endpoint={API.PHONE_TYPES}
                    languages={languages}
                    permissions={{
                        view: [],
                        manage: [],
                        delete: [],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                        {
                            name: t('icon'),
                            key: 'icon',
                            as: 'choice',
                            icon: true,
                            customOptions: [
                                { key: 'phone', value: 'call-outline', text: <Icon name="call-outline" /> },
                                {
                                    key: 'mobile',
                                    value: 'phone-portrait-outline',
                                    text: <Icon name="phone-portrait-outline" />,
                                },
                            ],
                        },
                    ]}
                />
            )}
            {params.slug === 'unit-types' && (
                <TypesManagement
                    title={t('phone_types')}
                    endpoint={API.UNIT_TYPES}
                    permissions={{
                        view: [
                            'company.c_view_active_company',
                            'company.c_view_inactive_companies',
                            'company.c_view_units',
                        ],
                        manage: [
                            'company.c_add_company',
                            'company.c_change_active_company',
                            'company.c_change_inactive_companies',
                            'company.c_manage_units',
                        ],
                        delete: [
                            'company.c_add_company',
                            'company.c_change_active_company',
                            'company.c_change_inactive_companies',
                            'company.c_manage_units',
                        ],
                    }}
                    definitions={[
                        {
                            name: t('unit_types'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}
            {params.slug === 'isco' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('isco')}
                    endpoint={API.JOBS + 'isco/'}
                    permissions={{
                        view: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts'],
                        manage: ['contracts.c_manage_all_contracts'],
                        delete: ['contracts.c_manage_all_contracts'],
                    }}
                    definitions={[
                        {
                            name: t('code'),
                            key: 'code',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('name'),
                            key: 'fullname',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('country'),
                            key: 'country',
                            as: 'choice',
                            type: 'countries',
                            isRequired: true,
                            search: true,
                        },
                        {
                            name: t('shortname'),
                            key: 'shortname',
                            as: 'input',
                            isRequired: false,
                        },
                    ]}
                />
            )}
            {params.slug === 'contract-types' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('contract_types')}
                    endpoint={API.CONTRACTS_TYPES}
                    permissions={{
                        view: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts'],
                        manage: ['contracts.c_manage_all_contracts'],
                        delete: ['contracts.c_manage_all_contracts'],
                    }}
                    definitions={[
                        {
                            name: t('contract_types'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('fond'),
                            key: 'fond',
                            as: 'input',
                            isRequired: false,
                        },
                        {
                            name: t('work_fond_type'),
                            key: 'fond_per_unit',
                            as: 'choice',
                            type: 'fond_per_type',
                            isRequired: false,
                            search: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: false,
                        },
                    ]}
                />
            )}
            {params.slug === 'cost-centers-record-categories' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('record_categories')}
                    endpoint={API.COST_CENTERS_RECORD_CATEGORIES}
                    permissions={{
                        view: ['cost_centers.c_view_cost_centers'],
                        manage: ['cost_centers.c_manage_cost_centers'],
                        delete: ['cost_centers.c_manage_cost_centers'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('code'),
                            key: 'code',
                            as: 'input',
                        },
                        {
                            name: t('type'),
                            key: 'type',
                            as: 'choice',
                            isRequired: true,
                            customOptions: [
                                { key: 1, value: 1, text: t('income') },
                                { key: 2, value: 2, text: t('expense') },
                            ],
                        },
                    ]}
                />
            )}
            {params.slug === 'courses' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('coursess')}
                    endpoint={API.COURSES}
                    permissions={{
                        view: ['courses.c_view_all_courses', 'courses.c_view_user_courses'],
                        manage: ['courses.c_manage_all_courses'],
                        delete: ['courses.c_manage_all_courses'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('category'),
                            key: 'category',
                            as: 'choice',
                            search: true,
                            isNested: true,
                            endpoint: API.COURSES_CATEGORIES,
                            text: 'title',
                        },
                        {
                            name: t('mandatory'),
                            key: 'is_mandatory',
                            as: 'checkbox',
                        },
                    ]}
                />
            )}
            {params.slug === 'course-categories' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('course_categories')}
                    endpoint={API.COURSES_CATEGORIES}
                    permissions={{
                        view: ['courses.c_view_all_courses', 'courses.c_view_user_courses'],
                        manage: ['courses.c_manage_all_courses'],
                        delete: ['courses.c_manage_all_courses'],
                    }}
                    definitions={[
                        {
                            name: t('course_categories'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}
            {params.slug === 'timesheets-activities' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('timesheets_activities')}
                    endpoint={API.TIMESHEETS_CATEGORIES}
                    permissions={{
                        view: ['timesheets.c_manage_timesheet_categories'],
                        manage: ['timesheets.c_manage_timesheet_categories'],
                        delete: ['timesheets.c_manage_timesheet_categories'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: false,
                        },
                    ]}
                />
            )}

            {params.slug === 'logistic-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('logistic_labels')}
                    endpoint={API.LOGISTICS + 'labels/'}
                    permissions={{
                        view: ['logistics.c_view_all_transports', 'logistics.c_view_user_transports'],
                        manage: ['logistics.c_manage_all_transports', 'logistics.c_manage_user_transports'],
                        delete: ['logistics.c_manage_all_transports', 'logistics.c_manage_user_transports'],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'template-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('template_labels')}
                    endpoint={API.TEMPLATES + 'labels/'}
                    permissions={{
                        view: ['templating.c_view_documents'],
                        manage: ['templating.c_manage_documents'],
                        delete: ['templating.c_manage_documents'],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'order-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('order_labels')}
                    endpoint={API.ORDERS + 'labels/'}
                    queryParams={'&lead_tag=false'}
                    permissions={{
                        view: ['orders.c_view_order'],
                        manage: ['orders.c_manage_order'],
                        delete: ['orders.c_manage_order'],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                        {
                            name: t('order_tag'),
                            key: 'lead_tag',
                            as: 'checkbox',
                            defaultValue: false,
                            isHidden: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'project-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('project_labels')}
                    endpoint={API.PROJECTS + 'tags/'}
                    permissions={{
                        view: ['projects.c_view_all_projects'],
                        manage: ['projects.c_manage_labels'],
                        delete: ['projects.c_manage_labels'],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'hiring-rooms-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('hiring_rooms_labels')}
                    endpoint={API.CANDIDATES_LABELS}
                    permissions={{
                        view: [],
                        manage: [],
                        delete: [],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'profile-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('profile_labels')}
                    endpoint={API.CONTACT_LABELS}
                    permissions={{
                        view: [],
                        manage: [],
                        delete: [],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'account-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('account_supplier_labels')}
                    endpoint={API.ACCOUNTS + 'labels/'}
                    permissions={{
                        view: ['accounts.c_view_all_accounts'],
                        manage: ['accounts.c_manage_all_accounts'],
                        delete: ['accounts.c_manage_all_accounts'],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'cost-center-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('cost_center_labels')}
                    endpoint={API.COST_CENTERS + 'labels/'}
                    permissions={{
                        view: ['cost_centers.c_view_cost_centers'],
                        manage: ['cost_centers.c_manage_cost_centers'],
                        delete: ['cost_centers.c_manage_cost_centers'],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'task-types' && (
                <TypesManagement
                    title={t('task_types')}
                    endpoint={API.TASKS + 'task_type/'}
                    permissions={{
                        view: ['tasks.c_view_tasks'],
                        manage: ['tasks.c_manage_tasks'],
                        delete: ['tasks.c_manage_tasks'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'languages' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('languages')}
                    endpoint={API.COMMON + 'language_names/'}
                    languages={languages}
                    permissions={{
                        view: [],
                        manage: [],
                        delete: [],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'sale-sources' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('sale_sources')}
                    endpoint={API.ORDERS + 'sale_sources/'}
                    languages={languages}
                    permissions={{
                        view: [],
                        manage: ['orders.c_manage_sale_source'],
                        delete: ['orders.c_delete_sale_source'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'transport-types' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('transport_types')}
                    endpoint={API.LOGISTICS + 'transport_types/'}
                    languages={languages}
                    permissions={{
                        view: ['logistics.c_view_transport_types'],
                        manage: ['logistics.c_manage_transport_types'],
                        delete: ['logistics.c_delete_transport_types'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'measure-units' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('measure_units')}
                    endpoint={API.COMMON + 'measure_units/'}
                    permissions={{
                        view: ['common.c_view_measure_units'],
                        manage: ['common.c_manage_measure_units'],
                        delete: ['common.c_manage_measure_units'],
                    }}
                    definitions={[
                        {
                            name: t('abbreviation'),
                            key: 'abbreviation',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: false,
                        },
                    ]}
                />
            )}

            {params.slug === 'benefits-categories' && (
                <TypesManagement
                    title={t('benefits_categories')}
                    endpoint={API.BENEFITS_CATEGORIES}
                    permissions={{
                        view: ['benefits.c_view_all_benefits', 'benefits.c_view_user_benefits'],
                        manage: [],
                        delete: [],
                    }}
                    definitions={[
                        {
                            name: t('benefits_categories'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            isRequired: true,
                        },
                    ]}
                />
            )}
            {params.slug === 'interruption-reasons' && (
                <TypesManagement
                    title={t('interruption_reasons')}
                    endpoint={API.ATTENDANCE_INTERRUPTION_REASONS}
                    permissions={{
                        view: [],
                        manage: ['attendance.c_manage_interruption_reasons'],
                        delete: ['attendance.c_manage_interruption_reasons'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}
            {params.slug === 'driver-licence' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('driver_licence')}
                    endpoint={API.DRIVER_LICENCE}
                    permissions={{
                        view: [],
                        manage: ['common.c_manage_all_employees'],
                        delete: ['common.c_manage_all_candidates '],
                    }}
                    definitions={[
                        {
                            name: t('driver_group'),
                            key: 'driver_group',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}
            {params.slug === 'turnuses' && (
                <TypesManagement
                    title={t('turnuses')}
                    endpoint={API.ATTENDANCE_TURNUSES}
                    permissions={{
                        view: [],
                        manage: ['attendance.c_manage_turnuses'],
                        delete: ['attendance.c_manage_turnuses'],
                    }}
                    definitions={[
                        {
                            name: t('hours'),
                            key: 'hours',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}
            {params.slug === 'termination-reasons' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('termination_reasons')}
                    endpoint={API.CONTRACTS_TERMINATION_REASONS}
                    permissions={{
                        view: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts'],
                        manage: ['contracts.c_manage_all_contracts'],
                        delete: ['contracts.c_manage_all_contracts'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'vehicle-types' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('vehicle_types')}
                    endpoint={API.VEHICLES + 'types/'}
                    permissions={{
                        view: [],
                        manage: ['vehicles.c_manage_vehicle_types'],
                        delete: ['vehicles.c_manage_vehicle_types'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'position-groups' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('position_groups')}
                    endpoint={API.JOBS + 'position_groups/'}
                    permissions={{
                        view: ['jobs.c_view_job_positions'],
                        manage: ['jobs.c_manage_job_positions'],
                        delete: ['jobs.c_manage_job_positions'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'position-levels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('position_levels')}
                    endpoint={API.JOBS + 'position_levels/'}
                    permissions={{
                        view: ['jobs.c_view_job_positions'],
                        manage: ['jobs.c_manage_job_positions'],
                        delete: ['jobs.c_manage_job_positions'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'job-families' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('job_families')}
                    endpoint={API.JOBS + 'job_families/'}
                    permissions={{
                        view: ['jobs.c_view_job_positions'],
                        manage: ['jobs.c_manage_job_positions'],
                        delete: ['jobs.c_manage_job_positions'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'health-insurance-types' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('health_insurance_types')}
                    endpoint={API.HEALTH_INSURANCE}
                    permissions={{
                        view: [],
                        manage: [],
                        delete: [],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('code'),
                            key: 'code',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('description'),
                            key: 'description',
                            as: 'input',
                            isRequired: false,
                        },
                    ]}
                />
            )}

            {params.slug === 'certificate-types' && (
                <TypesManagement
                    title={t('certificate_types')}
                    endpoint={API.COMPANIES + 'certificate_types/'}
                    languages={languages}
                    permissions={{
                        view: ['company.c_view_certificates'],
                        manage: ['company.c_manage_certificates'],
                        delete: ['company.c_delete_certificates'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'accommodations-labels' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('accommodations_labels')}
                    endpoint={API.ACCOMMODATIONS + 'labels/'}
                    permissions={{
                        view: ['accommodations.c_view_accommodation'],
                        manage: ['accommodations.c_manage_accommodation'],
                        delete: ['accommodations.c_manage_accommodation'],
                    }}
                    definitions={[
                        {
                            name: t('name'),
                            key: 'name',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            defaultValue: getRandomColor(),
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'catalogue-item-categories' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('catalogue_item_categories')}
                    endpoint={API.CATALOGUE_ITEM_CATEGORY}
                    permissions={{
                        view: ['orders.c_view_catalogue'],
                        manage: ['orders.c_manage_catalogue'],
                        delete: ['orders.c_delete_catalogue'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            isRequired: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'access-card-categories' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('languages')}
                    endpoint={API.ACCESS_CARD_CATEGORIES}
                    languages={languages}
                    permissions={{
                        view: ['employees.c_view_access_cards'],
                        manage: ['employees.c_manage_access_cards'],
                        delete: ['employees.c_delete_access_cards'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'purposes-of-stay' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('purpose_of_stay')}
                    endpoint={API.FOREIGNERS + 'purposes_of_stay/'}
                    languages={languages}
                    permissions={{
                        view: ['common.c_view_all_candidates', 'common.c_view_all_employe'],
                        manage: ['common.c_manage_all_candidates', 'common.c_manage_all_employees'],
                        delete: ['common.c_manage_all_candidates', 'common.c_manage_all_employees'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'types-of-residence' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('type_of_residence')}
                    endpoint={API.FOREIGNERS + 'types_of_residence/'}
                    languages={languages}
                    permissions={{
                        view: ['common.c_view_all_candidates', 'common.c_view_all_employees'],
                        manage: ['common.c_manage_all_candidates', 'common.c_manage_all_employees'],
                        delete: ['common.c_manage_all_candidates', 'common.c_manage_all_employees'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                    ]}
                />
            )}

            {params.slug === 'types-of-deduction' && (
                <TypesManagement
                    allowSoftDelete
                    title={t('deduction_categories')}
                    endpoint={API.PAYROLLS + 'deduction_categories/'}
                    languages={languages}
                    permissions={{
                        view: ['payrolls.c_view_all_payrolls'],
                        manage: ['payrolls.c_manage_all_payrolls'],
                        delete: ['payrolls.c_manage_all_payrolls'],
                    }}
                    definitions={[
                        {
                            name: t('title'),
                            key: 'title',
                            as: 'input',
                            isRequired: true,
                            isTranslatable: true,
                        },
                        {
                            name: t('color'),
                            key: 'color',
                            as: 'colorpicker',
                            isRequired: false,
                        },
                    ]}
                />
            )}
        </Container>
    )
}

export default TypesDetail
