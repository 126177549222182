import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
import { useHasPermissions } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import { Popup, Form, Button, Grid, Divider, Header, Comment } from 'semantic-ui-react'
import AvatarIcon from '@components/AvatarIcon'
import SuperField from '@components/forms/SuperField'
import Action from '@components/general/Action'

const QuickNotes = ({ record, setData }) => {
    const { t } = useTranslation()
    const canManage = useHasPermissions(['orders.cases.c_manage_all_cases', 'cases.c_manage_assigned_user_cases'])
    const dateFormat = useSelector((state) => state.date_format)
    const endpoint = API.CASES + 'notes/'
    const [isVisible, setIsVisible] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [note, setNote] = useState('')

    const onDelete = async (id) => {
        let notes = record.notes
        const notesRequest = await requests.del(endpoint + id + '/')
        if (notesRequest.status === 204) {
            notes = notes.filter((item) => item.id !== id)
        }

        setData((prev) =>
            prev.map((item) => {
                if (item.id === record.id) {
                    item.notes = notes
                }
                return item
            })
        )
    }

    const handleSubmit = async () => {
        setProcessing(true)
        let request = await requests.post(endpoint, {
            content: note,
            case: record.id,
        })

        if (request.status === 201) {
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === record.id) {
                        item.notes = [...record.notes, request.response]
                    }

                    return item
                })
            )
            setNote('')
            setIsVisible(false)
        }
        setProcessing(false)
    }

    return (
        <Popup
            basic
            style={{ width: '500px', maxHeight: '500px', overflow: 'auto' }}
            position="left center"
            wide="very"
            hoverable
            trigger={
                <div>
                    <Icon name="document-text-outline" style={{ fontSize: '1.3rem' }} /> {record?.notes?.length}
                </div>
            }
            content={
                <div>
                    <Grid style={{ padding: 0 }}>
                        <Grid.Row verticalAlign="middle" columns="2" style={{ padding: 0 }}>
                            <Grid.Column style={{ textAlign: 'left', padding: 0 }}>
                                <Header as={'h4'}>
                                    {t('notes')} ({record?.notes?.length})
                                </Header>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: 'right', padding: 0 }}>
                                {canManage && (
                                    <Action
                                        as="custom"
                                        type="button"
                                        buttonText={isVisible ? t('close') : t('add_note')}
                                        iconColor={isVisible ? 'var(--danger)' : 'var(--primary)'}
                                        paddingLeft="1rem"
                                        paddingRight="1rem"
                                        onClick={() => setIsVisible(!isVisible)}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider style={{ marginTop: '0.3rem' }} />
                    {isVisible && canManage && (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <SuperField
                                    autoFocus
                                    rows="2"
                                    value={note}
                                    label={t('add_note')}
                                    fluid
                                    as="textarea"
                                    onChange={(e, { value }) => setNote(value)}
                                />
                                <Button
                                    primary
                                    size="tiny"
                                    disabled={processing || note === ''}
                                    loading={processing}
                                    content={t('confirm')}
                                />
                            </Form>
                            <Divider />
                        </>
                    )}

                    {record?.notes?.length > 0 && (
                        <div style={{ maxHeight: '470px', overflow: 'scroll' }}>
                            <Comment.Group
                                style={{ paddingBottom: '0.5rem', marginBottom: '0.5rem', minWidth: '100%' }}
                            >
                                {record.notes.map((item, idx) => (
                                    <div
                                        key={idx}
                                        style={{
                                            //paddingLeft: '1rem',
                                            marginTop: idx === 0 ? '0rem' : '1rem',
                                            borderRight: `10px solid #acddde`,
                                            background: 'var(--light)',
                                            padding: '0.5rem',
                                            borderTopRightRadius: '7px',
                                            borderBottomRightRadius: '7px',
                                        }}
                                    >
                                        <Comment style={{ paddingLeft: '0.5rem' }}>
                                            <Comment.Avatar
                                                src={
                                                    <AvatarIcon
                                                        size={40}
                                                        src={item.created_by?.profile_picture || null}
                                                        name={item.created_by?.name || 'JD'}
                                                    />
                                                }
                                            />
                                            <Comment.Content>
                                                <Comment.Author as="a">{item.created_by?.name}</Comment.Author>
                                                <Comment.Metadata>
                                                    <div>
                                                        {tzDateTime(item.created_on).format(dateFormat + ' HH:mm:ss')}
                                                    </div>
                                                    {/*canManage && (
                                                        <div
                                                            style={{
                                                                paddingLeft: '0.8rem',
                                                                paddingTop: '0.5rem',
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            <Icon
                                                                name="close"
                                                                onClick={() => onDelete(note.id)}
                                                                style={{ cursor: 'pointer', color: 'var(--danger)' }}
                                                            />
                                                        </div>
                                                    )*/}
                                                </Comment.Metadata>
                                                <Comment.Text>
                                                    <NoteContent
                                                        recordNote={item}
                                                        record={record}
                                                        setData={setData}
                                                        canManage={canManage}
                                                        endpoint={endpoint}
                                                    />
                                                </Comment.Text>
                                            </Comment.Content>
                                        </Comment>
                                    </div>
                                ))}
                            </Comment.Group>
                        </div>
                    )}

                    {record?.notes?.length === 0 && (
                        <div style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>{t('no_notes')}</div>
                    )}
                </div>
            }
        />
    )
}

const NoteContent = ({ recordNote, record, setData, canManage, endpoint }) => {
    const { t } = useTranslation()

    const [clickedToEdit, setClickedToEdit] = useState(false)
    const [note, setNote] = useState(recordNote?.content)

    const onEdit = async () => {
        let request = await requests.patch(endpoint + recordNote.id + '/', {
            content: note,
        })

        if (request.status === 200) {
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === record.id) {
                        item.notes = item.notes.map((note) => {
                            if (note.id === recordNote.id) {
                                note = {
                                    ...note,
                                    content: request.response.content,
                                }
                            }
                            return note
                        })
                    }

                    return item
                })
            )
            setClickedToEdit(false)
        }
    }

    return (
        <>
            <div>
                {clickedToEdit ? (
                    <Form onSubmit={(e) => e.preventDefault()} style={{ marginBottom: 0 }}>
                        <Form.Field style={{ marginBottom: 0, paddingBottom: 0 }}>
                            <SuperField
                                as="textarea"
                                rows="2"
                                style={{ marginBottom: 0 }}
                                placeholder={t('add_note')}
                                value={note}
                                onChange={(e, { value }) => setNote(value)}
                            />
                            <span className="ref-link" style={{ color: 'var(--primary)' }} onClick={() => onEdit()}>
                                {t('confirm')}
                            </span>
                        </Form.Field>
                    </Form>
                ) : (
                    recordNote?.content
                )}
            </div>
            {canManage && !clickedToEdit && (
                <small
                    className="ref-link"
                    style={{ opacity: 0.8, color: 'var(--primary)' }}
                    onClick={() => setClickedToEdit(true)}
                >
                    {t('click_to_edit_note')}
                </small>
            )}
        </>
    )
}

export default QuickNotes
