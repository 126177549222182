import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { routes } from '@routes'
import { tzDateTime } from '@helpers/dates'
import { useHasPermission } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import { Button, Comment, Container, Divider, Form, Grid, Header, Label, Popup, Progress, Tab } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import AvatarIcon from '@components/AvatarIcon'
import CanView from '@components/perms/CanView'
import Attachments from '@components/Attachments'
import ListView from '@components/ListView'
import MKEditor from '@components/editors/MKEditor'
import TaskForm from './TaskForm'

const determinateColor = (state) => {
    let color = 'var(--dark)'

    if (state === 1) color = 'var(--info)'
    if (state === 2) color = 'var(--primary)'
    if (state === 3) color = 'var(--warning)'
    if (state === 4) color = 'var(--danger)'
    return color
}

const IssueDetail = ({ data, setData, priorityColor }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const [issue, setIssue] = useState({})
    const [notes, setNotes] = useState([])
    const [processing, setProcessing] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [caseNote, setCaseNote] = useState('')
    const [params, setParams] = useState({
        state: '',
        is_closed: '',
    })
    const [activeLabel, setActiveLabel] = useState(0)
    const canManage = useHasPermission('cases.c_manage_all_cases', 'cases.c_manage_assigned_user_cases')

    useEffect(() => {
        async function fetchNotes() {
            const request = await requests.get(API.CASES_NOTES + `?ordering=-created_on&case=${data.id}`)
            if (request.status === 200) {
                setNotes(request.response)
            }
        }
        fetchNotes()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetchIssue() {
            setIsLoading(true)
            let issues = []

            const requestIssue = await requests.get(
                API.PROJECT_ISSUES +
                    `?case=${data.id}&state=${params.state}&query={id,assigned,project,state_display,name,description,state,due_date}`
            )
            const requestTask = await requests.get(
                API.TASKS +
                    `?case=${data.id}&is_closed=${params.is_closed}&query={id,assigned_to,title,description,is_closed,to_date}`
            )

            if (requestIssue?.status === 200 && requestIssue?.response?.results?.length > 0) {
                issues = [...issues, ...requestIssue.response.results]
            }
            if (requestTask?.status === 200 && requestTask?.response?.length > 0) {
                issues = [...issues, ...requestTask.response]
            }
            setIssue(issues)
            setIsLoading(false)
        }
        fetchIssue()
        // eslint-disable-next-line
    }, [params])

    const getCount = () => {
        let closed = parseInt(data.completed_tasks || 0)
        let opened = parseInt(data.non_completed_tasks || 0)
        let total = opened + closed
        let percentage = 0

        if (closed > 0) {
            percentage = parseFloat((closed / total) * 100).toFixed(2)
        } else {
            percentage = 0
        }

        return [total, closed, percentage]
    }

    const onCreate = (response, setListData) => {
        let taskState = undefined
        if (response?.state === 1) {
            taskState = 'notCompleted'
        } else if (response?.state === 2) {
            taskState = 'completed'
        } else if (response?.is_closed === true) {
            taskState = 'completed'
        } else if (response?.is_closed === false && response?.is_closed !== undefined) {
            taskState = 'notCompleted'
        }

        setData((prev) =>
            prev.filter((item) => {
                if (item.id === data.id) {
                    if (taskState === 'completed') {
                        item.completed_tasks = item.completed_tasks + 1
                    } else if (taskState === 'notCompleted') {
                        item.non_completed_tasks = item.non_completed_tasks + 1
                    }
                }
                return item
            })
        )

        setListData((prev) => [response, ...prev])
        setIssue((prev) => [response, ...prev])
    }

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.post(API.CASES_NOTES, {
            content: caseNote,
            case: data.id,
        })

        if (request.status === 201) {
            setNotes((prev) => [request.response, ...prev])
            setCaseNote('')
        }
        setProcessing(false)
    }

    const TableFilters = () => {
        const openedIssues =
            issue?.length > 0 ? issue?.filter((item) => item.state === 1 || item.is_closed === false).length : 0
        const closedIssues =
            issue?.length > 0 ? issue?.filter((item) => item.state === 2 || item.is_closed === true).length : 0

        return (
            <div style={{ marginBottom: '-4rem', zIndex: '9999' }}>
                <Label
                    basic
                    onClick={() => {
                        setActiveLabel(0)
                        setParams({
                            is_closed: '',
                            state: '',
                        })
                    }}
                    style={{
                        fontWeight: 'normal',
                        fontSize: '0.9rem',
                        padding: '0.9rem',
                        cursor: 'pointer',
                        border: 'none',
                        backgroundColor: activeLabel === 0 ? 'var(--primary)' : 'transparent',
                        color: activeLabel === 0 ? 'var(--light)' : 'var(--primary)',
                    }}
                >
                    {t('all')}
                    {issue?.length >= 0 && (
                        <Label
                            size="tiny"
                            circular
                            style={{
                                fontWeight: 'normal',
                                background: 'var(--white)',
                                color: 'var(--dark)',
                                marginLeft: '0.5rem',
                            }}
                        >
                            {issue.length}
                        </Label>
                    )}
                </Label>
                <Label
                    basic
                    onClick={() => {
                        setActiveLabel(1)
                        setParams({
                            is_closed: false,
                            state: 1,
                        })
                    }}
                    style={{
                        fontWeight: 'normal',
                        fontSize: '0.9rem',
                        padding: '0.9rem',
                        cursor: 'pointer',
                        border: 'none',
                        backgroundColor: activeLabel === 1 ? 'var(--primary)' : 'transparent',
                        color: activeLabel === 1 ? 'var(--light)' : 'var(--primary)',
                    }}
                >
                    {t('opened')}
                    {openedIssues >= 0 && (
                        <Label
                            size="tiny"
                            circular
                            style={{
                                fontWeight: 'normal',
                                background: 'var(--white)',
                                color: 'var(--dark)',
                                marginLeft: '0.5rem',
                            }}
                        >
                            {openedIssues}
                        </Label>
                    )}
                </Label>
                <Label
                    basic
                    onClick={() => {
                        setActiveLabel(2)
                        setParams({
                            is_closed: true,
                            state: 2,
                        })
                    }}
                    style={{
                        fontWeight: 'normal',
                        fontSize: '0.9rem',
                        padding: '0.9rem',
                        cursor: 'pointer',
                        border: 'none',
                        backgroundColor: activeLabel === 2 ? 'var(--primary)' : 'transparent',
                        color: activeLabel === 2 ? 'var(--light)' : 'var(--primary)',
                    }}
                >
                    {t('closed')}
                    {closedIssues >= 0 && (
                        <Label
                            size="tiny"
                            circular
                            style={{
                                fontWeight: 'normal',
                                background: 'var(--white)',
                                color: 'var(--dark)',
                                marginLeft: '0.5rem',
                            }}
                        >
                            {closedIssues}
                        </Label>
                    )}
                </Label>
            </div>
        )
    }

    const IssuesInTable = ({ data, issue }) => {
        return (
            <>
                <TableFilters />
                <ListView
                    as="table"
                    forceLoading={isLoading}
                    externalData={issue}
                    tableHeaders={[
                        { title: t('name_of_issue_or_task') },
                        { title: t('classification') },
                        { title: t('to_date') },
                        { title: t('assigned') },
                        { title: t('status') },
                    ]}
                    actions={[
                        {
                            as: 'modal',
                            type: 'add',
                            modalSize: 'small',
                            permissions: [
                                'tasks.c_manage_tasks',
                                'tasks.c_manage_user_tasks',
                                'projects.c_manage_issues',
                                'cases.c_view_all_cases',
                                'cases.c_view_assigned_user_cases',
                            ],
                            //matchEveryPermission: true,
                            modal: (
                                <TaskForm
                                    record={data}
                                    handleRequest={(response, setListData) => onCreate(response, setListData)}
                                />
                            ),
                        },
                    ]}
                    renderCells={(item) => [
                        { content: item.name || item.title },
                        {
                            content: item.project ? (
                                <Link target="_blank" to={`${routes.PROJECT_DETAIL}${item.project.id}`}>
                                    <span className="link-ref"> {item.project.name}</span>
                                </Link>
                            ) : (
                                t('unclassified')
                            ),
                        },
                        {
                            content:
                                item.to_date && moment(item.to_date).isValid()
                                    ? moment(item.to_date).format(dateFormat)
                                    : item.due_date && moment(item.due_date).isValid()
                                    ? moment(item.due_date).format(dateFormat)
                                    : '--',
                        },
                        {
                            content:
                                item.assigned_to?.fullname_with_titles ||
                                item?.assigned?.map((name) => <div>{name.user.fullname_with_titles}</div>) ||
                                t('no_assigned_person'),
                        },
                        {
                            content: (
                                <Label color={item.state === 2 || item.is_closed ? 'red' : 'green'} horizontal>
                                    {item.state === 2 || item.is_closed ? t('closed') : t('open')}
                                </Label>
                            ),
                        },
                    ]}
                />
            </>
        )
    }

    const panes = [
        {
            menuItem: t('description'),
            render: () => <MKEditor readOnly={true} markdown={data.description} />,
        },
        {
            menuItem: t('tasks') + (' (' + getCount()[2] + ' %)'),
            render: () => (
                <Grid>
                    <Grid.Row style={{ padding: 0, marginLeft: '1rem' }}>
                        <Grid.Column width={16} style={{ paddingLeft: 0, paddingBottom: '1rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <strong>
                                    {getCount()[1]} / {getCount()[0]}
                                </strong>
                                <strong>{getCount()[2]}%</strong>
                            </div>
                            <div style={{ marginTop: '0.25rem' }}>
                                <Progress percent={getCount()[2]} indicating />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <IssuesInTable data={data} issue={issue} />
                </Grid>
            ),
        },
        {
            menuItem: t('notes') + ' (' + notes.length + ')',
            render: () => (
                <div>
                    <CanView permissions={['cases.c_manage_all_cases', 'cases.c_manage_assigned_user_cases']}>
                        <Form onSubmit={handleSubmit}>
                            <SuperField
                                autoFocus
                                as="textarea"
                                id="comment-box"
                                value={caseNote}
                                placeholder={t('enter_comment')}
                                onChange={(e, { value }) => setCaseNote(value)}
                            />
                            <Form.Field style={{ textAlign: 'left' }}>
                                <Button primary content={t('confirm')} disabled={processing} loading={processing} />
                            </Form.Field>
                        </Form>
                        <Divider />
                    </CanView>

                    {notes.length === 0 && (
                        <strong
                            style={{
                                display: 'block',
                                textAlign: 'center',
                                fontSize: '1.2rem',
                                paddingBottom: '2rem',
                                paddingTop: '2rem',
                            }}
                        >
                            {t('no_data')}
                        </strong>
                    )}

                    {notes.length > 0 && (
                        <div style={{ maxHeight: '470px', overflow: 'scroll' }}>
                            <Comment.Group
                                style={{ paddingBottom: '0.5rem', marginBottom: '0.5rem', minWidth: '100%' }}
                            >
                                {notes.map((item, idx) => (
                                    <div
                                        key={idx}
                                        style={{
                                            //paddingLeft: '1rem',
                                            marginTop: idx === 0 ? '0rem' : '1rem',
                                            borderRight: `10px solid #acddde`,
                                            background: 'var(--light)',
                                            padding: '0.5rem',
                                            borderTopRightRadius: '7px',
                                            borderBottomRightRadius: '7px',
                                        }}
                                    >
                                        <Comment style={{ paddingLeft: '0.5rem' }}>
                                            <Comment.Avatar
                                                src={
                                                    <AvatarIcon
                                                        size={40}
                                                        src={item.created_by?.profile_picture || null}
                                                        name={item.created_by?.name || 'JD'}
                                                    />
                                                }
                                            />
                                            <Comment.Content>
                                                <Comment.Author as="a">{item.created_by?.name}</Comment.Author>
                                                <Comment.Metadata>
                                                    <div>
                                                        {tzDateTime(item.created_on).format(dateFormat + ' HH:mm:ss')}
                                                    </div>
                                                </Comment.Metadata>
                                                <Comment.Text>{item.content}</Comment.Text>
                                            </Comment.Content>
                                        </Comment>
                                    </div>
                                ))}
                            </Comment.Group>
                        </div>
                    )}
                </div>
            ),
        },
        {
            menuItem: t('attachments') + ' (' + data.attachments?.length + ')',
            render: () => (
                <div>
                    <Attachments
                        viewOnly={!canManage}
                        allowFolders
                        record={data}
                        moduleName={'cases'}
                        setData={setData}
                        sourceEndpoint={API.CASES}
                    />
                </div>
            ),
        },
    ]

    return (
        <Container fluid style={{ paddingTop: '1rem' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                    <Header as={'h3'} style={{ marginBottom: 0, fontSize: '1.5rem' }}>
                        #{data.id} {data.title} <br />
                        <small style={{ opacity: '0.8', fontSize: '1rem' }}>
                            {t('case_created')}
                            <strong>
                                {' ' + (data?.created_on ? moment(data?.created_on).format(dateFormat) : '--') + ' '}
                            </strong>
                            {t('by_user') + ' '}
                            <strong>{data?.created_by ? data?.created_by?.name : '--'}</strong>
                        </small>
                    </Header>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '1rem' }}>
                        <div>
                            <Icon
                                name="ellipse"
                                style={{
                                    marginRight: '0.5rem',
                                    color: determinateColor(data.status),
                                }}
                            />
                            <span style={{ position: 'relative', top: '-0.2rem' }}>{data.status_display}</span>
                        </div>
                        <Label
                            style={{
                                background: priorityColor(data?.priority) + '66',
                                color: 'var(--black)',
                                marginRight: '0.5rem',
                            }}
                        >
                            {t('priority')}: {data?.priority_display}
                        </Label>
                    </div>
                </div>

                {data?.label?.length > 0 && (
                    <div style={{ marginTop: '0.5rem' }}>
                        {data?.label?.map((label, idx) => (
                            <>
                                {idx + 1 <= 2 && (
                                    <Label
                                        style={{
                                            fontSize: '0.9rem',
                                            color: 'black',
                                            backgroundColor: label.color + '66',
                                        }}
                                        key={label.id}
                                    >
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>{label.name}</span>
                                    </Label>
                                )}
                            </>
                        ))}

                        {data?.label?.length > 2 && (
                            <Popup
                                hoverable
                                position="bottom center"
                                trigger={
                                    <Label basic>
                                        <span style={{ fontSize: '0.8rem', color: 'var(--dark)' }}>
                                            {data?.label?.length - 2}{' '}
                                            <span style={{ textTransform: 'lowercase' }}>
                                                {data?.label?.length - 2 === 1 ? t('label') : t('labels')}
                                            </span>
                                            ...
                                        </span>
                                    </Label>
                                }
                                content={
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {data?.label?.map((label, idx) => (
                                            <>
                                                {idx + 1 > 2 && (
                                                    <Label
                                                        style={{
                                                            fontSize: '0.9rem',
                                                            padding: '0.4rem',
                                                            paddingTop: '0.6rem',
                                                            color: 'white',
                                                            backgroundColor: label.color,
                                                        }}
                                                        key={label.id}
                                                    >
                                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                            {label.name}
                                                        </span>
                                                    </Label>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                }
                            />
                        )}
                    </div>
                )}

                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <strong>{t('due_date') + ': '}</strong>
                    <strong>
                        {data?.due_date ? (
                            moment(data?.due_date).format(dateFormat)
                        ) : (
                            <span style={{ opacity: 0.5 }}>{t('not_specified').toLocaleLowerCase()}</span>
                        )}
                    </strong>
                </div>
            </div>

            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </Container>
    )
}

export default IssueDetail
