import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { toast } from 'react-semantic-toasts'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import ListView from '@components/ListView'
import { Form, Divider, Label } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const Publications = ({ onClose, setHeader, offer }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    useEffect(() => {
        setHeader(t('publications'))
        // eslint-disable-next-line
    }, [])

    const handleArchive = async (item, setData, setTotal) => {
        let errorReason
        const request = await requests.put(API.JOB_PUBLICATIONS + item.id + '/archive/')
        if (request.status === 204) {
            setData((prev) => prev.filter((publication) => publication.id !== item.id))
            setTotal((prev) => prev - 1)
        } else {
            errorReason = request?.response?.detail
            toast({
                type: 'error',
                icon: 'warning',
                title: t('error_occured'),
                description: errorReason,
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    return (
        <>
            <ListView
                as="table"
                isModal
                actionsCellWidth="2"
                forceReloadOnPagination={false}
                endpoint={API.JOB_PUBLICATIONS}
                initialFilters={{
                    job_offer: offer.id,
                    is_active: true,
                    ordering: ['-valid_until'],
                }}
                tableHeaders={[
                    { title: t('published') },
                    { title: t('valid_until') },
                    { title: t('source') },
                    { title: t('published_by') },
                ]}
                renderCells={(publication, setData) => [
                    {
                        content: (
                            <Label basic simple>
                                PULSAWORK
                            </Label>
                        ),
                    },
                    {
                        content: publication?.valid_until ? moment(publication?.valid_until).format(dateFormat) : '--',
                    },
                    {
                        content: publication?.source ? (
                            <Label
                                style={{
                                    background: publication?.source?.color + '40',
                                    color: 'var(--dark)',
                                }}
                            >
                                {publication?.source?.name}
                            </Label>
                        ) : (
                            <span style={{ opacity: '0.6' }}>{t('not_specified')}</span>
                        ),
                    },
                    {
                        content: publication?.published_by?.name,
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create_publication'),
                        permissions: ['jobs.c_manage_job_offers'],
                        modalSize: 'tiny',
                        modal: <PulsaworkPublishForm offer={offer} />,
                    },
                    {
                        as: 'link',
                        type: 'custom',
                        target: '_blank',
                        name: t('application_form_url'),
                        icon: 'log-in-outline',
                        customIconColor: 'var(--primary)',
                        permissions: ['jobs.c_view_job_offers'],
                        isHidden: (item) => item.application_form_config === null,
                        redirect: (item) => routes.PUBLIC_APPLICATION_FORM + item.id,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure'),
                        permissions: ['jobs.c_delete_job_offers'],
                        customDelete: (item, setData, setTotal) => handleArchive(item, setData, setTotal),
                    },
                ]}
            />
        </>
    )
}

const PulsaworkPublishForm = ({ onClose, setData, setTotal, offer }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        valid_until: offer.job_valid_until || '',
        application_form_config: '',
        resourcetype: 'JobPublication',
        job_offer: offer.id,
        source: '',
        is_active: true,
        is_featured: false,
    })

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)
        const request = await requests.post(API.JOB_PUBLICATIONS, { ...form, source: form.source || null })

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            //const requestPublish = await requests.put(API.JOB_PUBLICATIONS + request.response.id + '/publish/')
            //if (requestPublish.status === 200) {
            //const getPublishRecord = await requests.get(API.JOB_PUBLICATIONS + request.response.id + '/')
            //if (getPublishRecord) {
            setData((prev) => [...prev, request.response])
            setTotal((prev) => prev + 1)
            onClose()
            //}
            //}
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    label={t('valid_until')}
                    value={form.valid_until}
                    onChange={(e, data) =>
                        setForm((prev) => ({
                            ...prev,
                            valid_until: data.value,
                        }))
                    }
                    error={errors !== null ? errors?.valid_until : false}
                />
                <SuperField
                    as="choice-select"
                    required
                    search
                    text="name"
                    endpoint={API.APPLICATION + 'form_configs/'}
                    label={t('application_form')}
                    value={form.application_form_config}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            application_form_config: value,
                        }))
                    }
                />
            </Form.Group>

            <SuperField
                as="choice"
                search
                endpoint={API.SOURCES}
                text="name"
                label={t('source')}
                value={form.source}
                onChange={(e, { value }) =>
                    setForm((prev) => ({
                        ...prev,
                        source: value,
                    }))
                }
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    type="button"
                    loading={processing}
                    disabled={processing || form.valid_until === '' || form.application_form_config === ''}
                    onClick={() => handleSubmit()}
                    content={t('publish')}
                />
            </Form.Field>
        </Form>
    )
}

export default Publications
