import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
//components
import SuperField from '@components/forms/SuperField'

const ContractAdditionFilterForm = ({ filters, setFilters }) => {
    const { t } = useTranslation()

    return (
        <>
            <SuperField
                as="choice-select"
                search
                text="fullname"
                label={t('employee')}
                value={filters.employee}
                endpoint={API.EMPLOYEES}
                additionalFilters={'&only_basic_info=true'}
                onChange={(e, { value }) => setFilters({ ...filters, employee: value })}
            />
            <SuperField
                as="choice-select"
                search
                text={(item) => item.custom_id + ' | ' + item.employee.fullname + ' - ' + item.work_position?.title}
                label={t('contract')}
                value={filters.contract}
                endpoint={API.CONTRACTS}
                additionalFilters={'&only_basic_info=true&status=1&status=3&status=6'}
                onChange={(e, { value }) => setFilters({ ...filters, contract: value })}
            />
        </>
    )
}

export default ContractAdditionFilterForm
