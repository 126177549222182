import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import { Card, Button, Divider } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import BulkBonusForm from './BulkBonusForm'

const ContractBonuses = ({ contract, setData }) => {
    const { t } = useTranslation()

    const removeBonus = async (bonus) => {
        const request = await requests.patch(API.CONTRACTS + contract.id + '/?query={id, bonuses}', {
            bonuses: { remove: [bonus.id] },
        })

        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('bonus_was_removed'),
                animation: 'pulse',
                time: 2000,
            })
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === contract?.id) {
                        item.bonuses = request.response.bonuses
                    }
                    return item
                })
            )
        }
    }

    return (
        <>
            <CanView
                permissions={[
                    'contracts.c_manage_all_contracts',
                    'company.c_manage_only_assigned_unit_employee_contracts',
                    'common.c_manage_only_agency_employees',
                    'common.c_manage_only_internal_employees',
                    'common.c_manage_only_external_employees',
                ]}
            >
                <SuperDuperModal
                    size="tiny"
                    header={t('assign_contract_bonus')}
                    trigger={
                        <Button fluid primary>
                            {t('assign_contract_bonus')}
                        </Button>
                    }
                    content={
                        <BulkBonusForm
                            selected={[contract]}
                            setData={setData}
                            toExclude={contract.bonuses.map((item) => item.id)}
                        />
                    }
                />

                <Divider />
            </CanView>
            {contract.bonuses.length === 0 && (
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('no_data')}</div>
            )}
            {contract.bonuses.map((bonus) => (
                <Card fluid key={bonus.id}>
                    <Card.Content
                        style={{
                            backgroundColor: 'white',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '1.2rem',
                            }}
                        >
                            <div style={{ fontWeight: 'bold' }}>
                                {bonus?.title}
                                <div style={{ fontWeight: 'normal', opacity: 0.8, fontSize: '0.9rem' }}>
                                    {bonus.amount} {bonus.currency}
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <CanView
                                    permissions={[
                                        'contracts.c_manage_all_contracts',
                                        'company.c_manage_only_assigned_unit_employee_contracts',
                                        'common.c_manage_only_agency_employees',
                                        'common.c_manage_only_internal_employees',
                                        'common.c_manage_only_external_employees',
                                    ]}
                                >
                                    <Action
                                        as="confirm"
                                        type="icon"
                                        size="huge"
                                        iconColor="var(--danger)"
                                        tooltip={t('remove_bonus')}
                                        icon="close-outline"
                                        text={t('are_you_sure_that_you_want_to_remove_this_bonus')}
                                        onClick={() => removeBonus(bonus)}
                                    />
                                </CanView>
                            </div>
                        </div>
                    </Card.Content>
                </Card>
            ))}
        </>
    )
}

export default ContractBonuses
