import React, { useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { icons } from '@store/icons'
import { routes } from '@store/routes'
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import Address from '@components/forms/Address'
import CanView from '@components/perms/CanView'
import Attachments from '@components/Attachments'
import ShortenText from '@components/ShortenText'
import BreadcrumbNav from '@components/BreadcrumbNav'
import GenerateDocuments from '@components/GenerateDocuments'
import { Button, Container, Divider, Label, Popup } from 'semantic-ui-react'

// custom components
import ContractAdditionSignForm from './components/ContractAdditionSignForm'
import ContractAdditionFilterForm from './components/ContractAdditionFilterForm'
import ContractAdditionForm from '../contracts/components/ContractAdditionForm'

const ContractAdditions = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const [attachmentsOpen, setAttachmentsOpen] = useState(0)

    return (
        <CanView
            permissions={[
                'contracts.c_view_all_contracts',
                'contracts.c_view_user_contracts',
                'company.c_view_only_assigned_unit_employee_contracts',
                'common.c_view_only_agency_employees',
                'common.c_view_only_internal_employees',
                'common.c_view_only_external_employees',
            ]}
            redirect
        >
            <BreadcrumbNav
                mBottom="0rem"
                items={[
                    { name: t('contracts'), icon: icons.CONTRACTS, href: routes.CONTRACTS },
                    { name: t('contract_additions'), icon: '', href: '' },
                ]}
            />
            <Container fluid>
                <Button.Group basic>
                    <Button
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        as={Link}
                        to={routes.CONTRACTS}
                    >
                        <Icon name="file-tray-full-outline" style={{ marginRight: '0.5rem', fontSize: '1.1rem' }} />
                        {t('contracts')}
                    </Button>
                    <Button
                        active
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        as={Link}
                        to={routes.ADDITIONS}
                    >
                        <Icon name="document-text-outline" style={{ fontSize: '1.1rem', marginRight: '0.6rem' }} />
                        {t('additions')}
                    </Button>
                    <Button
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        as={Link}
                        to={routes.TERMINATIONS}
                    >
                        <Icon name="exit-outline" style={{ fontSize: '1.1rem', marginRight: '0.6rem' }} />
                        {t('terminations')}
                    </Button>
                </Button.Group>
                <Divider />
            </Container>
            <ListView
                as="table"
                allowSearch
                allowSelection
                isSoftDeleteAllowed
                // module="terminations"
                endpoint={API.CONTRACTS + 'additions/'}
                actionsCellWidth="2"
                bulkActions={(selected) => [
                    {
                        as: 'modal',
                        name: t('generate_documents'),
                        icon: 'document-text-outline',
                        modal: <GenerateDocuments source="contracts.ContractAddition" selected={selected} />,
                    },
                ]}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('drafts'),
                        filters: {
                            is_draft: true,
                        },
                        count: response?.draft_count || 0,
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('signed'),
                        filters: {
                            is_draft: false,
                        },
                        count: response?.signed_count || 0,
                    },

                    {
                        as: 'filter',
                        index: 2,
                        name: t('all'),
                        filters: {
                            is_draft: null,
                        },
                        count: response?.all_count || 0,
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create_contract_addition'),
                        modal: <ContractAdditionForm addAddition />,
                        permissions: [
                            'contracts.c_manage_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                            'common.c_manage_only_agency_employees',
                            'common.c_manage_only_internal_employees',
                            'common.c_manage_only_external_employees',
                        ],
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        modalSize: 'tiny',
                        icon: 'create-outline',
                        customIconColor: 'var(--dark)',
                        name: t('update_date_of_signature'),
                        permissions: [
                            'contracts.c_manage_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                            'common.c_manage_only_agency_employees',
                            'common.c_manage_only_internal_employees',
                            'common.c_manage_only_external_employees',
                        ],
                        modal: <ContractAdditionSignForm />,
                    },
                    {
                        name: t('edit'),
                        type: 'edit',
                        as: 'modal',
                        disabled: (item) => !item.is_draft,
                        icon: 'pencil-outline',
                        modalSize: 'tiny',
                        customIconColor: 'var(--dark)',
                        permissions: [
                            'contracts.c_manage_all_contracts',
                            'company.c_manage_only_assigned_unit_employee_contracts',
                            'common.c_manage_only_agency_employees',
                            'common.c_manage_only_internal_employees',
                            'common.c_manage_only_external_employees',
                        ],
                        modal: (item, setData) => <ContractAdditionForm additionObject={item} setData={setData} />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        isHidden: (item) => !item.is_draft,
                        text: t('delete_contract_message'),
                        permissions: [
                            'contracts.c_delete_all_contracts',
                            'common.c_delete_only_agency_employees',
                            'common.c_delete_only_external_employees',
                            'common.c_delete_only_internal_employees',
                        ],
                    },
                ]}
                tableHeaders={[
                    { title: t('contract_number') },
                    { title: t('addition_number') },
                    { title: t('employee') },
                    { title: t('employer') },
                    { title: t('contract_changes') },
                    { title: t('date_of_signature') },
                    { title: t('attachments') },
                    { title: t('status') },
                    { title: t('created') },
                ]}
                initialFilters={{
                    employee: '',
                    contract: '',
                    is_draft: true,
                    ordering: ['-number'],
                }}
                renderFilterFields={(filters, setFilters) => (
                    <ContractAdditionFilterForm filters={filters} setFilters={setFilters} />
                )}
                renderCells={(addition, setData) => [
                    { content: <ShortenText text={addition.contract.custom_id} />, customWidth: '100px' },
                    { content: addition.number, customWidth: '50px' },
                    {
                        content: (
                            <>
                                <Link to={routes.EMPLYOEES_DETAIL + addition?.contract?.employee?.id} target="_blank">
                                    <strong>
                                        <ShortenText text={addition?.contract?.employee?.fullname} />
                                    </strong>
                                </Link>{' '}
                            </>
                        ),
                        customWidth: '150px',
                    },
                    {
                        content: (
                            <>
                                <Link to={routes.UNIT_DETAIL + addition?.contract?.employer?.id} target="_blank">
                                    <strong>
                                        <ShortenText text={addition?.contract?.employer?.name} />
                                    </strong>
                                    <br />
                                </Link>
                                <small>{addition?.contract?.employer?.identification_number}</small>
                            </>
                        ),
                        customWidth: '150px',
                    },
                    {
                        content: (
                            <>
                                {addition.work_position && (
                                    <div>
                                        <strong>{t('position')}: </strong> {addition.work_position.title} (
                                        {addition.work_level}. {t('work_level').toLowerCase()})
                                    </div>
                                )}
                                {addition.fond && (
                                    <div>
                                        <strong>{t('work_fond')}: </strong> {addition.fond}{' '}
                                        {t('hours_shortcut').toLowerCase()}.{addition.fond_per_unit_display} (
                                        {addition.type.title})
                                    </div>
                                )}
                                {addition.valid_until === null && addition.is_indefinite_period && (
                                    <div>
                                        <strong>{t('valid_to')}: </strong> {t('indefined_period')}
                                    </div>
                                )}
                                {addition.valid_until !== null && addition.is_indefinite_period === false && (
                                    <div>
                                        <strong>{t('valid_to')}: </strong>{' '}
                                        {moment(addition.valid_until).format(dateFormat)}
                                    </div>
                                )}
                                {addition.trial_period && (
                                    <div>
                                        <strong>{t('trial_period')}: </strong>{' '}
                                        {moment(addition.trial_period).format(dateFormat)}
                                    </div>
                                )}
                                {addition.salary && (
                                    <div>
                                        <strong>{t('salary')}: </strong> {addition.salary} {addition.currency}{' '}
                                        {addition.per_unit_display}
                                    </div>
                                )}
                                {addition.workplace_address && (
                                    <div>
                                        <strong>{t('workplace')}: </strong>{' '}
                                        <Address address={addition.workplace_address} />
                                    </div>
                                )}
                            </>
                        ),
                    },
                    {
                        content: addition?.date_of_signature
                            ? moment(addition?.date_of_signature).format(dateFormat)
                            : '--',
                        customWidth: '150px',
                    },
                    {
                        content: (
                            <Label basic>
                                <Popup
                                    style={{ zIndex: '1000', minWidth: '450px' }}
                                    open={attachmentsOpen === addition.id}
                                    onOpen={() => setAttachmentsOpen(addition.id)}
                                    hoverable
                                    position="left center"
                                    trigger={
                                        <div style={{ cursor: 'pointer' }}>
                                            <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                {addition.files.length}
                                            </span>
                                        </div>
                                    }
                                    content={
                                        <Attachments
                                            //viewOnly={!canManage}
                                            record={addition}
                                            attribute="files"
                                            moduleName="contract_addition"
                                            setData={setData}
                                            sourceEndpoint={API.CONTRACTS + 'additions/'}
                                            closeView={() => setAttachmentsOpen(0)}
                                        />
                                    }
                                />
                            </Label>
                        ),
                        customWidth: '150px',
                    },
                    {
                        content: addition?.is_draft ? (
                            <Label
                                style={{
                                    background: 'var(--blue)',
                                    color: 'var(--white)',
                                    textAlign: 'center',
                                }}
                            >
                                {t('draft')}{' '}
                            </Label>
                        ) : (
                            <Label
                                style={{
                                    background: 'var(--success)',
                                    color: 'var(--white)',
                                    textAlign: 'center',
                                }}
                            >
                                {t('signed')}{' '}
                            </Label>
                        ),
                        customWidth: '150px',
                    },
                    {
                        content: (
                            <div>
                                {tzDateTime(addition.created_on).format(dateFormat + ' HH:mm')} <br />
                                <span>
                                    {t('created_by')} <strong>{addition.created_by?.name || '--'}</strong>
                                </span>
                            </div>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default ContractAdditions
