import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useIsMount, useHasPermissions } from '@helpers/hooks'
import { setDotSeparator } from '@helpers/functions'
// components
import Address from '@components/forms/Address'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Header, Label, Icon } from 'semantic-ui-react'
import EmailField from '@components/forms/common/EmailField'
import PhoneField from '@components/forms/common/PhoneField'
import ContractAdditionForm from './ContractAdditionForm'

const ContractForm = ({
    hiringRoomsSelected,
    setSelected,
    isSingle,
    onClose,
    setHeader,
    record,
    room,
    order,
    position,
    setData,
    setTotal,
    onItemUpdated,
}) => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    const [isProcessing, setIsProcessing] = useState(false)

    const profile = useSelector((state) => state?.user?.profile)

    const canViewAllUnits = useHasPermissions(['company.c_view_units'])

    const [customWorkplace, setCustomWorkPlace] = useState(record?.workplace_address?.street ? true : false)
    const [allowAccountant, setAllowAccountant] = useState(record?.accountant_email ? true : false)
    const [view, setView] = useState(0)

    const [employees, setEmployees] = useState([])

    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        status: record?.status || 4,
        type: record?.type?.id || '',
        employee: record?.employee?.id || '',
        employer: record?.employer?.id || '',
        work_position: record?.work_position?.id || '',
        work_level: record?.work_level?.toString() || '',
        valid_from: record?.valid_from || '',
        valid_until: record?.valid_until || '',
        fond: record?.fond || '40',
        fond_per_unit: record?.fond_per_unit || '1',
        salary: record?.salary || 0,
        currency: record?.currency || 'EUR',
        per_unit: record?.per_unit?.toString() || '1',
        trial_period: record?.trial_period || '',
        expected_end_date: record?.expected_end_date || '',
        bonuses: record?.bonuses?.map((item) => item.id) || [],
        available_vacation_fond: record?.available_vacation_fond || 0,
        allow_second_break: record?.allow_second_break === undefined ? true : record?.allow_second_break,
        unit: record?.unit?.id || '',

        // payroll provider
        external_accountant: record?.external_accountant?.id || '',
        accountant_name: record?.accountant_name || '',
        accountant_phone: record?.accountant_phone || '',
        accountant_email: record?.accountant_email || '',

        // additional
        shift: record?.shift?.id || '',
        strict_work_start: record?.strict_work_start || false,
        strict_work_end: record?.strict_work_end || false,
    })

    const [allowCustomID, setAllowCustomID] = useState(record?.custom_id ? true : false)
    const [customID, setCustomID] = useState(record?.custom_id || '')

    const [workplace, setWorkplace] = useState({
        street: record?.workplace_address?.street || '',
        city: record?.workplace_address?.city || '',
        postcode: record?.workplace_address?.postcode || '',
        country: record?.workplace_address?.country || 'SK',
        address_line: record?.workplace_address?.address_line || '',
        state: record?.workplace_address?.state || '',
        number: record?.workplace_address?.number || '',
        orientation_number: record?.workplace_address?.orientation_number || '',
    })

    const removeSelected = (selectedID) => {
        setSelected((prev) => prev.filter((item) => item.id !== selectedID))
    }

    const isWorkplaceFilled = () => {
        if (workplace.street === '' || customWorkplace === false) {
            return false
        }
        return true
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors([])
        setIsProcessing(true)
        let candidates = []
        let dataEmployees = []

        if (hiringRoomsSelected) {
            for (let i = 0; i < hiringRoomsSelected.length; i++) {
                if (hiringRoomsSelected[i].hr_contract?.id === undefined) {
                    candidates.push(hiringRoomsSelected[i].id)
                }
            }
            setEmployees(candidates)
        }

        if (record === undefined || isSingle) {
            let dataset = []
            dataEmployees = employees.length > 0 ? employees : candidates

            for (let i = 0; i < dataEmployees.length; i++) {
                let employee = dataEmployees[i]

                let data = {
                    employee: employee,
                    valid_from: form.valid_from,
                    valid_until: form.valid_until === '' ? null : form.valid_until,
                    fond: form.fond,
                    unit: form.unit === '' ? null : form.unit,
                    fond_per_unit: form.fond_per_unit,
                    work_level: form.work_level,
                    trial_period: form.trial_period === '' ? null : form.trial_period,
                    employer: form.employer,
                    type: form.type,
                    currency: form.currency,
                    salary: form.salary,
                    per_unit: form.per_unit,
                    work_position: form.work_position,
                    expected_end_date: form.expected_end_date === '' ? null : form.expected_end_date,
                    available_vacation_fond: form.available_vacation_fond || 0,
                    workplace_address: isWorkplaceFilled() ? workplace : null,
                    allow_second_break: form.allow_second_break,
                    //shift: form.shift === '' ? null : form.shift,
                    //strict_work_start: form.strict_work_start,
                    //strict_work_end: form.strict_work_end,

                    // payroll provider
                    accountant_email: allowAccountant ? form?.accountant_email || '' : '',
                    accountant_name: allowAccountant ? form?.accountant_name || '' : '',
                    accountant_phone: allowAccountant ? form?.accountant_phone || '' : '',
                    external_accountant: allowAccountant ? form?.external_accountant || null : null,
                }

                if (customID !== '' && customID !== record?.custom_id) {
                    data['custom_id'] = customID
                }
                if (hiringRoomsSelected) {
                    data['hiring_room'] = room
                    data['order'] = order

                    if (order) {
                        data['assign_to_order'] = true
                    }
                }

                const oldBonuses = record?.bonuses?.map((item) => item.id) || []
                if (form.bonuses.length > 0) {
                    data['bonuses'] = {
                        add: form.bonuses,
                    }

                    if (oldBonuses.length > 0) {
                        data['bonuses'] = {
                            remove: oldBonuses,
                            add: form.bonuses,
                        }
                    }
                }

                dataset.push(data)
            }

            const request = await requests.post(API.CONTRACTS + 'bulk/', dataset)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201 && !hiringRoomsSelected) {
                setData((prev) => [...request.response, ...prev])
                setTotal((prev) => prev + (request?.response?.length || 0))
                onClose()
            }
            if (request.status === 201 && hiringRoomsSelected) {
                for (let i = 0; i < hiringRoomsSelected.length; i++) {
                    const profileUpdate = request.response.find(
                        (item) => item.employee.id === hiringRoomsSelected[i].id
                    )
                    if (profileUpdate) {
                        onItemUpdated(profileUpdate, profileUpdate.employee)
                    }
                }

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('draft_contract_created'),
                    animation: 'pulse',
                    time: 2000,
                })
                setSelected([])
                onClose()
            }
        } else {
            const data = {
                valid_from: form.valid_from,
                valid_until: form.valid_until === '' ? null : form.valid_until,
                fond: form.fond,
                unit: form.unit === '' ? null : form.unit,
                fond_per_unit: form.fond_per_unit,
                work_level: form.work_level,
                trial_period: form.trial_period === '' ? null : form.trial_period,
                employer: form.employer,
                type: form.type,
                currency: form.currency,
                salary: form.salary,
                per_unit: form.per_unit,
                work_position: form.work_position,
                employee: form.employee ? form.employee : hiringRoomsSelected[0].id,
                expected_end_date: form.expected_end_date === '' ? null : form.expected_end_date,
                shift: form.shift === '' ? null : form.shift,
                strict_work_start: form.strict_work_start,
                strict_work_end: form.strict_work_end,
                available_vacation_fond: form.available_vacation_fond || 0,
                workplace_address: isWorkplaceFilled() ? workplace : null,
                // payroll provider
                allow_second_break: form.allow_second_break,
                accountant_email: allowAccountant ? form?.accountant_email || '' : '',
                accountant_name: allowAccountant ? form?.accountant_name || '' : '',
                accountant_phone: allowAccountant ? form?.accountant_phone || '' : '',
                external_accountant: allowAccountant ? form?.external_accountant || null : null,
            }

            if (customID !== '' && customID !== record?.custom_id) {
                data['custom_id'] = customID
            }

            const oldBonuses = record?.bonuses?.map((item) => item.id) || []
            if (form.bonuses.length > 0) {
                data['bonuses'] = {
                    add: form.bonuses,
                }

                if (oldBonuses.length > 0) {
                    data['bonuses'] = {
                        remove: oldBonuses,
                        add: form.bonuses,
                    }
                }
            }

            const request = await requests.patch(API.CONTRACTS + record.id + '/', data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200 && !hiringRoomsSelected) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) {
                            item = request.response
                        }

                        return item
                    })
                )

                onClose()
            }
            if (request.status === 200 && hiringRoomsSelected) {
                onClose()
            }
        }
        setIsProcessing(false)
    }

    useEffect(() => {
        async function fetchPositionData(selectedPosition) {
            const request = await requests.get(
                API.JOB_POSITIONS +
                    selectedPosition +
                    '/?query={id, title, work_level, allow_second_break, contract_type{id, fond, fond_per_unit}, salary_type, min, max, currency, bonuses{id, title}}'
            )
            if (request.status === 200) {
                let fetchedPosition = request.response
                if (fetchedPosition) {
                    setForm((prev) => {
                        let item = {
                            ...prev,
                            work_level: fetchedPosition?.work_level?.toString?.() || '1',
                            bonuses: fetchedPosition?.bonuses?.map((item) => item.id) || [],
                            type: fetchedPosition.contract_type?.id || '',
                            currency: fetchedPosition.currency || 'EUR',
                            salary: fetchedPosition.min || 0,
                            per_unit: fetchedPosition.salary_type?.toString?.() || '1',
                            fond: fetchedPosition?.contract_type?.fond || '40',
                            allow_second_break:
                                fetchedPosition?.allow_second_break !== undefined
                                    ? fetchedPosition?.allow_second_break
                                    : true,
                            fond_per_unit: fetchedPosition?.contract_type?.fond_per_unit?.toString?.() || '1',
                        }
                        return item
                    })
                }
            }
        }
        if (!isMount) {
            if (form.work_position !== '') {
                fetchPositionData(form.work_position)
            }
        }
        // eslint-disable-next-line
    }, [form.work_position])

    useEffect(() => {
        if (position) {
            setForm((prev) => ({ ...prev, work_position: position.id }))
        }
    }, [position])

    useEffect(() => {
        async function fetchContractType(selectedType) {
            const request = await requests.get(API.CONTRACTS_TYPES + selectedType + '/?query={id, fond, fond_per_unit}')
            if (request.status === 200) {
                let fetchedType = request.response
                if (fetchedType) {
                    setForm((prev) => {
                        let item = {
                            ...prev,
                            fond: fetchedType?.fond || '40',
                            fond_per_unit: fetchedType?.fond_per_unit?.toString?.() || '1',
                        }
                        return item
                    })
                }
            }
        }
        if (!isMount) {
            if (form.type !== '') {
                fetchContractType(form.type)
            }
        }
        // eslint-disable-next-line
    }, [form.type])

    useEffect(() => {
        if (record?.date_of_signature) {
            setHeader(t('create_addition_to_the_contract') + ': ' + record?.custom_id)
        } else {
            if (record !== undefined && record && !hiringRoomsSelected) {
                setHeader(t('update_contract') + ': ' + record?.employee?.fullname_with_titles)
            }
            if (hiringRoomsSelected) {
                if (hiringRoomsSelected.filter((item) => item.hr_contract?.id === undefined).length === 0) {
                    toast({
                        type: 'error',
                        icon: 'warning circle',
                        title: t('no_valid_candidate_selected'),
                        animation: 'bounce',
                        time: 5000,
                    })
                    onClose()
                } else {
                    setHeader(t('create_draft_contract'))
                }
            }
        }
        // eslint-disable-next-line
    }, [record])

    useEffect(() => {
        if (hiringRoomsSelected && hiringRoomsSelected.length === 0) {
            onClose()
        }
    }, [hiringRoomsSelected])

    return (
        <>
            {record?.date_of_signature && record.status !== 4 ? (
                <ContractAdditionForm record={record} position={position} onClose={onClose} setData={setData} />
            ) : (
                <Form onSubmit={handleSubmit}>
                    <NonFieldErrors errors={errors} />
                    {record?.id === undefined && !hiringRoomsSelected && (
                        <>
                            <SuperField
                                as="choice-select"
                                search
                                required
                                multiple
                                label={t('select_one_or_multiple_employees')}
                                value={employees}
                                help={t(
                                    'create_multiple_contracts_for_the_same_employer_and_position_by_selecting_multiple_employees'
                                )}
                                endpoint={API.EMPLOYEES}
                                //error={errors?.employee?.[0] || false}
                                text={(item) => item?.fullname_with_titles}
                                onChange={(e, { value }) => setEmployees(value)}
                                additionalFilters="&only_basic_info=true&is_active=true"
                            />
                            <Divider />
                        </>
                    )}
                    {hiringRoomsSelected && (
                        <>
                            <Header content={t('employees')} as="h5" style={{ marginTop: 0 }} />
                            {hiringRoomsSelected
                                .filter((item) => item.hr_contract?.id === undefined)
                                .map((selected) => (
                                    <Label>
                                        {selected?.fullname}
                                        {!isSingle && (
                                            <Icon name="delete" onClick={() => removeSelected(selected.id)} />
                                        )}
                                    </Label>
                                ))}
                            <Divider />
                        </>
                    )}

                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            search
                            required
                            label={t('employer')}
                            value={form.employer}
                            text={(item) => item?.name}
                            endpoint={API.BUSINESS_DETAIL}
                            error={errors?.employer?.[0] || false}
                            additionalFilters="&query={id, name}&is_employer=true&only_basic_info=true"
                            onChange={(e, { value }) => setForm({ ...form, employer: value })}
                            initialOptions={{
                                attribute: 'name',
                                source: record?.employer,
                            }}
                        />

                        <SuperField
                            as="choice-select"
                            label={t('department')}
                            search
                            endpoint={API.UNITS}
                            text={(item) => item.name}
                            value={form.unit}
                            onChange={(e, { value }) =>
                                setForm({
                                    ...form,
                                    unit: value,
                                })
                            }
                            additionalFilters={`&query={id, name}&only_from_active_company=true&only_basic_info=true${
                                canViewAllUnits ? '' : profile?.id ? `&responsible_persons=${profile?.id}` : ''
                            }`}
                            initialOptions={{
                                attribute: 'name',
                                source: record?.unit,
                            }}
                        />
                    </Form.Group>

                    <SuperField
                        as="checkbox"
                        label={t('set_custom_id_to_contract')}
                        checked={allowCustomID}
                        onChange={(e, { value }) => {
                            setAllowCustomID(!allowCustomID)
                            setCustomID('')
                        }}
                    />
                    {allowCustomID && (
                        <SuperField
                            required
                            autoFocus={record === undefined}
                            as="input"
                            label={t('custom_id')}
                            value={customID}
                            error={errors?.custom_id?.[0] || false}
                            onChange={(e, { value }) => setCustomID(value)}
                        />
                    )}
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            search
                            required
                            size="small"
                            label={t('work_position')}
                            value={form.work_position}
                            endpoint={API.JOB_POSITIONS}
                            text={(item) => item?.title}
                            error={errors?.work_position?.[0] || false}
                            onChange={(e, { value }) => setForm({ ...form, work_position: value })}
                            additionalFilters="&query={id, title}"
                            initialOptions={{
                                attribute: 'title',
                                source: record?.work_position || position,
                            }}
                        />
                        <SuperField
                            as="choice"
                            search
                            required
                            label={t('work_level')}
                            type="contracts_work_levels"
                            value={form.work_level?.toString()}
                            error={errors?.work_level?.[0] || false}
                            onChange={(e, { value }) => setForm({ ...form, work_level: value })}
                            initialOptions={{
                                attribute: 'work_level',
                                as_display: true,
                                source: record,
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            search
                            required
                            text="title"
                            size="small"
                            label={t('contract_type')}
                            value={form.type}
                            error={errors?.type?.[0] || false}
                            endpoint={API.CONTRACTS_TYPES}
                            settings="types/contract-types"
                            additionalFilters="&query={id, title}"
                            onChange={(e, { value }) => setForm({ ...form, type: value })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            required
                            label={t('work_fond')}
                            value={form.fond || ''}
                            onChange={(e, { value }) => setForm({ ...form, fond: value.replace(',', '.') })}
                            error={errors?.fond?.[0] || false}
                        />
                        <SuperField
                            as="choice"
                            required
                            type="fond_per_type"
                            label={t('work_fond_type')}
                            value={form.fond_per_unit?.toString?.()}
                            error={errors?.fond_per_unit?.[0] || false}
                            onChange={(e, { value }) => setForm({ ...form, fond_per_unit: value })}
                        />
                    </Form.Group>

                    <Divider />

                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            required
                            closable
                            label={t('work_starts')}
                            value={form.valid_from}
                            onChange={(e, { value }) => setForm({ ...form, valid_from: value })}
                            error={errors?.valid_from?.[0] || false}
                        />
                        <SuperField
                            as="datepicker"
                            closable
                            label={t('work_ends')}
                            help={t('work_ends_hint')}
                            value={form.valid_until}
                            onChange={(e, { value }) => setForm({ ...form, valid_until: value })}
                            error={errors?.valid_until?.[0] || false}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            closable
                            label={t('trial_period')}
                            value={form.trial_period}
                            onChange={(e, { value }) => setForm({ ...form, trial_period: value })}
                            error={errors?.trial_period?.[0] || false}
                        />
                        <SuperField
                            as="datepicker"
                            closable
                            label={t('expected_work_ends')}
                            value={form.expected_end_date}
                            onChange={(e, { value }) => setForm({ ...form, expected_end_date: value })}
                            error={errors?.expected_end_date?.[0] || false}
                        />
                    </Form.Group>

                    <Header as="h4">{t('salary_information')}</Header>
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            required
                            label={t('sum')}
                            value={form.salary}
                            onChange={(e, { value }) => setForm({ ...form, salary: setDotSeparator(value) })}
                            error={errors?.salary?.[0] || false}
                        />
                        <SuperField
                            as="choice"
                            search
                            required
                            label={t('currency')}
                            type="currency_codes"
                            value={form.currency}
                            onChange={(e, { value }) => setForm({ ...form, currency: value })}
                            error={errors?.currency?.[0] || false}
                        />
                        <SuperField
                            as="choice"
                            required
                            type="job_salary_type"
                            label={t('salary_type')}
                            value={form.per_unit}
                            onChange={(e, { value }) => setForm({ ...form, per_unit: value })}
                            error={errors?.per_unit?.[0] || false}
                        />
                    </Form.Group>
                    <CanView permissions={['payrolls.c_view_all_payrolls']}>
                        <SuperField
                            as="choice-select"
                            search
                            multiple
                            label={t('bonuses')}
                            settings="types/bonuses"
                            value={form.bonuses}
                            endpoint={API.PAYROLLS + 'bonuses/'}
                            text="title"
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    bonuses: value,
                                }))
                            }
                            initialOptions={{
                                attribute: 'title',
                                source: record?.bonuses,
                            }}
                        />
                    </CanView>

                    <Header as="h4" content={t('workplace')} style={{ marginBottom: '0.5rem' }} />
                    <Divider />
                    <SuperField
                        as="checkbox"
                        checked={customWorkplace}
                        onChange={() => setCustomWorkPlace(!customWorkplace)}
                        label={t('workplace_address_is_different_then_employer_address')}
                    />
                    {customWorkplace && <Address isForm address={workplace} setAddress={setWorkplace} />}

                    <Divider />

                    <Header as="h4" content={t('other_settings')} style={{ marginBottom: '0.5rem' }} />
                    <Divider />
                    <SuperField
                        as="input"
                        label={t('available_vacation_fond')}
                        value={form.available_vacation_fond}
                        help={t('current_vacation_availability_fond')}
                        error={errors?.available_vacation_fond?.[0] || false}
                        onChange={(e, { value }) =>
                            setForm({ ...form, available_vacation_fond: value.replace(',', '.') })
                        }
                    />

                    <SuperField
                        as="checkbox"
                        label={t('allow_second_break_for_attendance_tracking')}
                        checked={form.allow_second_break}
                        onChange={() => setForm({ ...form, allow_second_break: !form.allow_second_break })}
                    />

                    <Divider />
                    <h4>{t('accountant')}</h4>

                    <SuperField
                        as="checkbox"
                        checked={allowAccountant}
                        onChange={() => setAllowAccountant(!allowAccountant)}
                        label={t('allow_custom_accountant_configuration')}
                    />

                    {allowAccountant && (
                        <>
                            <Form.Group>
                                <SuperField
                                    as="radio"
                                    label={t('internal_singular')}
                                    checked={view === 0}
                                    onChange={() => {
                                        setView(0)
                                        setForm((prev) => ({ ...prev, external_accountant: '' }))
                                    }}
                                />
                                <SuperField
                                    as="radio"
                                    label={t('external_singular')}
                                    checked={view === 1}
                                    onChange={() => setView(1)}
                                />
                            </Form.Group>
                            <Divider />

                            {view === 1 && (
                                <>
                                    <SuperField
                                        search
                                        required
                                        as="choice-select"
                                        text="name"
                                        label={t('select_supplier')}
                                        value={form.external_accountant}
                                        endpoint={API.ACCOUNTS + 'business_details/'}
                                        additionalFilters={
                                            '&query={id, name}&is_supplier=true&exclude_unit=true&only_basic_info=true'
                                        }
                                        onChange={(e, { value }) =>
                                            setForm((prev) => ({ ...prev, external_accountant: value }))
                                        }
                                        initialOptions={{
                                            attribute: 'name',
                                            source: record?.external_accountant,
                                        }}
                                    />
                                    <Divider />
                                </>
                            )}

                            <SuperField
                                required
                                as="input"
                                label={t('person_name')}
                                value={form.accountant_name}
                                onChange={(e, { value }) => setForm((prev) => ({ ...prev, accountant_name: value }))}
                            />

                            <Form.Group widths="equal">
                                <EmailField
                                    required
                                    label={t('email')}
                                    value={form.accountant_email}
                                    setValue={(e, { value }) =>
                                        setForm((prev) => ({ ...prev, accountant_email: value }))
                                    }
                                />
                                <PhoneField
                                    hideType
                                    placeholder={t('enter_number')}
                                    value={form.accountant_phone}
                                    setValue={(e, { value }) =>
                                        setForm((prev) => ({ ...prev, accountant_phone: value }))
                                    }
                                />
                            </Form.Group>
                            <Divider />
                        </>
                    )}

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose} />
                        <ModalSubmit
                            loading={isProcessing}
                            disabled={
                                isProcessing ||
                                (record === undefined &&
                                    (hiringRoomsSelected
                                        ? hiringRoomsSelected.length === 0
                                        : employees.length === 0)) ||
                                form.contract_type === '' ||
                                form.work_position === '' ||
                                form.fond === '' ||
                                form.valid_from === '' ||
                                form.work_level === '' ||
                                form.per_unit === '' ||
                                form.currency === '' ||
                                form.salary === '' ||
                                (allowAccountant &&
                                    view === 0 &&
                                    (form.accountant_email === '' || form.accountant_name === '')) ||
                                (allowAccountant &&
                                    view === 1 &&
                                    (form.external_accountant === '' ||
                                        form.accountant_email === '' ||
                                        form.accountant_name === ''))
                            }
                            text={t('save')}
                        />
                    </Form.Field>
                </Form>
            )}
        </>
    )
}

export default ContractForm
