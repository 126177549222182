import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useIsMount } from '@helpers/hooks'
import { setDotSeparator } from '@helpers/functions'
// components
import Icon from '@components/Icon'
import Address from '@components/forms/Address'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Header, Segment, Label } from 'semantic-ui-react'

const ContractAdditionForm = ({
    onClose,
    record,
    additionObject,
    selected,
    setSelected,
    position,
    setData,
    setTotal,
    isMultiple,
    addAddition,
}) => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [customWorkplace, setCustomWorkPlace] = useState(
        (additionObject?.workplace_address?.street && true) || record?.workplace_address?.street ? true : false
    )

    const [viewSalary, setViewSalary] = useState(false)
    const [viewWorkplace, setViewWorkplace] = useState(false)
    const [viewFond, setViewFond] = useState(false)
    const [viewPosition, setViewPosition] = useState(false)
    const [viewPeriod, setViewPeriod] = useState(false)
    const [viewTrial, setViewTrial] = useState(false)
    const [indefinitePeriod, setIndefinitePeriod] = useState(
        additionObject?.is_indefinite_period === true ? true : false
    )
    const [contracts, setContracts] = useState([])

    const [form, setForm] = useState({
        type: additionObject?.type?.id || record?.type?.id || '',
        work_position: additionObject?.work_position?.id || record?.work_position?.id || '',
        work_level: additionObject?.work_level?.toString() || record?.work_level?.toString() || '',
        valid_until: additionObject?.valid_until || record?.valid_until || '',
        fond: additionObject?.fond || record?.fond || '40',
        fond_per_unit: additionObject?.fond_per_unit || record?.fond_per_unit || '1',
        salary: additionObject?.salary || record?.salary || 0,
        currency: additionObject?.currency || record?.currency || 'EUR',
        per_unit: additionObject?.per_unit?.toString() || record?.per_unit?.toString() || '1',
        trial_period: additionObject?.trial_period || record?.trial_period || '',
        is_indefinite_period: additionObject?.is_indefinite_period === true ? true : false,
    })

    useEffect(() => {
        async function fetchAddition() {
            if (additionObject?.currency || additionObject?.salary || additionObject?.per_unit) setViewSalary(true)
            if (additionObject?.workplace_address) setViewWorkplace(true)
            if (additionObject?.type?.id || additionObject?.fond || additionObject?.fond_per_unit) setViewFond(true)
            if (additionObject?.work_position?.id || additionObject?.work_level) setViewPosition(true)

            if (additionObject?.valid_until !== null && additionObject?.is_indefinite_period === false) {
                setViewPeriod(true)
            } else if (additionObject?.valid_until === null && additionObject?.is_indefinite_period === true) {
                setViewPeriod(true)
            }
            //if (additionObject?.valid_until) setViewPeriod(true)
            if (additionObject?.trial_period) setViewTrial(true)
        }

        fetchAddition()

        // eslint-disable-next-line
    }, [])

    const isWorkplaceFilled = () => {
        if (workplace.street === '' || customWorkplace === false) {
            return false
        }
        return true
    }

    useEffect(() => {
        async function fetchContractType(selectedType) {
            const request = await requests.get(API.CONTRACTS_TYPES + selectedType + '/?query={id, fond, fond_per_unit}')
            if (request.status === 200) {
                let fetchedType = request.response
                if (fetchedType) {
                    setForm((prev) => {
                        let item = {
                            ...prev,
                            fond: fetchedType?.fond || '40',
                            fond_per_unit: fetchedType?.fond_per_unit?.toString?.() || '1',
                        }
                        return item
                    })
                }
            }
        }
        if (!isMount) {
            if (form.type !== '') {
                fetchContractType(form.type)
            }
        }
        // eslint-disable-next-line
    }, [form.type])

    const [workplace, setWorkplace] = useState({
        street: additionObject?.workplace_address?.street || record?.workplace_address?.street || '',
        city: additionObject?.workplace_address?.city || record?.workplace_address?.city || '',
        postcode: additionObject?.workplace_address?.postcode || record?.workplace_address?.postcode || '',
        country: additionObject?.workplace_address?.country || record?.workplace_address?.country || 'SK',
        address_line: additionObject?.workplace_address?.address_line || record?.workplace_address?.address_line || '',
        state: additionObject?.workplace_address?.state || record?.workplace_address?.state || '',
        number: additionObject?.workplace_address?.number || record?.workplace_address?.number || '',
        orientation_number:
            additionObject?.workplace_address?.orientation_number ||
            record?.workplace_address?.orientation_number ||
            '',
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors([])
        setIsProcessing(true)

        let data
        if (!selected && !addAddition) {
            data = { contract: record?.id || record?.contract?.id || additionObject?.contract?.id }
        }

        const dataSalary = {
            currency: form.currency,
            salary: form.salary,
            per_unit: form.per_unit,
        }
        const dataWorkplace = {
            workplace_address: isWorkplaceFilled() ? workplace : null,
        }
        const dataFond = {
            type: form.type,
            fond: form.fond,
            fond_per_unit: form.fond_per_unit,
        }
        const dataPosition = {
            work_position: form.work_position,
            work_level: form.work_level,
        }
        const dataPeriod = {}
        if (indefinitePeriod) {
            dataPeriod.valid_until = null
            dataPeriod.is_indefinite_period = true
        } else {
            dataPeriod.valid_until = form.valid_until === '' ? null : form.valid_until
            dataPeriod.is_indefinite_period = false
        }

        const dataTrial = {
            trial_period: form.trial_period === '' ? null : form.trial_period,
        }

        if (viewSalary) data = { ...data, ...dataSalary }
        if (viewWorkplace) data = { ...data, ...dataWorkplace }
        if (viewFond) data = { ...data, ...dataFond }
        if (viewPosition) data = { ...data, ...dataPosition }
        if (viewPeriod) data = { ...data, ...dataPeriod }
        if (viewTrial) data = { ...data, ...dataTrial }

        if (selected?.length > 0) {
            let dataset = []

            for (let i = 0; i < selected.length; i++) {
                let selectedData = {
                    ...data,
                    contract: selected[i].id,
                }
                dataset.push(selectedData)
            }
            const request = await requests.post(API.CONTRACTS + 'additions/bulk/', dataset)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                for (let i = 0; i < selected.length; i++) {
                    setData((prev) =>
                        prev.map((item) => {
                            if (selected.find((contract) => contract.id === item.id) !== undefined) {
                                item.contract_additions_count = item.contract_additions_count + 1
                            }

                            return item
                        })
                    )
                }
            }
            onClose()
        } else {
            if (addAddition) {
                let dataset = []

                for (let i = 0; i < contracts.length; i++) {
                    let selectedData = {
                        ...data,
                        contract: contracts[i],
                    }
                    dataset.push(selectedData)
                }

                const request = await requests.post(API.CONTRACTS + 'additions/bulk/', dataset)
                if (request.status === 400) setErrors(request.response)
                if (request.status === 201) {
                    setTotal((prev) => prev + request.response.length)
                    setData((prev) => [...request.response, ...prev])
                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('contract_addition_updated'),
                        animation: 'pulse',
                        time: 2000,
                    })

                    onClose()
                }
            } else if (additionObject) {
                const request = await requests.patch(API.CONTRACTS + 'additions/' + additionObject.id + '/', {
                    ...data,
                    contract: additionObject.contract.id,
                })
                if (request.status === 400) setErrors(request.response)
                if (request.status === 200) {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item.id === additionObject.id) {
                                item = request.response
                            }

                            return item
                        })
                    )

                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('contract_addition_created'),
                        animation: 'pulse',
                        time: 2000,
                    })

                    onClose()
                }
            } else {
                const request = await requests.post(API.CONTRACTS + 'additions/', data)
                if (request.status === 400) setErrors(request.response)
                if (request.status === 201) {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item.id === record.id) {
                                item.contract_additions_count = item.contract_additions_count + 1
                            }

                            return item
                        })
                    )
                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('contract_addition_created'),
                        animation: 'pulse',
                        time: 2000,
                    })

                    onClose()
                }
            }
        }

        setIsProcessing(false)
    }

    const removeSelected = (selectedID) => {
        setSelected((prev) => prev.filter((item) => item.id !== selectedID))
    }

    return (
        <Form onSubmit={handleSubmit}>
            {selected?.length > 0 && (
                <>
                    <Header content={t('contracts')} as="h5" style={{ marginTop: 0 }} />
                    {selected?.map((item) => (
                        <Label>
                            {item?.custom_id}
                            {isMultiple && (
                                <Icon
                                    name="close"
                                    color="red"
                                    onClick={() => removeSelected(item.id)}
                                    style={{ marginLeft: '0.5rem', marginBottom: '-0.1rem' }}
                                />
                            )}
                        </Label>
                    ))}
                    <Divider />
                </>
            )}
            {addAddition && (
                <SuperField
                    multiple
                    as="choice-select"
                    search
                    text={(item) => item.custom_id + ' | ' + item.employee.fullname + ' - ' + item.work_position?.title}
                    label={t('contract')}
                    value={contracts}
                    endpoint={API.CONTRACTS}
                    additionalFilters={'&only_basic_info=true&status=1&status=3&status=6'}
                    onChange={(e, { value }) => setContracts(value)}
                />
            )}
            <SuperField
                as="checkbox"
                label={t('change_of_salary_conditions')}
                checked={viewSalary}
                onChange={(e, { value }) => {
                    setViewSalary(!viewSalary)
                }}
            />
            {viewSalary && (
                <Segment>
                    <Header as="h4">{t('salary_information')}</Header>
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            required
                            label={t('sum')}
                            value={form.salary}
                            onChange={(e, { value }) => setForm({ ...form, salary: setDotSeparator(value) })}
                            error={errors?.salary?.[0] || false}
                        />
                        <SuperField
                            as="choice"
                            search
                            required
                            clearable={false}
                            label={t('currency')}
                            type="currency_codes"
                            value={form.currency}
                            onChange={(e, { value }) => setForm({ ...form, currency: value })}
                            error={errors?.currency?.[0] || false}
                        />
                        <SuperField
                            as="choice"
                            required
                            clearable={false}
                            type="job_salary_type"
                            label={t('salary_type')}
                            value={form.per_unit}
                            onChange={(e, { value }) => setForm({ ...form, per_unit: value })}
                            error={errors?.per_unit?.[0] || false}
                        />
                    </Form.Group>
                </Segment>
            )}
            <Divider />

            <SuperField
                as="checkbox"
                label={t('change_of_workplace')}
                checked={viewWorkplace}
                onChange={(e, { value }) => {
                    setViewWorkplace(!viewWorkplace)
                }}
            />
            {viewWorkplace && (
                <Segment>
                    <Header as="h4" content={t('workplace')} style={{ marginBottom: '0.5rem' }} />
                    <Divider />
                    <SuperField
                        as="checkbox"
                        checked={customWorkplace}
                        onChange={() => setCustomWorkPlace(!customWorkplace)}
                        label={t('workplace_address_is_different_then_employer_address')}
                    />
                    {customWorkplace && <Address isForm address={workplace} setAddress={setWorkplace} />}
                </Segment>
            )}
            <Divider />

            <SuperField
                as="checkbox"
                label={t('change_of_work_fond')}
                checked={viewFond}
                onChange={(e, { value }) => {
                    setViewFond(!viewFond)
                }}
            />
            {viewFond && (
                <Segment>
                    <Header as="h4" content={t('work_fond')} style={{ marginBottom: '0.5rem' }} />
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            search
                            required
                            text="title"
                            size="small"
                            label={t('contract_type')}
                            value={form.type}
                            error={errors?.type?.[0] || false}
                            endpoint={API.CONTRACTS_TYPES}
                            settings="types/contract-types"
                            additionalFilters="&query={id, title}"
                            onChange={(e, { value }) => setForm({ ...form, type: value })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            required
                            label={t('work_fond')}
                            value={form.fond || ''}
                            onChange={(e, { value }) => setForm({ ...form, fond: value.replace(',', '.') })}
                            error={errors?.fond?.[0] || false}
                        />
                        <SuperField
                            as="choice"
                            required
                            clearable={false}
                            type="fond_per_type"
                            label={t('work_fond_type')}
                            value={form.fond_per_unit?.toString?.()}
                            error={errors?.fond_per_unit?.[0] || false}
                            onChange={(e, { value }) => setForm({ ...form, fond_per_unit: value })}
                        />
                    </Form.Group>
                </Segment>
            )}
            <Divider />

            <SuperField
                as="checkbox"
                label={t('change_of_work_position')}
                checked={viewPosition}
                onChange={(e, { value }) => {
                    setViewPosition(!viewPosition)
                }}
            />
            {viewPosition && (
                <Segment>
                    <Header as="h4" content={t('work_position')} style={{ marginBottom: '0.5rem' }} />
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice-select"
                            search
                            required
                            size="small"
                            label={t('work_position')}
                            value={form.work_position}
                            endpoint={API.JOB_POSITIONS}
                            text={(item) => item?.title}
                            error={errors?.work_position?.[0] || false}
                            onChange={(e, { value }) => setForm({ ...form, work_position: value })}
                            additionalFilters="&query={id, title}"
                            initialOptions={{
                                attribute: 'title',
                                source: additionObject?.work_position || record?.work_position || position,
                            }}
                        />
                        <SuperField
                            as="choice"
                            search
                            required
                            clearable={false}
                            label={t('work_level')}
                            type="contracts_work_levels"
                            value={form.work_level?.toString()}
                            error={errors?.work_level?.[0] || false}
                            onChange={(e, { value }) => setForm({ ...form, work_level: value })}
                            initialOptions={{
                                attribute: 'work_level',
                                as_display: true,
                                source: record,
                            }}
                        />
                    </Form.Group>
                </Segment>
            )}
            <Divider />

            <SuperField
                as="checkbox"
                label={t('change_of_contract_validity')}
                checked={viewPeriod}
                onChange={(e, { value }) => {
                    setViewPeriod(!viewPeriod)
                }}
            />
            {viewPeriod && (
                <Segment>
                    <Form.Group>
                        <SuperField
                            as="radio"
                            checked={!indefinitePeriod}
                            onChange={() => setIndefinitePeriod(false)}
                            label={t('defined_period')}
                        />
                        <SuperField
                            as="radio"
                            checked={indefinitePeriod}
                            onChange={() => setIndefinitePeriod(true)}
                            label={t('indefined_period')}
                        />
                    </Form.Group>
                    {!indefinitePeriod && (
                        <SuperField
                            as="datepicker"
                            closable
                            label={t('valid_to')}
                            value={form.valid_until}
                            onChange={(e, { value }) => setForm({ ...form, valid_until: value })}
                            error={errors?.valid_until?.[0] || false}
                        />
                    )}
                </Segment>
            )}
            <Divider />

            <SuperField
                as="checkbox"
                label={t('change_of_trial_period')}
                checked={viewTrial}
                onChange={(e, { value }) => {
                    setViewTrial(!viewTrial)
                }}
            />
            {viewTrial && (
                <Segment>
                    <SuperField
                        as="datepicker"
                        closable
                        label={t('trial_period')}
                        value={form.trial_period}
                        onChange={(e, { value }) => setForm({ ...form, trial_period: value })}
                        error={errors?.trial_period?.[0] || false}
                    />
                </Segment>
            )}
            <Divider />

            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={isProcessing} text={t('save')} />
            </Form.Field>
        </Form>
    )
}

export default ContractAdditionForm
