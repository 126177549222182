import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SpinnerSegment from '@components/SpinnerSegment'
import { Divider, Checkbox } from 'semantic-ui-react'

const HistoryOfChanges = ({ contract, year, month, getDates, showCorrectedRecords, setSize, setHeader }) => {
    const { t } = useTranslation()
    const language = useSelector((state) => state.language)
    const dateFormat = useSelector((state) => state.date_format)
    const [loading, setLoading] = useState(true)
    const [history, setHistory] = useState([])
    const [hideEmptyDays, setHideEmptyDays] = useState(false)

    const dateFrom = moment(year + '-' + month + '-01').startOf('month')
    const dateTo = moment(year + '-' + month + '-01').endOf('month')

    const [dates] = useState(getDates(dateFrom, dateTo))

    useEffect(() => {
        async function fetchData() {
            setLoading(true)

            const firstDay = moment(year + '-' + month + '-01')
                .startOf('month')
                .format('YYYY-MM-DD')
            const lastDay = moment(year + '-' + month + '-01')
                .endOf('month')
                .format('YYYY-MM-DD')

            let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
            if (showCorrectedRecords) correctedRecordsQuery = ''

            // fetch currently active records and their history
            const fetchActiveRecords = await requests.get(
                API.ATTENDANCE +
                    '?query={id, date, created_on, created_by, history}&contracts=' +
                    contract.id +
                    '&date_from=' +
                    firstDay +
                    '&date_to=' +
                    lastDay +
                    correctedRecordsQuery
            )
            // fetch deleted records and their history
            const fetchDeletedRecords = await requests.get(
                API.ATTENDANCE +
                    'deleted_history/' +
                    '?query={id, date, created_on, created_by, deleted_on, deleted_by history}&contracts=' +
                    contract.id +
                    '&date_from=' +
                    firstDay +
                    '&date_to=' +
                    lastDay +
                    correctedRecordsQuery
            )
            // fetch absence records
            const fetchActiveAbsences = await requests.get(
                API.ATTENDANCE_BASE +
                    'absences/' +
                    '?query={id, date, hours, absence_type{id, title}  created_on, created_by}&contract=' +
                    contract.id +
                    '&date_after=' +
                    firstDay +
                    '&date_before=' +
                    lastDay +
                    correctedRecordsQuery
            )
            // fetch deleted absence records
            const fetchDeletedAbsences = await requests.get(
                API.ATTENDANCE_BASE +
                    'absences/deleted_history/' +
                    '?query={id, date, hours, absence_type{id, title}  created_on, created_by, deleted_by, deleted_on}&contract=' +
                    contract.id +
                    '&date_after=' +
                    firstDay +
                    '&date_before=' +
                    lastDay +
                    correctedRecordsQuery
            )

            // create list of history items chronologically
            let historyItems = [] // { date, history_type, recorded_on, recorded_by, old_value, new_value  }

            if (fetchActiveRecords.status === 200) {
                const record = fetchActiveRecords.response
                for (let i = 0; i < record.length; i++) {
                    const recordHistory = record[i].history
                    historyItems.push({
                        date: record[i].date,
                        history_type: 'created_on',
                        recorded_on: record[i].created_on,
                        recorded_by: record[i].created_by?.name,
                        old_value: null,
                        new_value: null,
                    })

                    for (let y = 0; y < recordHistory.length; y++) {
                        historyItems.push({
                            date: record[i].date,
                            history_type: recordHistory[y].field,
                            recorded_on: recordHistory[y].updated_at,
                            recorded_by: recordHistory[y].updated_by,
                            old_value: recordHistory[y].old_value,
                            new_value: recordHistory[y].new_value,
                        })
                    }
                }
            }

            if (fetchDeletedRecords.status === 200) {
                const record = fetchDeletedRecords.response
                for (let i = 0; i < record.length; i++) {
                    const recordHistory = record[i].history
                    historyItems.push({
                        date: record[i].date,
                        history_type: 'created_on',
                        recorded_on: record[i].created_on,
                        recorded_by: record[i].created_by?.name,
                        old_value: null,
                        new_value: null,
                    })

                    for (let y = 0; y < recordHistory.length; y++) {
                        historyItems.push({
                            date: record[i].date,
                            history_type: recordHistory[y].field,
                            recorded_on: recordHistory[y].updated_at,
                            recorded_by: recordHistory[y].updated_by,
                            old_value: recordHistory[y].old_value,
                            new_value: recordHistory[y].new_value,
                        })
                    }

                    historyItems.push({
                        date: record[i].date,
                        history_type: 'deleted_on',
                        recorded_on: record[i].deleted_on,
                        recorded_by: record[i].deleted_by?.name,
                        old_value: null,
                        new_value: null,
                    })
                }
            }

            if (fetchActiveAbsences.status === 200) {
                const record = fetchActiveAbsences.response
                for (let i = 0; i < record.length; i++) {
                    historyItems.push({
                        date: record[i].date,
                        history_type: 'absence_add',
                        recorded_on: record[i].created_on,
                        recorded_by: record[i].created_by?.name || '',
                        old_value: record[i].absence_type?.title || '',
                        new_value: record[i].hours,
                    })
                }
            }

            if (fetchDeletedAbsences.status === 200) {
                const record = fetchDeletedAbsences.response
                for (let i = 0; i < record.length; i++) {
                    historyItems.push({
                        date: record[i].date,
                        history_type: 'absence_add',
                        recorded_on: record[i].created_on,
                        recorded_by: record[i].created_by?.name,
                        old_value: record[i].absence_type?.title || '',
                        new_value: record[i].hours,
                    })

                    historyItems.push({
                        date: record[i].date,
                        history_type: 'absence_remove',
                        recorded_on: record[i].deleted_on,
                        recorded_by: record[i].deleted_by?.name,
                        old_value: record[i].absence_type?.title || '',
                        new_value: record[i].hours,
                    })
                }
            }

            const sortedHistoryItems = historyItems.sort((a, b) => {
                const dateComparison = new Date(a.date) - new Date(b.date)
                if (dateComparison !== 0) return dateComparison

                return new Date(a.recorded_on) - new Date(b.recorded_on)
            })

            setHistory(sortedHistoryItems)
            setHeader(
                `${t('history_of_changes')} - ${contract?.employee?.fullname_with_titles || ''} - ${month}/${year}`
            )
            setSize('small')
            setLoading(false)
        }

        fetchData()
    }, [])

    const getHistoryData = (date) => {
        return history.filter((item) => item.date === date)
    }

    const getDays = () => {
        let validDays = dates
        if (hideEmptyDays) {
            return validDays.filter((item) => getHistoryData(item).length > 0)
        }

        return validDays
    }

    const getField = (field) => {
        if (field === 'start') return t('arrival_record')
        if (field === 'end') return t('departure_record')
        if (field === 'pause_start') return t('pause_start_record')
        if (field === 'pause_end') return t('pause_end_record')
        if (field === 'second_pause_start') return t('second_pause_start_record')
        if (field === 'second_pause_end') return t('second_pause_end_record')
    }

    const displayFieldChangePhrase = (item) => {
        if (!['created_on', 'deleted_on', 'absence_add', 'absence_remove'].includes(item.history_type)) {
            return (
                <>
                    <span>{t('change_of') + ' '}</span>
                    <span>{getField(item.history_type)}</span>
                    <span>{' ' + t('from') + ' '}</span>
                    <span>{tzDateTime(item.old_value).format('HH:mm ')}</span>
                    <span>{t('to_record') + ' '}</span>
                    <span>{tzDateTime(item.new_value).format('HH:mm')}.</span>
                </>
            )
        } else {
            return ''
        }
    }

    const getBorderColor = (history_type) => {
        let color = 'var(--dark)'

        if (history_type === 'deleted_on' || history_type === 'absence_remove') {
            color = 'var(--danger)'
        }

        if (history_type === 'created_on' || history_type === 'absence_add') {
            color = 'green'
        }

        return color
    }

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('loading_history')} marginBottom="0">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Checkbox
                    checked={hideEmptyDays}
                    onChange={() => setHideEmptyDays(!hideEmptyDays)}
                    label={t('hide_days_with_no_changes')}
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
                    <span>
                        <Icon
                            name="square"
                            color={'green'}
                            style={{ position: 'relative', top: '0.2rem', marginRight: '0.25rem' }}
                        />
                        {t('added')}
                    </span>
                    <span>
                        <Icon
                            name="square"
                            color={'var(--danger'}
                            style={{ position: 'relative', top: '0.2rem', marginRight: '0.25rem' }}
                        />
                        {t('removed')}
                    </span>
                    <span>
                        <Icon
                            name="square"
                            color={'var(--dark)'}
                            style={{ position: 'relative', top: '0.2rem', marginRight: '0.25rem' }}
                        />
                        {t('updated').toLowerCase()}
                    </span>
                </div>
            </div>
            <Divider />
            {getDays().map((date) => (
                <>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div style={{ width: '50px' }}>
                            <div
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid var(--dark)',
                                    borderRadius: 'var(--border-radius',
                                    background: 'var(--light)',
                                    color: 'var(--dark)',
                                    fontWeight: 'bold',
                                }}
                            >
                                <div style={{ fontSize: '1.2rem' }}>{moment(date).format('DD') + '.'}</div>
                                <small>{moment(date).locale(language).format('dd').toUpperCase()}</small>
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            {getHistoryData(date).length > 0 ? (
                                getHistoryData(date).map((item) => (
                                    <div
                                        style={{
                                            marginBottom: '0.25rem',
                                            background: 'var(--light)',
                                            borderLeft: `4px solid ${getBorderColor(item.history_type)}`,
                                            padding: '0.5rem',
                                            width: '100%',
                                        }}
                                    >
                                        <div style={{ fontSize: '0.9rem', opacity: '0.7' }}>
                                            {t('recorded_by')} <strong>{item.recorded_by}</strong> {t('at') + ' '}
                                            <strong>
                                                {tzDateTime(item.recorded_on).format(dateFormat + ' HH:mm')}
                                            </strong>
                                        </div>
                                        <div style={{ fontWeight: 'bold' }}>
                                            {item.history_type === 'created_on' && t('record_created') + '.'}
                                            {item.history_type === 'deleted_on' && t('record_removed') + '.'}

                                            {item.history_type === 'absence_add' &&
                                                t('work_absence_added') +
                                                    ': ' +
                                                    item.old_value.toLowerCase() +
                                                    ' - ' +
                                                    item.new_value +
                                                    ' ' +
                                                    t('hours_shortcut').toLowerCase() +
                                                    '. '}

                                            {item.history_type === 'absence_remove' &&
                                                t('work_absence_removed') +
                                                    ': ' +
                                                    item.old_value.toLowerCase() +
                                                    ' - ' +
                                                    item.new_value +
                                                    ' ' +
                                                    t('hours_shortcut').toLowerCase() +
                                                    '. '}

                                            {displayFieldChangePhrase(item)}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span style={{ opacity: '0.6' }}>{t('no_changes_for_this_day')}</span>
                            )}
                        </div>
                    </div>
                    <Divider />
                </>
            ))}
        </SpinnerSegment>
    )
}

export default HistoryOfChanges
