import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { renderAddress } from '@helpers/functions'
// components
import SpinnerSegment from '@components/SpinnerSegment'
import EmptyRow from '@components/tables/EmptyRow'
import Icon from '@components/Icon'
import { Divider, Header, Card, Label } from 'semantic-ui-react'
import SortedFileRenderer from '@components/SortedFileRenderer'

const AdditionsDetail = ({ contractID }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const [isLoading, setIsLoading] = useState(false)
    const [addition, setAddition] = useState({})

    useEffect(() => {
        async function fetchAdditions() {
            setIsLoading(true)
            const request = await requests.get(
                API.CONTRACTS + 'additions/?contract=' + contractID + '&ordering=-number'
            )
            if (request.status === 200) {
                setAddition(request.response)
            }
            setIsLoading(false)
        }

        fetchAdditions()

        // eslint-disable-next-line
    }, [contractID])

    const CardContent = ({ item }) => {
        const [open, setOpen] = useState(false)

        const dataSalary = () => {
            if (item?.currency || item?.salary || item?.per_unit_display) {
                return (
                    <div>
                        <strong>{t('salary') + ': '}</strong>
                        <span>{item?.salary + ' ' + item?.currency + ' ' + t(item?.per_unit_display)}</span>
                    </div>
                )
            }
            return null
        }
        const dataWorkplace = () => {
            if (item?.workplace_address) {
                return (
                    <div>
                        <strong>{t('workplace') + ': '}</strong>
                        <span>{renderAddress(item.workplace_address)}</span>
                    </div>
                )
            }
            return null
        }
        const dataPosition = () => {
            if (item?.work_position?.title || item?.work_level_display) {
                return (
                    <div>
                        <strong>{t('position') + ': '}</strong>
                        <span>{item?.work_position?.title + ' - ' + item?.work_level_display}</span>
                    </div>
                )
            }
            return null
        }
        const dataType = () => {
            if (item?.type?.title) {
                return (
                    <div>
                        <strong>{t('type') + ': '}</strong>
                        <span>{item?.type?.title}</span>
                    </div>
                )
            }
            return null
        }
        const dataFond = () => {
            if (item?.fond || item?.fond_per_unit_display) {
                return (
                    <div>
                        <strong>{t('fond') + ': '}</strong>
                        <span>
                            {item?.fond + ' ' + t('_hours').toLocaleLowerCase() + ' ' + t(item?.fond_per_unit_display)}
                        </span>
                    </div>
                )
            }
            return null
        }
        const dataPeriod = () => {
            if (item?.is_indefinite_period === true) {
                return (
                    <div>
                        <strong>{t('valid_until') + ': '}</strong>
                        <span>{t('indefined_period')}</span>
                    </div>
                )
            } else if (item?.valid_until) {
                return (
                    <div>
                        <strong>{t('valid_until') + ': '}</strong>
                        <span>
                            {item?.valid_until
                                ? moment(item?.valid_until).format(dateFormat)
                                : item?.is_indefined_period
                                ? t('indefined_period')
                                : ''}
                        </span>
                    </div>
                )
            }
            return null
        }
        const dataTrial = () => {
            if (item?.trial_period) {
                return (
                    <div>
                        <strong>{t('trial_period') + ': '}</strong>
                        <span>{item?.trial_period}</span>
                    </div>
                )
            }
            return null
        }

        return (
            <Card fluid>
                <Card.Content
                    style={{
                        backgroundColor: open ? 'var(--light)' : 'white',
                    }}
                >
                    <div
                        onClick={() => setOpen(!open)}
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start', // 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            fontSize: '1.2rem',
                        }}
                    >
                        <div style={{ fontWeight: 'bold' }}>{t('num_addition') + ' ' + item?.number}</div>
                        <div style={{ display: 'flex' }}>
                            {item?.is_draft ? (
                                <Label
                                    style={{
                                        background: 'var(--blue)',
                                        color: 'var(--white)',
                                        textAlign: 'center',
                                        marginRight: '1rem',
                                    }}
                                >
                                    {t('draft')}{' '}
                                </Label>
                            ) : (
                                <Label
                                    style={{
                                        background: 'var(--success)',
                                        color: 'var(--white)',
                                        textAlign: 'center',
                                        marginRight: '1rem',
                                    }}
                                >
                                    {t('signed')}{' '}
                                </Label>
                            )}
                            <Icon
                                style={{ cursor: 'pointer', fontSize: '1.4rem', marginTop: '0.3rem' }}
                                onClick={() => setOpen(!open)}
                                name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
                            />
                        </div>
                    </div>
                </Card.Content>
                {open && (
                    <Card.Content extra style={{ color: 'black' }}>
                        <Header as={'h4'} content={t('contract_changes') + ': '} />
                        {dataSalary()}
                        {dataWorkplace()}
                        {dataPosition()}
                        {dataType()}
                        {dataFond()}
                        {dataPeriod()}
                        {dataTrial()}
                        <div style={{ paddingTop: '1rem' }}>
                            <strong>{t('created_by') + ': '}</strong>
                            <span style={{ paddingLeft: '1rem' }}>
                                {item?.created_by?.name +
                                    ' ' +
                                    t('at') +
                                    ' ' +
                                    moment(item?.created_on).format(dateFormat)}
                            </span>
                        </div>
                        {!item?.is_draft && (
                            <div>
                                <strong>{t('signed_by') + ': '}</strong>
                                <span style={{ paddingLeft: '1rem' }}>
                                    {item?.contract?.employee?.fullname +
                                        ' ' +
                                        t('at') +
                                        ' ' +
                                        moment(item?.date_of_signature).format(dateFormat)}
                                </span>
                            </div>
                        )}
                        <Divider />
                        {item?.files?.length > 0
                            ? item?.files.map((attachment) => (
                                  <div
                                      key={attachment.id}
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'middle',
                                          justifyContent: 'space-between',
                                          marginTop: '1rem',
                                      }}
                                  >
                                      <SortedFileRenderer
                                          document={attachment}
                                          name={attachment.name}
                                          file={attachment.file}
                                      />
                                  </div>
                              ))
                            : t('no_attachments')}
                    </Card.Content>
                )}
            </Card>
        )
    }

    return (
        <SpinnerSegment loading={isLoading}>
            {!isLoading && (
                <>
                    {addition?.results?.length > 0
                        ? addition?.results?.map((item, idx) => <CardContent item={item} index={idx} />)
                        : t('no_data')}
                </>
            )}
        </SpinnerSegment>
    )
}

export default AdditionsDetail
