import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { isEmpty } from '@helpers/validation'
// components
import Icon from '@components/Icon'
import SpinnerSegment from '@components/SpinnerSegment'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SuperField from '@components/forms/SuperField'
import { Button, Divider, Form, Header, Message, Icon as SemanticIcon, Container } from 'semantic-ui-react'
import NonFieldErrors from '@components/NonFieldErrors'
import SortedFileRenderer from '@components/SortedFileRenderer'
import MKEditor from '@components/editors/MKEditor'

const BugTrackerForm = ({ onClose }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false)

    const [resources, setResources] = useState(null)
    const [errors, setErrors] = useState(null)
    const [files, setFiles] = useState([])

    const user = useSelector((state) => state.user)
    const language = useSelector((state) => state.language)

    const requestTypes = [
        { key: 0, value: 8, valueText: t('bug'), text: t('reporting_bug') },
        { key: 1, value: 9, valueText: t('improvement'), text: t('reporting_improvement') },
        { key: 2, value: 10, valueText: t('support'), text: t('reporting_support') },
    ]

    const [form, setForm] = useState({
        title: '',
        description: '',
        contact_email: user.email,
    })

    const [type, setType] = useState('')
    const [view, setView] = useState(1)
    const [responseNumber, setResponseNumber] = useState('')

    useEffect(() => {
        async function getKeys() {
            setLoading(true)
            const request = await requests.get(API.COMMON + 'api_keys/')
            if (request.status === 200) setResources(request.response)
            setLoading(false)
        }

        getKeys()
    }, [])

    function getSubDomain() {
        const host = window.location.host
        const parts = host.split('.')

        if (parts.length > 2) {
            return parts[0]
        } else {
            return null
        }
    }

    const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/zip',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/png',
        'image/gif',
        'text/xml',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'text/csv',
    ]

    function getBrowserInfo() {
        const userAgent = navigator.userAgent
        let browserName, browserVersion

        if (userAgent.indexOf('Firefox') > -1) {
            browserName = 'Mozilla Firefox'
            browserVersion = userAgent.match(/Firefox\/(\d+\.\d+)/)[1]
        } else if (
            userAgent.indexOf('Chrome') > -1 &&
            userAgent.indexOf('Edg') === -1 &&
            userAgent.indexOf('OPR') === -1
        ) {
            browserName = 'Google Chrome'
            browserVersion = userAgent.match(/Chrome\/(\d+\.\d+)/)[1]
        } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
            browserName = 'Apple Safari'
            browserVersion = userAgent.match(/Version\/(\d+\.\d+)/)[1]
        } else if (userAgent.indexOf('Edg') > -1) {
            browserName = 'Microsoft Edge'
            browserVersion = userAgent.match(/Edg\/(\d+\.\d+)/)[1]
        } else if (userAgent.indexOf('OPR') > -1 || userAgent.indexOf('Opera') > -1) {
            browserName = 'Opera'
            browserVersion = userAgent.match(/(Opera|OPR)\/(\d+\.\d+)/)[2]
        } else if (userAgent.indexOf('MSIE') > -1 || !!document.documentMode) {
            browserName = 'Internet Explorer'
            browserVersion = userAgent.match(/MSIE (\d+\.\d+)/)
                ? userAgent.match(/MSIE (\d+\.\d+)/)[1]
                : document.documentMode
        } else {
            browserName = 'Unknown'
            browserVersion = 'Unknown'
        }

        return { browserName, browserVersion }
    }

    function getDescription(value) {
        const browserInfo = getBrowserInfo()
        return `
            ${value}
            
            **${t('request_type')}:** ${requestTypes.find((item) => item.value === type)?.valueText}
            **${t('browser_type')}:** ${browserInfo.browserName}, Version: ${browserInfo.browserVersion}
            **${t('url_address')}:** ${window.location.href.toString()}
            **${t('user')}:** ${user.name} (${user.email})
        `
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        let formFiles = {}

        if (files?.length > 0) {
            const formData = new FormData()
            for (let i = 0; i < files?.length; i++) {
                formData.append('files', files[i])
            }

            const requestAtt = await requests.post(
                resources?.cases_url + 'attachments/',
                formData,
                true,
                `APIkey ${resources?.cases_api_key}`
            )

            if (requestAtt.status === 201) {
                setFiles([])
                formFiles = { add: requestAtt.response.map((item) => item.id) }
                document.getElementById('fileInput').value = null

                let payload = {
                    ...form,
                    type: type,
                    unit: 4,
                    description: getDescription(form.description),
                    attachments: formFiles,
                    title: form.title,
                }

                const request = await requests.post(
                    resources?.cases_url,
                    payload,
                    true,
                    `APIkey ${resources?.cases_api_key}`
                )

                if (request.status === 400) {
                    if (request.response?.type || request.response?.unit) {
                        if (request.response?.type) delete payload.type
                        if (request.response?.unit) delete payload.unit

                        const requestAgain = await requests.post(
                            resources?.cases_url,
                            payload,
                            true,
                            `APIkey ${resources?.cases_api_key}`
                        )

                        if (requestAgain.status === 201) {
                            setView(2)
                            setResponseNumber(requestAgain.response.id)
                        }
                    }
                }

                if (request.status === 201) {
                    setView(2)
                    setResponseNumber(request.response.id)
                }
            } else setErrors(requestAtt.response.file)
        } else {
            let payload = {
                ...form,
                type: type,
                unit: 4,
                description: getDescription(form.description),
                attachments: formFiles,
                title: form.title,
            }
            const request = await requests.post(
                resources?.cases_url,
                payload,
                true,
                `APIkey ${resources?.cases_api_key}`
            )

            if (request.status === 400) {
                if (request.response?.type || request.response?.unit) {
                    if (request.response?.type) delete payload.type
                    if (request.response?.unit) delete payload.unit

                    const requestAgain = await requests.post(
                        resources?.cases_url,
                        payload,
                        true,
                        `APIkey ${resources?.cases_api_key}`
                    )

                    if (requestAgain.status === 201) {
                        setView(2)
                        setResponseNumber(requestAgain.response.id)
                    }
                }
            }

            if (request.status === 201) {
                //onClose()
                setView(2)
                setResponseNumber(request.response.id)
            }
        }

        setIsProcessing(false)
    }

    const hiddenFrontFileInput = React.useRef(null)
    const handleFrontClick = (event) => {
        hiddenFrontFileInput.current.click()
    }

    const removeFile = (fileName) => {
        setFiles(Array.from(files).filter((file) => file.name !== fileName))
    }

    const checkType = (fileType) => {
        return allowedFileTypes.includes(fileType)
    }

    const checkSize = (fileSize) => {
        return fileSize <= 2 * 1024 * 1024
    }

    const checkFilesValidity = (files) => {
        return Array.from(files).every((file) => allowedFileTypes.includes(file.type))
    }

    const checkFilesSize = (files) => {
        return Array.from(files).every((file) => file.size <= 2 * 1024 * 1024)
    }

    const resetForm = () => {
        setForm({
            title: '',
            description: '',
            contact_email: user.email,
        })
        setType('')
        setErrors(null)
        setFiles([])
        setResponseNumber('')
        setView(1)
    }

    const formView = () => {
        return (
            <Form onSubmit={handleSubmit}>
                <NonFieldErrors errors={errors} />
                <Message error visible={errors?.length > 0} header={t('error_submission_message')} list={errors} />
                <Message
                    info
                    visible
                    content={
                        <strong>
                            <Icon
                                name="information-circle"
                                style={{
                                    marginRight: '0.5rem',
                                    fontSize: '1.2rem',
                                    position: 'relative',
                                    top: '0.2rem',
                                }}
                            />
                            <>
                                {t('if_you_need_help_please_visit_our') + ' '}
                                <a
                                    href={`https://pulsawork.com/${language}/help`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('support_page')}
                                </a>{' '}
                                .
                            </>
                        </strong>
                    }
                    style={{ marginTop: 0 }}
                />
                {resources?.cases_api_key !== null ? (
                    <>
                        <SuperField
                            as="input"
                            autoFocus
                            required
                            label={t('title')}
                            placeholder={t('title')}
                            error={errors?.title || false}
                            value={form.title}
                            onChange={(e, { value }) =>
                                setForm({
                                    ...form,
                                    title: value,
                                })
                            }
                        />
                        <SuperField
                            as="choice-select"
                            search
                            required
                            label={t('request_type')}
                            value={type}
                            customOptions={requestTypes}
                            error={errors?.type || false}
                            onChange={(e, { value }) => setType(value)}
                        />
                        <MKEditor
                            //readOnly={readOnly}
                            excludeHeaders
                            excludeDiffSource
                            excludeCheckList
                            label={t('description')}
                            markdown={form.description}
                            onChange={(value) => setForm((prev) => ({ ...prev, description: value }))}
                        />
                        <Form.Group widths={'equal'}>
                            <div style={{ paddingLeft: '0.5rem' }}>
                                <input
                                    type="file"
                                    multiple
                                    id="fileInput"
                                    ref={hiddenFrontFileInput}
                                    onChange={(event) => {
                                        if (event?.target?.files?.length > 0) {
                                            setFiles(event.target.files)
                                        }
                                    }}
                                    style={{ display: 'none' }}
                                />
                                <div
                                    style={{
                                        padding: '0.7rem',
                                        cursor: 'pointer',
                                        border: '1px solid var(--light-grey)',
                                    }}
                                    onClick={handleFrontClick}
                                >
                                    <Header as="h4" style={{ marginTop: 0, fontSize: '15px' }}>
                                        <SemanticIcon
                                            name="upload"
                                            size="tiny"
                                            style={{ color: 'var(--primary)', fontSize: '15px' }}
                                        />
                                        {t('upload_files')}
                                    </Header>
                                </div>
                            </div>
                            <div style={{ padding: '0.8rem' }}>{files.length + ' ' + t('files_selected')}</div>
                            {Array.from(files)?.length > 0 && (
                                <div style={{ padding: '0.8rem', color: 'var(--danger)' }} onClick={() => setFiles([])}>
                                    <Icon
                                        name="close"
                                        style={{
                                            marginLeft: '1rem',
                                            fontSize: '1.2rem',
                                            color: 'var(--danger)',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </div>
                            )}
                        </Form.Group>
                        {Array.from(files)?.length > 0 &&
                            Array.from(files).map((attachment, index) => (
                                <>
                                    <div
                                        key={attachment.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'middle',
                                            justifyContent: 'space-between',
                                            marginTop: '1rem',
                                            marginRight: '5rem',
                                        }}
                                    >
                                        <SortedFileRenderer
                                            document={attachment}
                                            name={attachment.name}
                                            file={attachment.name}
                                            fontSize="12px"
                                            notSupported={!checkType(attachment.type)}
                                            fileSize={!checkSize(attachment.size)}
                                        />

                                        <Icon
                                            name="close"
                                            style={{
                                                marginLeft: '1rem',
                                                fontSize: '1.2rem',
                                                color: 'var(--danger)',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => removeFile(attachment.name)}
                                        />
                                    </div>
                                </>
                            ))}
                        <Divider />
                        <Form.Field style={{ textAlign: 'right' }}>
                            <ModalCancel onClose={onClose} disabled={isProcessing} />
                            <ModalSubmit
                                disabled={
                                    isProcessing ||
                                    loading ||
                                    isEmpty(form.title) ||
                                    isEmpty(type) ||
                                    !checkFilesValidity(files) ||
                                    !checkFilesSize(files)
                                }
                                loading={isProcessing || loading}
                                text={t('send-report')}
                            />
                        </Form.Field>
                    </>
                ) : (
                    <Message visible error style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                        <Icon
                            name="close-circle-outline"
                            style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                        />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>{t('you_have_not_access')}</span>
                    </Message>
                )}
            </Form>
        )
    }

    const containerView = () => {
        return (
            <Container textAlign="center">
                <Header as={'h3'}>{t('request_received_under') + responseNumber + '.'}</Header>
                <Divider />
                <Button basic type="button" onClick={() => resetForm()}>
                    {t('report_another_request')}
                </Button>
                <Button primary type="button" onClick={() => onClose()}>
                    {t('i_understand')}
                </Button>
            </Container>
        )
    }

    const inavailableView = () => {
        return (
            <Container>
                <Message
                    info
                    visible
                    content={
                        <strong>
                            <Icon
                                name="information-circle"
                                style={{
                                    marginRight: '0.5rem',
                                    fontSize: '1.2rem',
                                    position: 'relative',
                                    top: '0.2rem',
                                }}
                            />
                            <>
                                {t('if_you_need_help_please_visit_our') + ' '}
                                <a
                                    href={`https://pulsawork.com/${language}/help`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('support_page')}
                                </a>{' '}
                                .
                            </>
                        </strong>
                    }
                    style={{ marginTop: 0 }}
                />
                <Header as={'h3'} color="red">
                    {t('service_is_not_available')}
                </Header>
            </Container>
        )
    }

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('loading')}>
            {view === 1 ? (resources?.cases_api_key ? formView() : inavailableView()) : view === 2 && containerView()}
        </SpinnerSegment>
    )
}

const BugReporter = ({ trigger }) => {
    const { t } = useTranslation()

    return (
        <SuperDuperModal
            trigger={
                trigger ? (
                    trigger
                ) : (
                    <div
                        style={{
                            position: 'fixed',
                            bottom: 15,
                            right: 10,
                            background: 'var(--primary)',
                            color: 'var(--light)',
                            padding: '0.5rem',
                            borderRadius: '50%',
                            width: '45px',
                            height: '45px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            zIndex: '99999',
                        }}
                    >
                        <Icon
                            name="bug-outline"
                            style={{ marginTop: '0.3rem', fontSize: '1.4rem', transform: 'rotate(10deg)' }}
                        />
                    </div>
                )
            }
            header={t('report_bug_or_request')}
            content={<BugTrackerForm />}
        />
    )
}

export default BugReporter
