import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { formatPhoneNumber } from '@helpers/functions'
// components
import { Grid, Icon as SemanticIcon } from 'semantic-ui-react'
import AvatarIcon from '@components/AvatarIcon'
import ListView from '@components/ListView'
import SuperField from '@components/forms/SuperField'
import ProjectMemberCreateForm from '../../forms/ProjectMemberCreateForm'

const ProjectMemberTab = ({ project, members, setMembers }) => {
    const { t } = useTranslation()

    const PutAvatar = ({ row }) => {
        return (
            <Grid style={{ padding: 0 }}>
                <Grid.Row style={{ padding: 0 }}>
                    <SemanticIcon.Group style={{ marginRight: '0.5rem', padding: '0.5rem' }}>
                        <AvatarIcon
                            size={35}
                            name={row?.profile?.fullname || 'JD'}
                            src={row?.profile?.profile_picture || null}
                        />
                    </SemanticIcon.Group>
                    {row?.profile?.user?.username ? (
                        <span style={{ display: 'flex', flexDirection: 'column', paddingTop: '0.5rem' }}>
                            <Link to={routes.EMPLYOEES_DETAIL + row?.profile?.id} target="_blank">
                                <strong>{row?.profile?.fullname}</strong>
                            </Link>
                            <div>{row?.profile?.user?.username}</div>
                        </span>
                    ) : (
                        <span style={{ display: 'flex', flexDirection: 'column', paddingTop: '0.9rem' }}>
                            <Link to={routes.EMPLYOEES_DETAIL + row?.profile?.id} target="_blank">
                                <strong>{row?.profile?.fullname}</strong>
                            </Link>
                        </span>
                    )}
                </Grid.Row>
            </Grid>
        )
    }

    const onDelete = async (item, setData, setTotal) => {
        const request = await requests.del(API.PROJECT_MEMBERS + item.id + '/')
        if (request.status === 204) {
            setTotal((prev) => prev - 1)
            setData((prev) => prev.filter((member) => member.id !== item.id))
            setMembers((prev) => prev.filter((member) => member.id !== item.id))
        }
    }

    return (
        <ListView
            as="table"
            allowSearch
            endpoint={API.PROJECT_MEMBERS}
            query={`&project=${project.id}`}
            noContainer
            actionsCellWidth="2"
            initialFilters={{
                gitlab_role: '',
            }}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField
                        as="choice"
                        type="gitlab_roles"
                        label={t('gitlab_role')}
                        value={filters.gitlab_role}
                        onChange={(e, { value }) => setFilters({ ...filters, gitlab_role: value })}
                    />
                </>
            )}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('add_member'),
                    modalSize: 'tiny',
                    modal: <ProjectMemberCreateForm project={project} members={members} setMembers={setMembers} />,
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('delete'),
                    customDelete: (item, setData, setTotal) => onDelete(item, setData, setTotal),
                },
            ]}
            tableHeaders={[
                { title: t('employee') },
                { title: t('phone') },
                { title: t('email') },
                { title: t('external_employee') },
                { title: t('access_granted') },
                project?.allow_gitlab && { title: t('gitlab_role') },
            ]}
            renderCells={(projectMember) => [
                { content: <PutAvatar key={projectMember.id} row={projectMember} /> },
                {
                    content: projectMember?.profile?.contacts?.[0]?.phone
                        ? formatPhoneNumber(projectMember?.profile?.contacts?.[0]?.phone)
                        : '--',
                },
                { content: projectMember?.profile?.contacts?.[0]?.email },
                { content: projectMember?.profile?.is_external === true ? t('yes') : t('no') },
                {
                    content: (
                        <>
                            <span>
                                <strong>{moment(projectMember?.created_on).format('YYYY-MM-DD')}</strong> {t('by')}{' '}
                            </span>{' '}
                            <br />
                            <Link to={routes.EMPLYOEES_DETAIL + projectMember?.created_by?.profile_id} target="_blank">
                                <strong>{projectMember?.created_by?.name}</strong>
                            </Link>
                            <strong>{projectMember?.milestone?.name}</strong> <br />
                            {projectMember?.milestone?.from_date && projectMember?.milestone?.due_date && (
                                <span>
                                    {projectMember?.milestone?.from_date} - {projectMember?.milestone?.due_date}
                                </span>
                            )}
                        </>
                    ),
                },
                project?.allow_gitlab && { content: projectMember?.gitlab_role_display },
            ]}
        />
    )
}

export default ProjectMemberTab
