import React, { Fragment, useState, useEffect, useRef } from 'react'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useIsMount, useHasPermission } from '@helpers/hooks'
import { setLocaleLanguage, tzDateTime, getDates, convertTime } from '@helpers/dates'
import SuperField from '@components/forms/SuperField'
// eslint-disable-next-line
import CanView from '@components/perms/CanView'
import Icon from '@components/Icon'
import {
    Divider,
    Form,
    Button,
    Table,
    Popup,
    Segment,
    Modal,
    Grid,
    // eslint-disable-next-line
    Icon as SemanticIcon,
    // eslint-disable-next-line
    Input,
} from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ShortenText from '@components/ShortenText'
// specific components
import PayrollDetails from '../../payrolls/components/PayrollDetails'
import CellEvent from '../components/attendance-compare-view/CellEvent'
import BankTransfer from '../components/attendance-compare-view/BankTransfer'
import BankModalView from '../components/attendance-compare-view/BankModalView'

const calculateContractAbsences = (contract, records, isReality) => {
    let duration = 0
    let contractRecords = []
    if (isReality) {
        contractRecords = records.filter((item) => item.contract === contract?.id)
    } else {
        contractRecords = records.filter((item) => item.contract === contract?.id && item.type === 3)
    }
    for (let i = 0; i < contractRecords.length; i++) {
        duration += parseFloat(contractRecords[i].hours || 0)
    }

    return parseFloat(duration).toFixed(2).replace('.00', '')
}

const calculateContractDuration = (contract, records, isReality) => {
    let duration = 0
    let contractRecords = []
    if (isReality) {
        contractRecords = records.filter((item) => item.contract === contract?.id)
    } else {
        contractRecords = records.filter((item) => item.contract === contract?.id && item.type === 3)
    }
    for (let i = 0; i < contractRecords.length; i++) {
        duration += parseFloat(contractRecords[i].duration)
    }

    return parseFloat(duration).toFixed(2).replace('.00', '')
}

const calculateDurationByType = (contract, records, isReality, type, dates) => {
    let duration = 0
    let recordsList = []

    if (type === 'holiday') {
        records = records.filter((record) => record.is_holiday)
    } else if (type === 'sunday') {
        records = records.filter((record) => record.is_sunday)
    } else if (type === 'saturday') {
        records = records.filter((record) => record.is_saturday)
    }

    if (isReality) {
        recordsList = records.filter((item) => item.contract === contract?.id)
    }

    for (let i = 0; i < recordsList.length; i++) {
        duration += parseFloat(recordsList[i].duration)
    }

    return parseFloat(duration).toFixed(2).replace('.00', '')
}

const calculateWeekContractDuration = (week, contract, records, isReality) => {
    let duration = 0
    let weekRecords = []

    if (isReality) {
        weekRecords = records.filter((item) => item.contract === contract && item.week === week)
    } else {
        weekRecords = records.filter((item) => item.contract === contract && item.week === week && item.type === 3)
    }

    for (let i = 0; i < weekRecords.length; i++) {
        duration += parseFloat(weekRecords[i].duration)
    }

    return parseFloat(duration).toFixed(2).replace('.00', '')
}

const calculateWeekDuration = (week, records, isReality) => {
    let duration = 0
    let weekRecords = []
    if (isReality) {
        weekRecords = records.filter((item) => item.week === week)
    } else {
        weekRecords = records.filter((item) => item.week === week && item.type === 3)
    }
    for (let i = 0; i < weekRecords.length; i++) {
        duration += parseFloat(weekRecords[i].duration)
    }

    return parseFloat(duration).toFixed(2).replace('.00', '')
}

const hasRecords = (
    contract,
    records,
    attendanceRecords,
    absenceRecords,
    attendanceAbsenceRecords,
    excludeEmptyContracts
) => {
    return (
        records.filter((item) => item.contract === contract).length > 0 ||
        absenceRecords.filter((item) => item.contract === contract).length > 0 ||
        attendanceRecords.filter((item) => item.contract === contract).length > 0 ||
        attendanceAbsenceRecords.filter((item) => item.contract === contract).length > 0 ||
        excludeEmptyContracts !== true
    )
}

const CorrectionsView = () => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    // eslint-disable-next-line
    const profile_id = useSelector((state) => state?.user?.profile_id || null)

    const canManageOnlyAssignedEmployees = useHasPermission(
        'attendance.c_can_manage_only_employees_as_responsible_person'
    )

    const excludeSelf = useHasPermission('attendance.c_can_not_manage_self_attendance')

    const [excludeWeekends, setExcludeWeekends] = useState(false)
    const [excludeEmptyContracts, setExcludeEmptyContracts] = useState(false)
    const [excludeHolidays, setExcludeHolidays] = useState(false)
    const [showWeekDuration, setShowWeekDuration] = useState(false)
    const [showActionEmptyCell, setShowActionEmptyCell] = useState(false)
    const [showBanks, setShowBanks] = useState(false)
    const [showTotals, setShowTotals] = useState(false)

    // eslint-disable-next-line
    const [modal, setModal] = useState({
        open: false,
        selectedDay: null,
        contract: null,
    })

    const [modalBank, setModalBank] = useState({
        open: false,
        contract: null,
        bankValue: null,
        source: null,
        action: null,
    })

    const [modalTransfer, setModalTransfer] = useState({ open: false, contract: null })

    const [planRecords, setPlanRecords] = useState([])
    const [attendanceRecords, setAttendanceRecords] = useState([])
    const [attendanceAbsenceRecords, setAttendanceAbsenceRecords] = useState([])
    const [absenceRecords, setAbsenceRecords] = useState([])
    const [records, setRecords] = useState([])
    const [shifts, setShifts] = useState([])
    const [contracts, setContracts] = useState([])
    const [loading, setLoading] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)
    const [loadingPlanner, setLoadingPlanner] = useState(false)
    const [durationConfig, setDurationConfig] = useState({})
    const [config, setConfig] = useState({
        date_from: moment().startOf('month').format('YYYY-MM-DD'),
        date_to: moment().endOf('month').format('YYYY-MM-DD'),
        view_mode: 'monthly', // view: Monthly, Weekly
        unit: '',
        position_group: '',
        contract_type: '',
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        weeks: [],
        dates: [],
    })

    const [scrollPosition, setScrollPosition] = useState(0)
    const tableContainerRef = useRef(null)

    const fetchShifts = async () => {
        setLoading(true)
        const request = await requests.get(API.ATTENDANCE_BASE + 'shifts/')
        if (request.status === 200) {
            const response = request.response
            let shiftList = []
            for (let i = 0; i < response.length; i++) {
                shiftList.push({
                    ...response[i],
                    time_from: convertTime(response[i].time_from, false),
                    time_to: convertTime(response[i].time_to, false),
                })
            }
            setShifts(shiftList)
        }
        setLoading(false)
    }

    const handleCalendarRender = (holidays, dates, weeks) => {
        setScrollPosition(0)
        const firstDay = moment(config.year + '-' + config.month + '-01').startOf('month')
        const lastDay = moment(config.year + '-' + config.month + '-01').endOf('month')
        // const dates = getDates(firstDay, lastDay)
        // const duplicate_weeks = dates.map(date => moment(date).isoWeek())
        // const unique_weeks = [...new Set(duplicate_weeks)]

        setConfig((prev) => ({
            ...prev,
            date_from: firstDay.format('YYYY-MM-DD'),
            date_to: lastDay.format('YYYY-MM-DD'),
            weeks: weeks,
            highlightedWeeks: weeks.filter((_, index) => index % 2 !== 0),
            dates: dates.map((date) => ({
                date: date,
                dayNumber: moment(date).format('DD'),
                week: moment(date).isoWeek(),
                shortname: moment(date).locale(setLocaleLanguage()).format('ddd'),
                isWeekend: [0, 6].includes(moment(date).day()),
                isSunday: moment(date).day() === 0,
                isSaturday: moment(date).day() === 6,
                holiday: holidays?.[date] || null,
            })),
        }))
    }

    useEffect(() => {
        fetchShifts()
        // eslint-disable-next-line
    }, [])

    /* !! TODO: Add preference (for now temporary disabled features) */
    const handleScroll = () => {
        setScrollPosition(tableContainerRef?.current?.scrollTop || 0)
    }

    useEffect(() => {
        if (tableContainerRef?.current) {
            tableContainerRef.current.addEventListener('scroll', handleScroll)
        }

        return () => {
            // eslint-disable-next-line
            if (tableContainerRef?.current) {
                // eslint-disable-next-line
                tableContainerRef.current.removeEventListener('scroll', handleScroll)
            }
        }

        // eslint-disable-next-line
    }, [tableContainerRef, records])

    useEffect(() => {
        if (!isMount && !loadingPlanner) {
            performCalculations(
                records,
                attendanceRecords,
                absenceRecords,
                attendanceAbsenceRecords,
                config.weeks,
                contracts
            )
        }

        // eslint-disable-next-line
    }, [records, attendanceRecords, contracts])

    const monthList = [
        { key: 1, value: '01', text: t('january') },
        { key: 2, value: '02', text: t('february') },
        { key: 3, value: '03', text: t('march') },
        { key: 4, value: '04', text: t('april') },
        { key: 5, value: '05', text: t('may') },
        { key: 6, value: '06', text: t('june') },
        { key: 7, value: '07', text: t('july') },
        { key: 8, value: '08', text: t('august') },
        { key: 9, value: '09', text: t('september') },
        { key: 10, value: '10', text: t('october') },
        { key: 11, value: '11', text: t('november') },
        { key: 12, value: '12', text: t('december') },
    ]

    const calculateTime = (start, end, as) => {
        let time = 0
        as = as || 'hours'
        let start_time = start ? tzDateTime(moment(start).format('YYYY-MM-DD HH:mm'), true) : null
        let end_time = end ? tzDateTime(moment(end).format('YYYY-MM-DD HH:mm'), true) : null

        if (start_time !== null && end_time !== null) {
            // Check if end_time is higher, add 1 day if true
            if (end_time.isBefore(start_time)) {
                end_time.add(1, 'day')
            }

            // Calculate the difference
            if (as === 'minutes') {
                time = moment.duration(end_time.diff(start_time)).asMinutes()
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours()
            }
        }

        return as === 'hours' ? time.toFixed(2) : time
    }

    // eslint-disable-next-line
    const handleBreakDeduction = (time_from, time_to, shift) => {
        let duration = calculateTime(time_from, time_to, 'minutes')
        if (shift?.pause_length !== '' && parseFloat(shift?.pause_length) > 0) {
            duration = duration - parseFloat(shift.pause_length)
        }

        if (shift?.second_pause_length !== '' && parseFloat(shift?.second_pause_length) > 0) {
            duration = duration - parseFloat(shift.second_pause_length)
        }

        return duration > 0 ? parseFloat(duration / 60) : 0
    }

    const performCalculations = (
        records,
        attendanceRecords,
        absenceRecords,
        attendanceAbsenceRecords,
        weeks,
        contracts
    ) => {
        let weekDurations = []
        // calculate week duration
        if (weeks.length > 0) {
            for (let i = 0; i < weeks.length; i++) {
                weekDurations.push({
                    id: weeks[i],
                    duration: calculateWeekDuration(weeks[i], attendanceRecords, true),
                    duration_real: calculateWeekDuration(weeks[i], records, true),
                })
            }
        }

        let contractDurations = []
        if (contracts.length > 0) {
            // calculate contract duration
            for (let i = 0; i < contracts.length; i++) {
                let weekContractDurations = []
                for (let y = 0; y < weeks.length; y++) {
                    weekContractDurations.push({
                        id: weeks[y],
                        duration: calculateWeekContractDuration(weeks[y], contracts[i].id, attendanceRecords, true),
                        duration_real: calculateWeekContractDuration(weeks[y], contracts[i].id, records, true),
                    })
                }
                contractDurations.push({
                    id: contracts[i].id,
                    weekDurations: weekContractDurations,
                    duration: calculateContractDuration(contracts[i], attendanceRecords, true),
                    duration_absences: calculateContractAbsences(contracts[i], attendanceAbsenceRecords, true),
                    duration_real: calculateContractDuration(contracts[i], records, true),
                    duration_absences_real: calculateContractAbsences(contracts[i], absenceRecords, true),
                    saturday_hours_reality: calculateDurationByType(contracts[i], records, true, 'saturday'),
                    saturday_hours_oficial: calculateDurationByType(contracts[i], attendanceRecords, true, 'saturday'),
                    sunday_hours_reality: calculateDurationByType(contracts[i], records, true, 'sunday', config.dates),
                    sunday_hours_oficial: calculateDurationByType(contracts[i], attendanceRecords, true, 'sunday'),
                    holiday_hours_reality: calculateDurationByType(contracts[i], records, true, 'holiday'),
                    holiday_hours_oficial: calculateDurationByType(contracts[i], attendanceRecords, true, 'holiday'),
                })
            }
        }

        setDurationConfig({
            weeks: weekDurations,
            contracts: contractDurations,
        })
    }

    const handleSubmit = async () => {
        setLoadingPlanner(true)
        setIsGenerated(false)
        setRecords([])
        const firstDay = moment(config.year + '-' + config.month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(config.year + '-' + config.month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')
        const dates = getDates(moment(firstDay), moment(lastDay))
        const duplicate_weeks = dates.map((date) => moment(date).isoWeek())
        const unique_weeks = [...new Set(duplicate_weeks)]

        let holidayList = []
        const recordsList = []
        const absenceRecordsList = []
        const planRecordsList = []
        const attendanceRecordsList = []
        const attendanceAbsenceRecordsList = []
        const request = await requests.get(API.COMMON + 'holidays/?country=SK&year=' + config.year)
        if (request.status === 200) holidayList = request.response

        // let positionGroupQuery = ''
        // if (config.contract_type) positionGroupQuery = '&position_group=' + config.contract_type

        let contractTypeQuery = ''
        if (config.contract_type) contractTypeQuery = '&type=' + config.contract_type

        // get me current day based on selected month and first day in month from previous month
        let currentDay = moment()
        if (currentDay.isAfter(moment(lastDay))) {
            currentDay = moment(lastDay)
        }
        const previousMonth = currentDay.clone().subtract(1, 'months')
        const contract_date_to = currentDay.format('YYYY-MM-DD')
        const contract_date_from = previousMonth.clone().startOf('month').format('YYYY-MM-DD')

        // fetch contracts
        const requestContracts = await requests.get(
            API.CONTRACTS +
                `?only_basic_info=true&valid_contract_based_on_date_from=${contract_date_from}&valid_contract_based_on_date_to=${contract_date_to}&payroll_date_from=${firstDay}&payroll_date_to=${lastDay}&lock_date_from=${firstDay}&lock_date_to=${lastDay}` +
                (canManageOnlyAssignedEmployees ? '&unit=' : '&descendant_units=') +
                config.unit +
                contractTypeQuery
        )
        if (requestContracts.status === 200) {
            const response = requestContracts.response?.results || []
            const tmpArray = []
            for (let i = 0; i < response.length; i++) {
                if (excludeSelf && profile_id && response[i].employee?.id === profile_id) {
                    // pass
                } else {
                    tmpArray.push({
                        id: response[i].id,
                        shortname: response[i].employee?.fullname || '--',
                        fullname: response[i].employee?.fullname || '--',
                        is_underage: response[i]?.is_underage || false,
                        profile_id: response[i].employee?.id || null,
                        profile_picture: response[i].employee?.profile_picture || null,
                        position: response[i].work_position.title,
                        employer: response[i].employer.name,
                        custom_prefix_and_id: response[i].employee?.custom_prefix_and_id || '',
                        fond: response[i].fond || '',
                        fond_per_unit: response[i].fond_per_unit || '',
                        fond_per_unit_display: response[i].fond_per_unit_display || '',
                        bank_saturday: response[i].bank_saturday || 0,
                        bank_sunday: response[i].bank_sunday || 0,
                        bank_overtime: response[i].bank_overtime || 0,
                        bank_holiday: response[i].bank_holiday || 0,
                        bank_night: response[i].bank_night || 0,
                        bank_hours: response[i].bank_hours || 0,
                        available_vacation_fond: response[i].available_vacation_fond || 0,
                        payroll: response[i].payroll,
                        attendance_lock: response[i].attendance_lock,
                    })
                }
            }

            if (tmpArray.length > 0) {
                // fetch records
                let dateRange = '&date_after=' + firstDay + '&date_before=' + lastDay
                let ignoreCorrectionPref = '&ignore_attendance_correction_preference=true'
                let contractsQuery = '&contracts='
                for (let i = 0; i < tmpArray.length; i++) {
                    if (tmpArray.length - 1 === i) {
                        contractsQuery += tmpArray[i].id
                    } else {
                        contractsQuery += tmpArray[i].id + ','
                    }
                }
                const requestDataset = await requests.get(
                    API.ATTENDANCE_BASE +
                        'dataset_corrections/?query={*}' +
                        dateRange +
                        ignoreCorrectionPref +
                        contractsQuery
                )

                if (requestDataset.status === 200) {
                    const response = requestDataset.response
                    // set original records
                    for (let i = 0; i < response.original_records.length; i++) {
                        recordsList.push({
                            id: response.original_records[i].id,
                            employee: response.original_records[i].employee?.id,
                            contract: response.original_records[i].contract?.id,
                            date: response.original_records[i].date,
                            start: response.original_records[i].start,
                            end: response.original_records[i].end,
                            duration: response.original_records[i]?.work_hours || 0,
                            night_work_hours: response.original_records[i]?.night_work_hours || 0,
                            duration_real: response.original_records[i]?.duration || 0,
                            closed: response.original_records[i].closed,
                            vacation: response.original_records[i].vacation,
                            vacation_hours: response.original_records[i].vacation_hours,
                            paragraph: response.original_records[i].paragraph,
                            absention: response.original_records[i].absention,
                            sick_day: response.original_records[i].sick_day,
                            care_for_a_family_member: response.original_records[i].care_for_a_family_member,
                            family_member_paragraph: response.original_records[i].family_member_paragraph,
                            absence_type: response.original_records[i].absence_type,
                            original_record: response.original_records[i].original_record,
                            correction: response.original_records[i].correction,
                            is_saturday: response.original_records[i].is_saturday,
                            is_sunday: response.original_records[i].is_sunday,
                            is_holiday: response.original_records[i].is_holiday,
                            require_manual_correction: response.original_records[i].require_manual_correction,
                            week: moment(response.original_records[i].date).isoWeek(),
                        })
                    }

                    // set original+corrected records
                    for (let i = 0; i < response.correction_records.length; i++) {
                        attendanceRecordsList.push({
                            id: response.correction_records[i].id,
                            employee: response.correction_records[i].employee?.id,
                            contract: response.correction_records[i].contract?.id,
                            date: response.correction_records[i].date,
                            start: response.correction_records[i].start,
                            end: response.correction_records[i].end,
                            night_work_hours: response.correction_records[i]?.night_work_hours || 0,
                            duration: response.correction_records[i]?.work_hours || 0,
                            duration_real: response.correction_records[i]?.duration || 0,
                            closed: response.correction_records[i].closed,
                            vacation: response.correction_records[i].vacation,
                            vacation_hours: response.correction_records[i].vacation_hours,
                            paragraph: response.correction_records[i].paragraph,
                            absention: response.correction_records[i].absention,
                            sick_day: response.correction_records[i].sick_day,
                            care_for_a_family_member: response.correction_records[i].care_for_a_family_member,
                            family_member_paragraph: response.correction_records[i].family_member_paragraph,
                            absence_type: response.correction_records[i].absence_type,
                            original_record: response.correction_records[i].original_record,
                            correction: response.correction_records[i].correction,
                            is_corrected: response.correction_records[i].is_corrected,
                            is_saturday: response.correction_records[i].is_saturday,
                            is_sunday: response.correction_records[i].is_sunday,
                            is_holiday: response.correction_records[i].is_holiday,
                            week: moment(response.correction_records[i].date).isoWeek(),
                        })
                    }

                    // set original absence records:
                    for (let i = 0; i < response.original_absence_records.length; i++) {
                        absenceRecordsList.push({
                            ...response.original_absence_records[i],
                            week: moment(response.original_absence_records[i].date).isoWeek(),
                        })
                    }

                    // set original+correction absence records
                    for (let i = 0; i < response.correction_absence_records.length; i++) {
                        attendanceAbsenceRecordsList.push({
                            ...response.correction_absence_records[i],
                            week: moment(response.correction_absence_records[i].date).isoWeek(),
                        })
                    }

                    // set planner records
                    for (let i = 0; i < response.planner_records.length; i++) {
                        let shift = response.planner_records[i].shift
                        const duration = handleBreakDeduction(
                            response.planner_records[i].datetime_from,
                            response.planner_records[i].datetime_to,
                            shift
                        )
                        planRecordsList.push({
                            id: response.planner_records[i].id,
                            is_deleted: false,
                            employee: response.planner_records[i].employee?.id,
                            contract: response.planner_records[i].contract?.id,
                            date: response.planner_records[i].date,
                            week: moment(response.planner_records[i].date).isoWeek(),
                            type: parseInt(response.planner_records[i].type),
                            type_display: response.planner_records[i].type_display,
                            shift: response.planner_records[i].shift?.id || null,
                            color: response.planner_records[i].shift?.color || '#000000',
                            time_from: response.planner_records[i].datetime_from
                                ? tzDateTime(response.planner_records[i].datetime_from).format('HH:mm')
                                : null,
                            time_to: response.planner_records[i].datetime_to
                                ? tzDateTime(response.planner_records[i].datetime_to).format('HH:mm')
                                : null,
                            duration: parseFloat(duration),
                            duration_display: duration.toString().replace('.00', ''),
                        })
                    }
                }

                setContracts(tmpArray)
                setRecords(recordsList) // original records
                setAbsenceRecords(absenceRecordsList) // original absence records
                setPlanRecords(planRecordsList)
                setAttendanceRecords(attendanceRecordsList) // correction+original
                setAttendanceAbsenceRecords(attendanceAbsenceRecordsList) // correction+original
                performCalculations(
                    recordsList,
                    attendanceRecordsList,
                    absenceRecordsList,
                    attendanceAbsenceRecordsList,
                    unique_weeks,
                    tmpArray
                )
            } else {
                setContracts(tmpArray)
            }
        }

        handleCalendarRender(holidayList, dates, unique_weeks)
        setLoadingPlanner(false)
        setIsGenerated(true)
    }

    const excludeDates = (dates) => {
        if (excludeWeekends) {
            dates = dates.filter((item) => !item.isWeekend)
        }

        if (excludeHolidays) {
            dates = dates.filter((item) => item.holiday === null)
        }

        return dates
    }

    const isLastDayOfWeek = (day) => {
        let datesOfWeek = config.dates.filter((item) => item.week === day.week)
        if (excludeWeekends) datesOfWeek = datesOfWeek.filter((item) => !item.isWeekend)
        if (excludeHolidays) datesOfWeek = datesOfWeek.filter((item) => item.holiday === null)
        let isLast = false

        if (datesOfWeek.length > 0) {
            isLast = datesOfWeek[datesOfWeek.length - 1]?.date === day.date
        }

        return isLast
    }

    const handleLock = async (contract) => {
        const firstDay = moment(config.year + '-' + config.month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(config.year + '-' + config.month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        const isUnlock = contract.attendance_lock

        const request = await requests.post(API.ATTENDANCE_BASE + `locks/${isUnlock ? '?unlock=true' : ''}`, {
            date_from: firstDay,
            date_to: lastDay,
            contracts: [contract.id],
        })

        if (request.status === 201 || request.status === 200) {
            setContracts((prev) =>
                prev.filter((item) => {
                    if (item.id === contract.id) {
                        item.attendance_lock = !contract.attendance_lock
                    }

                    return item
                })
            )

            toast({
                type: 'success',
                icon: 'check circle',
                title: !contract.attendance_lock === false ? t('records_where_locked') : t('records_where_unlocked'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    return (
        <CanView permissions={['attendance.c_can_create_corrections']} redirect>
            <Form>
                <Form.Group widths={6}>
                    <SuperField
                        as="yearpicker"
                        label={t('year')}
                        value={config.year}
                        width="2"
                        dateFormat="YYYY"
                        onChange={(e, { value }) => setConfig((prev) => ({ ...prev, year: value }))}
                    />
                    <SuperField
                        as="choice"
                        search
                        width="2"
                        label={t('month')}
                        clearable={false}
                        value={config.month}
                        customOptions={monthList}
                        onChange={(e, { value }) => setConfig((prev) => ({ ...prev, month: value }))}
                    />
                    <SuperField
                        as="choice-select"
                        search
                        required
                        label={t('department')}
                        clearable={false}
                        value={config.unit}
                        text="name"
                        endpoint={API.UNITS}
                        additionalFilters={
                            '?query={id, name}&only_basic_info=true&only_from_active_company=true' +
                            (canManageOnlyAssignedEmployees && profile_id ? '&responsible_persons=' + profile_id : '')
                        }
                        onChange={(e, { value }) => setConfig((prev) => ({ ...prev, unit: value }))}
                    />
                    {/* <SuperField
                        as="choice-select"
                        search
                        text="title"
                        label={t('contract_type')}
                        value={config.position_group}
                        endpoint={API.POSITIONS_GROUPS}
                        additionalFilters={'&query={id, title}&only_basic_info=true'}
                        onChange={(e, { value }) => setConfig((prev) => ({ ...prev, position_group: value }))}
                    /> */}
                    <SuperField
                        as="choice-select"
                        search
                        text="title"
                        label={t('contract_type')}
                        value={config.contract_type}
                        endpoint={API.CONTRACTS + 'types/'}
                        onChange={(e, { value }) =>
                            setConfig((prev) => ({
                                ...prev,
                                contract_type: value,
                            }))
                        }
                    />
                    <Form.Field>
                        <Button
                            primary
                            type="button"
                            onClick={() => handleSubmit()}
                            style={{ marginTop: '1.7rem' }}
                            disabled={config.unit === '' || config.month === '' || config.year === '' || loading}
                        >
                            {t('confirm')}
                        </Button>
                    </Form.Field>
                </Form.Group>
            </Form>
            <Divider />

            <Segment
                loading={loadingPlanner}
                style={{
                    padding: 0,
                    background: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    marginBottom: '10rem',
                    marginTop: '1rem',
                }}
            >
                {loadingPlanner && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                        {t('loading_compare_plan_vs_reality_view')}
                    </p>
                )}

                {!loadingPlanner && !isGenerated && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '1rem', fontWeight: 'bold' }}>
                        {t('select_options_to_generate_monthly_compare_view')}
                    </p>
                )}

                {!loadingPlanner && isGenerated && (
                    <Form style={{ fontSize: '0.7rem' }}>
                        <Grid style={{ padding: 0 }}>
                            <Grid.Row style={{ padding: 0 }}>
                                <Grid.Column style={{ padding: 0 }}>
                                    <Form.Group>
                                        <SuperField
                                            as="checkbox"
                                            style={{ marginTop: '0.5rem' }}
                                            label={t('exclude_weekends')}
                                            checked={excludeWeekends}
                                            onChange={() => setExcludeWeekends(!excludeWeekends)}
                                        />
                                        <SuperField
                                            as="checkbox"
                                            style={{ marginTop: '0.5rem' }}
                                            label={t('exclude_holidays')}
                                            checked={excludeHolidays}
                                            onChange={() => setExcludeHolidays(!excludeHolidays)}
                                        />
                                        <SuperField
                                            as="checkbox"
                                            style={{ marginTop: '0.5rem' }}
                                            label={t('exclude_empty_attendance')}
                                            checked={excludeEmptyContracts}
                                            onChange={() => setExcludeEmptyContracts(!excludeEmptyContracts)}
                                        />
                                        <SuperField
                                            as="checkbox"
                                            style={{ marginTop: '0.5rem' }}
                                            label={t('show_banks')}
                                            checked={showBanks}
                                            onChange={() => setShowBanks(!showBanks)}
                                        />
                                        <SuperField
                                            as="checkbox"
                                            style={{ marginTop: '0.5rem' }}
                                            label={t('show_totals_special_days')}
                                            checked={showTotals}
                                            onChange={() => setShowTotals(!showTotals)}
                                        />
                                        <SuperField
                                            as="checkbox"
                                            style={{ marginTop: '0.5rem' }}
                                            label={t('show_weekly_hours')}
                                            checked={showWeekDuration}
                                            onChange={() => setShowWeekDuration(!showWeekDuration)}
                                        />
                                        <SuperField
                                            as="checkbox"
                                            style={{ marginTop: '0.5rem' }}
                                            label={t('show_actions_for_empty_days')}
                                            checked={showActionEmptyCell}
                                            onChange={() => setShowActionEmptyCell(!showActionEmptyCell)}
                                        />
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}

                <div
                    className="attendance-planner-table-container"
                    ref={tableContainerRef}
                    style={{ overflow: loadingPlanner ? 'hidden' : 'auto', height: `${60}vh` }}
                >
                    {!loadingPlanner && isGenerated && (
                        <Table
                            celled
                            singleLine
                            unstackable
                            compact
                            className="attendance-planner-table"
                            style={{ width: '100%', fontSize: '0.9rem' }}
                        >
                            <Table.Header
                                style={{
                                    transform: `translateY(${scrollPosition}px)`,
                                    transition: 'transform 0.2s ease-in-out',
                                    position: 'relative',
                                    zIndex: 4,
                                }}
                            >
                                <Table.Row className="header-row" style={{ textAlign: 'center' }}>
                                    <Table.HeaderCell
                                        verticalAlign="bottom"
                                        className="sticky-column-left"
                                        rowSpan="2"
                                        style={{
                                            textAlign: 'left',
                                            borderRight: '1px solid #e2e3e5',
                                            backgroung: 'transparent',
                                        }}
                                    >
                                        {t('employee')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        verticalAlign="bottom"
                                        rowSpan="2"
                                        style={{
                                            textAlign: 'left',
                                            borderRight: '1px solid #e2e3e5',
                                            backgroung: 'transparent',
                                        }}
                                    >
                                        {t('fond')}
                                    </Table.HeaderCell>
                                    {config.weeks.map((week) => (
                                        <Table.HeaderCell
                                            key={week}
                                            colSpan={
                                                (showWeekDuration ? 1 : 0) +
                                                    excludeDates(config.dates).filter((day) => day.week === week)
                                                        ?.length || 1
                                            }
                                            style={{
                                                paddingTop: '0.2rem',
                                                paddingBottom: '0.2rem',
                                                background: config.highlightedWeeks.includes(week)
                                                    ? 'var(--light)'
                                                    : 'var(--white)',
                                                // transform: `translateY(${scrollPosition}px)`,
                                                // transition: 'transform 0.2s ease-in-out'
                                            }}
                                        >
                                            {t('week')} {week} <br />
                                            <small style={{ opacity: '0.7' }}>
                                                {' '}
                                                {
                                                    durationConfig?.weeks?.find((item) => item.id === week)
                                                        ?.duration_real
                                                }{' '}
                                                <span style={{ textTransform: 'lowercase' }}>
                                                    {t('hours_shortcut')}.
                                                </span>{' '}
                                                /{' '}
                                                {durationConfig?.weeks?.find((item) => item.id === week)?.duration || 0}{' '}
                                                {t('hours_shortcut')}.
                                            </small>
                                        </Table.HeaderCell>
                                    ))}
                                    <Table.HeaderCell
                                        className="sticky-column-right"
                                        rowSpan="2"
                                        style={{
                                            textAlign: 'center',
                                            borderRight: '1px solid #e2e3e5',
                                            backgroung: 'transparent',
                                        }}
                                    >
                                        <Table fixed size="tiny">
                                            <Table.Row>
                                                <Table.Cell
                                                    style={{
                                                        width: '150px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {t('total_hours')} <br />
                                                </Table.Cell>
                                                {showTotals && (
                                                    <>
                                                        <Table.Cell
                                                            rowSpan="2"
                                                            style={{
                                                                width: '100px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {t('hours_saturday_short')} <br />
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            rowSpan="2"
                                                            style={{
                                                                width: '100px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {t('hours_sunday_short')} <br />
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            rowSpan="2"
                                                            style={{
                                                                width: '100px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {t('hours_holiday_short')} <br />
                                                        </Table.Cell>
                                                    </>
                                                )}
                                                {showBanks && (
                                                    <Table.Cell
                                                        colSpan="6"
                                                        style={{ width: '100%', textAlign: 'center' }}
                                                    >
                                                        {t('banks')}
                                                    </Table.Cell>
                                                )}
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell
                                                    style={{
                                                        width: '80px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <span style={{ fontWeight: 'normal' }}>
                                                        {t('reality')} / {t('correction')}
                                                    </span>
                                                </Table.Cell>
                                                {showBanks && (
                                                    <>
                                                        <Table.Cell
                                                            style={{
                                                                width: '80px',
                                                                textAlign: 'center',
                                                                fontWeight: 'normal',
                                                            }}
                                                        >
                                                            {t('bank_hours')}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            style={{
                                                                width: '80px',
                                                                textAlign: 'center',
                                                                fontWeight: 'normal',
                                                            }}
                                                        >
                                                            {t('overtime')}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            style={{
                                                                width: '80px',
                                                                textAlign: 'center',
                                                                fontWeight: 'normal',
                                                            }}
                                                        >
                                                            {t('saturday')}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            style={{
                                                                width: '80px',
                                                                textAlign: 'center',
                                                                fontWeight: 'normal',
                                                            }}
                                                        >
                                                            {t('sunday')}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            style={{
                                                                width: '80px',
                                                                textAlign: 'center',
                                                                fontWeight: 'normal',
                                                            }}
                                                        >
                                                            {t('holiday')}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            style={{
                                                                width: '80px',
                                                                textAlign: 'center',
                                                                fontWeight: 'normal',
                                                            }}
                                                        >
                                                            {t('night')}
                                                        </Table.Cell>
                                                    </>
                                                )}
                                            </Table.Row>
                                        </Table>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row className="header-row" style={{ textAlign: 'center' }}>
                                    {excludeDates(config.dates).map((day, index) => (
                                        <Fragment key={index}>
                                            <Table.HeaderCell
                                                style={{
                                                    background: config.highlightedWeeks.includes(day.week)
                                                        ? 'var(--light)'
                                                        : 'var(--white)',
                                                    color:
                                                        day.holiday !== null
                                                            ? 'orange'
                                                            : day.isWeekend
                                                            ? 'var(--danger)'
                                                            : 'var(--dark)',
                                                    paddingTop: '0.2rem',
                                                    paddingBottom: '0.2rem',
                                                    paddingRight: '0.2rem',
                                                    paddingLeft: '0.2rem',
                                                    // transform: `translateY(${scrollPosition}px)`,
                                                    // transition: 'transform 0.2s ease-in-out'
                                                }}
                                            >
                                                {day.holiday === null ? (
                                                    day.isWeekend ? (
                                                        <Popup
                                                            position="top center"
                                                            trigger={
                                                                <div>
                                                                    {day.dayNumber}. <br />
                                                                    <small
                                                                        style={{
                                                                            opacity: '0.8',
                                                                            textTransform: 'capitalize',
                                                                        }}
                                                                    >
                                                                        {day.shortname}.
                                                                    </small>
                                                                </div>
                                                            }
                                                            content={
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <span style={{ textTransform: 'capitalize' }}>
                                                                        {moment(day.date)
                                                                            .locale(setLocaleLanguage())
                                                                            .format('dddd')}
                                                                    </span>{' '}
                                                                    <br />
                                                                    <small>
                                                                        <strong>{t('weekend')}</strong>
                                                                    </small>
                                                                </div>
                                                            }
                                                        />
                                                    ) : (
                                                        <div>
                                                            {day.dayNumber}. <br />
                                                            <small
                                                                style={{ opacity: '0.8', textTransform: 'capitalize' }}
                                                            >
                                                                {day.shortname}.
                                                            </small>
                                                        </div>
                                                    )
                                                ) : (
                                                    <Popup
                                                        position="top center"
                                                        trigger={
                                                            <div>
                                                                {day.dayNumber}. <br />
                                                                <small
                                                                    style={{
                                                                        opacity: '0.8',
                                                                        textTransform: 'capitalize',
                                                                    }}
                                                                >
                                                                    {day.shortname}.
                                                                </small>{' '}
                                                                <br />
                                                            </div>
                                                        }
                                                        content={
                                                            <div style={{ textAlign: 'center' }}>
                                                                {day.holiday} <br />
                                                                <small>
                                                                    <strong>{t('holiday')}</strong>
                                                                </small>
                                                            </div>
                                                        }
                                                    />
                                                )}
                                            </Table.HeaderCell>
                                            {showWeekDuration && isLastDayOfWeek(day) && (
                                                <Table.HeaderCell
                                                    style={{
                                                        background: config.highlightedWeeks.includes(day.week)
                                                            ? 'var(--light)'
                                                            : 'var(--white)',
                                                        paddingTop: '0.2rem',
                                                        paddingBottom: '0.2rem',
                                                        paddingRight: '0.2rem',
                                                        paddingLeft: '0.2rem',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: '0.8rem',
                                                        }}
                                                    >
                                                        {t('weekly_hours')} <br />
                                                        <span style={{ fontWeight: 'normal' }}>
                                                            {t('reality')} / {t('correction')}
                                                        </span>
                                                    </span>
                                                </Table.HeaderCell>
                                            )}
                                        </Fragment>
                                    ))}
                                </Table.Row>
                            </Table.Header>

                            {/* Table Content */}
                            <Table.Body>
                                {contracts.length === 0 && (
                                    <Table.Row>
                                        <Table.Cell
                                            colSpan={
                                                (showWeekDuration ? config.weeks.length : 0) +
                                                2 +
                                                excludeDates(config.dates).length
                                            }
                                            style={{ textAlign: 'center', fontWeight: 'bold', padding: '2rem' }}
                                        >
                                            {t('no_data')}
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                {contracts.map((contract, index) => (
                                    <Fragment key={contract.id}>
                                        {hasRecords(
                                            contract.id,
                                            records,
                                            attendanceRecords,
                                            absenceRecords,
                                            attendanceAbsenceRecords,
                                            excludeEmptyContracts
                                        ) && (
                                            <Table.Row key={contract.id}>
                                                <Table.Cell
                                                    className="sticky-column-left"
                                                    style={{ padding: '0.2rem 1rem' }}
                                                >
                                                    <div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'top',
                                                            }}
                                                        >
                                                            <div>
                                                                {contract.is_underage ? (
                                                                    <Popup
                                                                        style={{ zIndex: 1899 }}
                                                                        hoverable
                                                                        position="right center"
                                                                        trigger={
                                                                            <strong
                                                                                style={{
                                                                                    color: 'var(--danger)',
                                                                                }}
                                                                            >
                                                                                {contract.fullname}
                                                                            </strong>
                                                                        }
                                                                        content={t('underaged')}
                                                                    />
                                                                ) : (
                                                                    <strong
                                                                        style={{
                                                                            color: "var('--dark')",
                                                                        }}
                                                                    >
                                                                        {contract.fullname}
                                                                    </strong>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <Popup
                                                                    style={{ zIndex: 1899 }}
                                                                    hoverable
                                                                    position={
                                                                        contracts[contracts.length - 1]?.id ===
                                                                        contract?.id
                                                                            ? 'top center'
                                                                            : 'right center'
                                                                    }
                                                                    trigger={
                                                                        <Icon
                                                                            name="wallet-outline"
                                                                            style={{
                                                                                fontSize: '1.3rem',
                                                                                marginLeft: '1rem',
                                                                                position: 'relative',
                                                                                top: '0rem',
                                                                            }}
                                                                        />
                                                                    }
                                                                    content={
                                                                        <div>
                                                                            <div
                                                                                onClick={() =>
                                                                                    setModalTransfer({
                                                                                        open: true,
                                                                                        contract: contract,
                                                                                    })
                                                                                }
                                                                                className="ref-link"
                                                                                style={{
                                                                                    fontWeight: 'bold',
                                                                                    textAlign: 'center',
                                                                                    marginBottom: '0.5rem',
                                                                                    color: 'var(--primary)',
                                                                                }}
                                                                            >
                                                                                {t('perform_bank_transfer')}
                                                                            </div>
                                                                            <Table size="small" compact>
                                                                                <Table.Row>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {t('bank_hours')}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ textAlign: 'left' }}
                                                                                    >
                                                                                        {contract.bank_hours || 0}{' '}
                                                                                        {t(
                                                                                            'hours_shortcut'
                                                                                        ).toLocaleLowerCase()}
                                                                                        .
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        <Icon
                                                                                            title={t('history')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_hours',
                                                                                                    action: 'history',
                                                                                                    size: 'large',
                                                                                                })
                                                                                            }
                                                                                            name="albums-outline"
                                                                                            style={{
                                                                                                color: 'var(--primary)',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                        <Icon
                                                                                            title={t('settings')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_hours',
                                                                                                    action: 'settings',
                                                                                                    size: 'tiny',
                                                                                                })
                                                                                            }
                                                                                            name="settings-outline"
                                                                                            style={{
                                                                                                marginLeft: '1rem',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {t('bank_overtime')}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ textAlign: 'left' }}
                                                                                    >
                                                                                        {contract.bank_overtime}{' '}
                                                                                        {t(
                                                                                            'hours_shortcut'
                                                                                        ).toLocaleLowerCase()}
                                                                                        .
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        <Icon
                                                                                            title={t('history')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_overtime',
                                                                                                    action: 'history',
                                                                                                    size: 'large',
                                                                                                })
                                                                                            }
                                                                                            name="albums-outline"
                                                                                            style={{
                                                                                                color: 'var(--primary)',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                        <Icon
                                                                                            title={t('settings')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_overtime',
                                                                                                    action: 'settings',
                                                                                                    size: 'tiny',
                                                                                                })
                                                                                            }
                                                                                            name="settings-outline"
                                                                                            style={{
                                                                                                marginLeft: '1rem',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {t('bank_saturday')}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ textAlign: 'left' }}
                                                                                    >
                                                                                        {contract.bank_saturday}{' '}
                                                                                        {t(
                                                                                            'hours_shortcut'
                                                                                        ).toLocaleLowerCase()}
                                                                                        .
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        <Icon
                                                                                            title={t('history')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_saturday',
                                                                                                    action: 'history',
                                                                                                    size: 'large',
                                                                                                })
                                                                                            }
                                                                                            name="albums-outline"
                                                                                            style={{
                                                                                                color: 'var(--primary)',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                        <Icon
                                                                                            title={t('settings')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_saturday',
                                                                                                    action: 'settings',
                                                                                                    size: 'tiny',
                                                                                                })
                                                                                            }
                                                                                            name="settings-outline"
                                                                                            style={{
                                                                                                marginLeft: '1rem',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {t('bank_sunday')}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ textAlign: 'left' }}
                                                                                    >
                                                                                        {contract.bank_sunday}{' '}
                                                                                        {t(
                                                                                            'hours_shortcut'
                                                                                        ).toLocaleLowerCase()}
                                                                                        .
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        <Icon
                                                                                            title={t('history')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_sunday',
                                                                                                    action: 'history',
                                                                                                    size: 'large',
                                                                                                })
                                                                                            }
                                                                                            name="albums-outline"
                                                                                            style={{
                                                                                                color: 'var(--primary)',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                        <Icon
                                                                                            title={t('settings')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_sunday',
                                                                                                    action: 'settings',
                                                                                                    size: 'tiny',
                                                                                                })
                                                                                            }
                                                                                            name="settings-outline"
                                                                                            style={{
                                                                                                marginLeft: '1rem',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {t('bank_holiday')}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ textAlign: 'left' }}
                                                                                    >
                                                                                        {contract.bank_holiday}{' '}
                                                                                        {t(
                                                                                            'hours_shortcut'
                                                                                        ).toLocaleLowerCase()}
                                                                                        .
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        <Icon
                                                                                            title={t('history')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_holiday',
                                                                                                    action: 'history',
                                                                                                    size: 'large',
                                                                                                })
                                                                                            }
                                                                                            name="albums-outline"
                                                                                            style={{
                                                                                                color: 'var(--primary)',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                        <Icon
                                                                                            title={t('settings')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_holiday',
                                                                                                    action: 'settings',
                                                                                                    size: 'tiny',
                                                                                                })
                                                                                            }
                                                                                            name="settings-outline"
                                                                                            style={{
                                                                                                marginLeft: '1rem',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {t('bank_night')}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ textAlign: 'left' }}
                                                                                    >
                                                                                        {contract.bank_night || 0}{' '}
                                                                                        {t(
                                                                                            'hours_shortcut'
                                                                                        ).toLocaleLowerCase()}
                                                                                        .
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        <Icon
                                                                                            title={t('history')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_night',
                                                                                                    action: 'history',
                                                                                                    size: 'large',
                                                                                                })
                                                                                            }
                                                                                            name="albums-outline"
                                                                                            style={{
                                                                                                color: 'var(--primary)',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                        <Icon
                                                                                            title={t('settings')}
                                                                                            onClick={() =>
                                                                                                setModalBank({
                                                                                                    open: true,
                                                                                                    contract: contract,
                                                                                                    source: 'bank_night',
                                                                                                    action: 'settings',
                                                                                                    size: 'tiny',
                                                                                                })
                                                                                            }
                                                                                            name="settings-outline"
                                                                                            style={{
                                                                                                marginLeft: '1rem',
                                                                                                fontSize: '1.2rem',
                                                                                                position: 'relative',
                                                                                                top: '0.2rem',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        />
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                                {/* <Table.Row>
                                                                                    <Table.Cell
                                                                                        singleLine
                                                                                        style={{ fontWeight: 'bold' }}
                                                                                    >
                                                                                        {t('vacation_fond')}
                                                                                    </Table.Cell>
                                                                                    <Table.Cell
                                                                                        colSpan="2"
                                                                                        singleLine
                                                                                        style={{ textAlign: 'left' }}
                                                                                    >
                                                                                        {contract.available_vacation_fond ||
                                                                                            0}{' '}
                                                                                        {t('days').toLocaleLowerCase()}.
                                                                                    </Table.Cell>
                                                                                </Table.Row> */}
                                                                            </Table>
                                                                        </div>
                                                                    }
                                                                />
                                                                <CanView
                                                                    permissions={[
                                                                        'attendance.c_lock_attendance_records',
                                                                    ]}
                                                                >
                                                                    <Popup
                                                                        style={{ zIndex: 1899 }}
                                                                        hoverable
                                                                        position="right center"
                                                                        trigger={
                                                                            contract.attendance_lock ? (
                                                                                <Icon
                                                                                    onClick={() => handleLock(contract)}
                                                                                    name="lock-closed"
                                                                                    style={{
                                                                                        fontSize: '1.3rem',
                                                                                        marginLeft: '1rem',
                                                                                        position: 'relative',
                                                                                        top: '0rem',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <Icon
                                                                                    onClick={() => handleLock(contract)}
                                                                                    name="lock-open-outline"
                                                                                    style={{
                                                                                        fontSize: '1.3rem',
                                                                                        marginLeft: '1rem',
                                                                                        position: 'relative',
                                                                                        top: '0rem',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        content={
                                                                            contract.attendance_lock
                                                                                ? t('unlock_original_attendance')
                                                                                : t('lock_original_attendance')
                                                                        }
                                                                    />
                                                                </CanView>
                                                                <CanView
                                                                    permissions={[
                                                                        'payrolls.c_manage_all_payrolls',
                                                                        'payrolls.c_manage_user_payrolls',
                                                                    ]}
                                                                >
                                                                    <SuperDuperModal
                                                                        size="small"
                                                                        centered={false}
                                                                        trigger={
                                                                            contract?.payroll?.length > 0 ? (
                                                                                <Icon
                                                                                    title={t('view_payroll_record')}
                                                                                    name="shield-checkmark-outline"
                                                                                    style={{
                                                                                        fontSize: '1.3rem',
                                                                                        marginLeft: '1rem',
                                                                                        position: 'relative',
                                                                                        top: '0rem',
                                                                                        color: 'var(--blue)',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <Icon
                                                                                    title={t('add_payroll_record')}
                                                                                    name="add-circle-outline"
                                                                                    style={{
                                                                                        fontSize: '1.3rem',
                                                                                        marginLeft: '1rem',
                                                                                        position: 'relative',
                                                                                        top: '0rem',
                                                                                        color: 'var(--primary)',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        content={
                                                                            contract?.payroll?.length > 0 ? (
                                                                                <PayrollDetails
                                                                                    payrollID={contract?.payroll?.[0]}
                                                                                    contractID={contract?.id}
                                                                                    dateFrom={config?.date_from}
                                                                                    dateTo={config?.date_to}
                                                                                    setContracts={setContracts}
                                                                                />
                                                                            ) : (
                                                                                <PayrollDetails
                                                                                    contractID={contract?.id}
                                                                                    dateFrom={config?.date_from}
                                                                                    dateTo={config?.date_to}
                                                                                    setContracts={setContracts}
                                                                                />
                                                                            )
                                                                        }
                                                                    />
                                                                </CanView>
                                                            </div>
                                                        </div>
                                                        <small style={{ opacity: '0.8' }}>
                                                            <ShortenText
                                                                textLength={30}
                                                                text={
                                                                    (contract.custom_prefix_and_id !== ''
                                                                        ? contract.custom_prefix_and_id + ' · '
                                                                        : '') +
                                                                    contract.employer +
                                                                    ' (' +
                                                                    contract.position +
                                                                    ')'
                                                                }
                                                            />
                                                        </small>
                                                    </div>
                                                </Table.Cell>

                                                <Table.Cell
                                                    style={{ textAlign: 'left', backgroundColor: '#f8f8f8' }}
                                                    verticalAlign="middle"
                                                >
                                                    {contract.fond}{' '}
                                                    <span style={{ textTransform: 'lowercase' }}>
                                                        {' '}
                                                        {t('hours_shortcut')}.
                                                    </span>
                                                    <br />
                                                    <small>{contract.fond_per_unit_display}</small>
                                                </Table.Cell>

                                                {excludeDates(config.dates).map((day, index) => (
                                                    <Fragment key={index}>
                                                        <CellEvent
                                                            day={day}
                                                            key={index}
                                                            index={index}
                                                            shifts={shifts}
                                                            config={config}
                                                            records={records}
                                                            absenceRecords={absenceRecords}
                                                            planRecords={planRecords}
                                                            attendanceRecords={attendanceRecords}
                                                            attendanceAbsenceRecords={attendanceAbsenceRecords}
                                                            setAttendanceRecords={setAttendanceRecords}
                                                            setAttendanceAbsenceRecords={setAttendanceAbsenceRecords}
                                                            setContracts={setContracts}
                                                            showActionEmptyCell={showActionEmptyCell}
                                                            setModal={setModal}
                                                            contract={contract}
                                                            setRecords={setRecords}
                                                        />
                                                        {showWeekDuration && isLastDayOfWeek(day) && (
                                                            <Table.Cell
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'center',
                                                                    background: !config.highlightedWeeks.includes(
                                                                        day.week
                                                                    )
                                                                        ? 'transparent'
                                                                        : 'var(--light)',
                                                                }}
                                                            >
                                                                {durationConfig?.contracts
                                                                    ?.find((item) => item.id === contract.id)
                                                                    ?.weekDurations?.find(
                                                                        (item) => item.id === day.week
                                                                    )?.duration_real || 0}{' '}
                                                                /{' '}
                                                                {durationConfig?.contracts
                                                                    ?.find((item) => item.id === contract.id)
                                                                    ?.weekDurations?.find(
                                                                        (item) => item.id === day.week
                                                                    )?.duration || 0}{' '}
                                                            </Table.Cell>
                                                        )}
                                                    </Fragment>
                                                ))}

                                                <Table.Cell
                                                    className="sticky-column-right"
                                                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                                                    verticalAlign="middle"
                                                >
                                                    <Table size="tiny" fixed>
                                                        <Table.Row>
                                                            <Popup
                                                                hoverable
                                                                position="left center"
                                                                trigger={
                                                                    <Table.Cell
                                                                        style={{
                                                                            width: '150px',
                                                                            textAlign: 'center',
                                                                            background: 'var(--light)',
                                                                        }}
                                                                    >
                                                                        {parseFloat(
                                                                            parseFloat(
                                                                                durationConfig?.contracts?.find(
                                                                                    (item) => item.id === contract.id
                                                                                )?.duration_real || 0
                                                                            ) +
                                                                                parseFloat(
                                                                                    durationConfig?.contracts?.find(
                                                                                        (item) =>
                                                                                            item.id === contract.id
                                                                                    )?.duration_absences_real || 0
                                                                                )
                                                                        )}{' '}
                                                                        /{' '}
                                                                        {parseFloat(
                                                                            parseFloat(
                                                                                durationConfig?.contracts?.find(
                                                                                    (item) => item.id === contract.id
                                                                                )?.duration || 0
                                                                            ) +
                                                                                parseFloat(
                                                                                    durationConfig?.contracts?.find(
                                                                                        (item) =>
                                                                                            item.id === contract.id
                                                                                    )?.duration_absences || 0
                                                                                )
                                                                        )}{' '}
                                                                    </Table.Cell>
                                                                }
                                                                content={
                                                                    <Table size="small" singleLine>
                                                                        <Table.Header>
                                                                            <Table.Row>
                                                                                <Table.HeaderCell></Table.HeaderCell>
                                                                                <Table.HeaderCell>
                                                                                    {t('reality')}
                                                                                </Table.HeaderCell>
                                                                                <Table.HeaderCell>
                                                                                    {t('correction')}
                                                                                </Table.HeaderCell>
                                                                            </Table.Row>
                                                                        </Table.Header>
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell
                                                                                    style={{ fontWeight: 'bold' }}
                                                                                >
                                                                                    {t('working_hours')}
                                                                                </Table.Cell>
                                                                                <Table.Cell>
                                                                                    {durationConfig?.contracts?.find(
                                                                                        (item) =>
                                                                                            item.id === contract.id
                                                                                    )?.duration_real || 0}
                                                                                </Table.Cell>
                                                                                <Table.Cell>
                                                                                    {durationConfig?.contracts?.find(
                                                                                        (item) =>
                                                                                            item.id === contract.id
                                                                                    )?.duration || 0}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell
                                                                                    style={{ fontWeight: 'bold' }}
                                                                                >
                                                                                    {t('absence_hours')}
                                                                                </Table.Cell>
                                                                                <Table.Cell>
                                                                                    {durationConfig?.contracts?.find(
                                                                                        (item) =>
                                                                                            item.id === contract.id
                                                                                    )?.duration_absences_real || 0}
                                                                                </Table.Cell>
                                                                                <Table.Cell>
                                                                                    {durationConfig?.contracts?.find(
                                                                                        (item) =>
                                                                                            item.id === contract.id
                                                                                    )?.duration_absences || 0}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        </Table.Body>
                                                                    </Table>
                                                                }
                                                            />
                                                            {showTotals && (
                                                                <>
                                                                    <Table.Cell
                                                                        style={{
                                                                            width: '100px',
                                                                            textAlign: 'center',
                                                                            background: 'var(--light)',
                                                                        }}
                                                                    >
                                                                        {durationConfig?.contracts?.find(
                                                                            (item) => item.id === contract.id
                                                                        )?.saturday_hours_reality || 0}{' '}
                                                                        /{' '}
                                                                        {durationConfig?.contracts?.find(
                                                                            (item) => item.id === contract.id
                                                                        )?.saturday_hours_oficial || 0}{' '}
                                                                    </Table.Cell>

                                                                    <Table.Cell
                                                                        style={{
                                                                            width: '100px',
                                                                            textAlign: 'center',
                                                                            background: 'var(--light)',
                                                                        }}
                                                                    >
                                                                        {durationConfig?.contracts?.find(
                                                                            (item) => item.id === contract.id
                                                                        )?.sunday_hours_reality || 0}{' '}
                                                                        /{' '}
                                                                        {durationConfig?.contracts?.find(
                                                                            (item) => item.id === contract.id
                                                                        )?.sunday_hours_oficial || 0}{' '}
                                                                    </Table.Cell>

                                                                    <Table.Cell
                                                                        style={{
                                                                            width: '100px',
                                                                            textAlign: 'center',
                                                                            background: 'var(--light)',
                                                                        }}
                                                                    >
                                                                        {durationConfig?.contracts?.find(
                                                                            (item) => item.id === contract.id
                                                                        )?.holiday_hours_reality || 0}{' '}
                                                                        /{' '}
                                                                        {durationConfig?.contracts?.find(
                                                                            (item) => item.id === contract.id
                                                                        )?.holiday_hours_oficial || 0}{' '}
                                                                    </Table.Cell>
                                                                </>
                                                            )}
                                                            {showBanks && (
                                                                <>
                                                                    <Table.Cell
                                                                        style={{ width: '80px', textAlign: 'center' }}
                                                                    >
                                                                        {contract.bank_hours || 0}
                                                                    </Table.Cell>
                                                                    <Table.Cell
                                                                        style={{ width: '80px', textAlign: 'center' }}
                                                                    >
                                                                        {contract.bank_overtime || 0}
                                                                    </Table.Cell>

                                                                    <Table.Cell
                                                                        style={{ width: '80px', textAlign: 'center' }}
                                                                    >
                                                                        {contract.bank_saturday || 0}
                                                                    </Table.Cell>
                                                                    <Table.Cell
                                                                        style={{ width: '80px', textAlign: 'center' }}
                                                                    >
                                                                        {contract.bank_sunday || 0}
                                                                    </Table.Cell>
                                                                    <Table.Cell
                                                                        style={{ width: '80px', textAlign: 'center' }}
                                                                    >
                                                                        {contract.bank_holiday || 0}
                                                                    </Table.Cell>
                                                                    <Table.Cell
                                                                        style={{ width: '80px', textAlign: 'center' }}
                                                                    >
                                                                        {contract.bank_night || 0}
                                                                    </Table.Cell>
                                                                </>
                                                            )}
                                                        </Table.Row>
                                                    </Table>
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Fragment>
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </div>
            </Segment>

            <Modal
                closeIcon
                size={modalBank.size || 'tiny'}
                centered={false}
                open={modalBank.open}
                onClose={() =>
                    setModalBank((prev) => ({
                        ...prev,
                        open: false,
                        contract: null,
                        source: null,
                        bankValue: null,
                        action: null,
                    }))
                }
            >
                <Modal.Header>
                    {t(modalBank.action)} - {t(modalBank.source)} ({modalBank?.contract?.fullname})
                </Modal.Header>
                <Modal.Content>
                    <BankModalView
                        config={config}
                        modalBank={modalBank}
                        contract={modalBank.contract}
                        source={modalBank.source}
                        setContracts={setContracts}
                        bankValue={modalBank.bankValue}
                        onClose={() =>
                            setModalBank((prev) => ({
                                ...prev,
                                open: false,
                                contract: null,
                                source: null,
                                bankValue: null,
                                action: null,
                            }))
                        }
                    />
                </Modal.Content>
            </Modal>

            <Modal
                closeIcon
                size={'small'}
                centered={false}
                open={modalTransfer.open}
                onClose={() =>
                    setModalTransfer((prev) => ({
                        ...prev,
                        open: false,
                        contract: null,
                    }))
                }
            >
                <Modal.Header>
                    {t('bank_transfer')} - {modalTransfer?.contract?.fullname}
                </Modal.Header>
                <Modal.Content>
                    <BankTransfer
                        config={config}
                        contract={modalTransfer.contract}
                        setContracts={setContracts}
                        onClose={() =>
                            setModalTransfer((prev) => ({
                                ...prev,
                                open: false,
                                contract: null,
                            }))
                        }
                    />
                </Modal.Content>
            </Modal>
        </CanView>
    )
}

export default CorrectionsView
