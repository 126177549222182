import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Divider, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const AgreementForm = ({ onClose, profile, onConfirm, setData, setTotal }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [radioView, setRadioView] = useState(1)
    const [form, setForm] = useState({
        profile: profile || '',
        gdpr: '',
    })

    const [sendEmail, setSendEmail] = useState(true)

    const handleSubmit = async () => {
        setIsProcessing(true)
        if (sendEmail) {
            const request = await requests.post(API.GDPRS_PUBLIC + 'agreement/send/', {
                ...form,
                status: 1,
            })
            if (request.status === 201) {
                onConfirm(request.response, setData, setTotal)
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('email_request_sended'),
                    animation: 'bounce',
                    time: 3000,
                })
                onClose()
            }
        } else {
            const request = await requests.post(API.API_URL + '/common/gdpr_agreements_manual/', {
                ...form,
                status: 2,
            })
            if (request.status === 201) {
                onConfirm(request.response, setData, setTotal)
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {profile === undefined && (
                <>
                    <Form.Group>
                        <SuperField
                            as="radio"
                            label={t('candidates')}
                            checked={radioView === 1}
                            onChange={() => setRadioView(1)}
                        />
                        <SuperField
                            as="radio"
                            label={t('employees')}
                            checked={radioView === 2}
                            onChange={() => setRadioView(2)}
                        />
                    </Form.Group>
                    {radioView === 1 ? (
                        <SuperField
                            as="choice-select"
                            key={radioView}
                            required
                            search
                            endpoint={API.CANDIDATES}
                            additionalFilters={`&only_basic_info=true`}
                            text="fullname_with_titles"
                            label={t('candidates')}
                            value={form.person}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, profile: value }))}
                        />
                    ) : (
                        <SuperField
                            as="choice-select"
                            search
                            placeholder={t('employees')}
                            key={radioView}
                            required
                            endpoint={API.EMPLOYEES}
                            additionalFilters={`&only_basic_info=true`}
                            text="fullname_with_titles"
                            label={t('employees')}
                            value={form.person}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, profile: value }))}
                        />
                    )}
                </>
            )}
            <SuperField
                as="choice-select"
                required
                label={t('gdpr')}
                endpoint={API.API_URL + '/common/gdprs/'}
                additionalFilters={`&query={id, title}`}
                text="title"
                value={form.gdpr}
                onChange={(e, { value }) => setForm({ ...form, gdpr: value })}
            />

            {profile === undefined && (
                <SuperField
                    as="checkbox"
                    label={t('send_email_request_for_gdpr')}
                    checked={sendEmail}
                    onChange={() => setSendEmail(!sendEmail)}
                />
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel disabled={isProcessing} onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || form.profile === '' || form.gdpr === ''}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default AgreementForm
