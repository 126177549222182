import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import '@store/languages'
// components
import SuperField from '@components/forms/SuperField'
import { Form, Header, Divider, Icon, Button } from 'semantic-ui-react'

const PauseForm = ({ isSecond, onClose, record, handleChange, calculateDuration }) => {
    const { t } = useTranslation()

    const [pauseStartDate, setPauseStartDate] = useState(record.day)
    const [pauseEndDate, setPauseEndDate] = useState(record.day)
    const [pauseStartTime, setPauseStartTime] = useState(
        isSecond
            ? record.data?.second_start_pause
                ? moment(record.data?.second_start_pause).format('HH:mm')
                : ''
            : record.data?.start_pause
            ? moment(record.data?.start_pause).format('HH:mm')
            : ''
    )
    const [pauseEndTime, setPauseEndTime] = useState(
        isSecond
            ? record.data?.second_end_pause
                ? moment(record.data?.second_end_pause).format('HH:mm')
                : ''
            : record.data?.end_pause
            ? moment(record.data?.end_pause).format('HH:mm')
            : ''
    )

    const [isInvalid, setIsInvalid] = useState(false)

    const handleSubmit = () => {
        setIsInvalid(false)
        let start_pause = pauseStartDate + ' ' + pauseStartTime
        let end_pause = pauseEndDate + ' ' + pauseEndTime

        if (
            moment(start_pause, 'YYYY-MM-DD HH:mm', true).isValid() &&
            moment(end_pause, 'YYYY-MM-DD HH:mm', true).isValid()
        ) {
            handleChange(record.day, start_pause, isSecond ? 'second_start_pause' : 'start_pause')
            handleChange(record.day, end_pause, isSecond ? 'second_end_pause' : 'end_pause')
            handleChange(record.day, false, isSecond ? 'auto_second_break' : 'auto_break')

            let changed_record = record
            changed_record['start_pause'] = start_pause
            changed_record['end_pause'] = end_pause
            // calculateDuration(changed_record)
            onClose()
        } else {
            setIsInvalid(true)
        }
    }

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <Header as="h3" content={record.data?.start_pause ? t('update_pause') : t('add_pause')} />
            <Divider />
            {isInvalid && (
                <p style={{ color: 'var(--danger)', textAlign: 'center', fontWeight: 'bold' }}>
                    <Icon name="warning circle" /> {t('invalid_pause_definition')}
                </p>
            )}
            <Form.Group widths="equal">
                <SuperField
                    required
                    as="datepicker"
                    label={t('date_from')}
                    value={pauseStartDate}
                    onChange={(e, { value }) => setPauseStartDate(value)}
                />
                <SuperField
                    required
                    as="timepicker"
                    label={t('time_from')}
                    value={pauseStartTime}
                    onChange={(e, { value }) => setPauseStartTime(value)}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    required
                    as="datepicker"
                    label={t('date_to')}
                    value={pauseEndDate}
                    onChange={(e, { value }) => setPauseEndDate(value)}
                />
                <SuperField
                    required
                    as="timepicker"
                    label={t('time_to')}
                    value={pauseEndTime}
                    onChange={(e, { value }) => setPauseEndTime(value)}
                />
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <Button primary type="button" content={t('confirm')} onClick={() => handleSubmit()} />
            </Form.Field>
        </Form>
    )
}

export default PauseForm
