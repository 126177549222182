import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider } from 'semantic-ui-react'

const BulkBonusForm = ({ selected, setSelected, onClose, setData, toExclude }) => {
    const { t } = useTranslation()

    toExclude = toExclude || []

    const [isProcessing, setIsProcessing] = useState(false)

    const [form, setForm] = useState({
        bonuses: [],
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)
        let updated = []

        for (let i = 0; i < selected.length; i++) {
            let validBonusesToAssign = []
            for (let y = 0; y < form.bonuses.length; y++) {
                if (!selected[i].bonuses.find((item) => item.id === form.bonuses[y])) {
                    validBonusesToAssign.push(form.bonuses[y])
                }
            }

            if (validBonusesToAssign.length > 0) {
                const request = await requests.patch(API.CONTRACTS + selected[i].id + '/?query={id, bonuses}', {
                    bonuses: { add: validBonusesToAssign },
                })
                if (request.status === 200) {
                    updated.push(request.response)
                }
            } else {
                updated.push(selected[i])
            }
        }

        setData((prev) =>
            prev.filter((item) => {
                const itemToUpdate = updated.find((updatedItem) => updatedItem.id === item.id)
                if (item.id === itemToUpdate?.id) {
                    item.bonuses = itemToUpdate.bonuses
                }
                return item
            })
        )

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('bonuses_were_assigned'),
            animation: 'pulse',
            time: 2000,
        })

        if (setSelected) {
            setSelected([])
        }

        onClose()
        setIsProcessing(true)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="choice-select"
                search
                multiple
                label={t('bonuses')}
                settings="types/bonuses"
                value={form.bonuses}
                endpoint={API.PAYROLLS + 'bonuses/'}
                exclude={toExclude}
                text="title"
                onChange={(e, { value }) =>
                    setForm((prev) => ({
                        ...prev,
                        bonuses: value,
                    }))
                }
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={isProcessing} text={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export default BulkBonusForm
