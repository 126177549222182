import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
import { handleURL, formatPhoneNumber } from '@helpers/functions'
import { toast } from 'react-semantic-toasts'
import { useHasPermission } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import AvatarList from '@components/AvatarList'
import CanView from '@components/perms/CanView'
import ShortenText from '@components/ShortenText'
import DatePresets from '@components/DatePresets'
import LabelsList from '@components/lists/LabelsList'
import BreadcrumbNav from '@components/BreadcrumbNav'
import SuperField from '@components/forms/SuperField'
import Attachments from '@components/Attachments'
import ConfirmModal from '@components/modals/ConfrimModal'
import { Popup, Label, Form, List, Divider } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'

// module specific components
import LeadForm from './components/LeadForm'
import QuickNotes from './components/QuickNotes'
import LeadListStats from './components/LeadListStats'
import LastContactedForm from './components/LastContactedForm'

const Leads = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const canManage = useHasPermission('orders.c_manage_offer')

    const [metricValues, setMericValues] = useState([])
    const [focusedPopup, setFocusedPopup] = useState(0)
    const [openPopup, setOpenPopup] = useState(0)

    const [attachmentsOpen, setAttachmentsOpen] = useState(0)

    useEffect(() => {
        async function fetchMetricValues() {
            const request = await requests.get(API.ORDERS + 'lead_count_number_values/')
            if (request.status === 200) {
                setMericValues(request.response.filter((item) => item !== ''))
            }
        }

        fetchMetricValues()
    }, [])

    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev])
        setTotal((prev) => prev + 1)
    }

    const onUpdate = (response, setData) => {
        setData((prev) =>
            prev.map((item) => {
                if (item.id === response.id) {
                    item = response
                }
                return item
            })
        )
    }

    const determinateColor = (state) => {
        let color = 'var(--dark)'

        if (state === 1) color = 'var(--info)'
        if (state === 2) color = 'var(--primary)'
        if (state === 3) color = 'var(--warning)'
        if (state === 4) color = 'var(--blue)'
        if (state === 5) color = 'var(--success)'
        if (state === 6) color = 'var(--danger)'
        return color
    }

    const onStateChange = async (state, lead, setData) => {
        if (lead.state !== state) {
            const request = await requests.patch(API.LEADS + lead.id + '/', { state: state })
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === lead.id) {
                            item = request.response
                        }
                        return item
                    })
                )
            }
        }
    }

    return (
        <CanView permissions={['orders.c_view_offer']} redirect>
            <BreadcrumbNav items={[{ name: t('leads'), icon: '', href: '' }]} />
            <ListView
                as="table"
                allowSearch
                allowSelection
                allowRowExport
                endpoint={API.LEADS}
                actionsCellWidth="2"
                showStatistic
                visibleStatsOnRender
                initialFilters={{
                    is_opportunity: '',
                    has_www: '',
                    has_phone: '',
                    has_email: '',
                    not_contacted: '',
                    count_number_value: '',
                    count_number_min: '',
                    count_number_max: '',
                    assigned_to: [],
                    state: '',
                    unit: '',
                    source: [],
                    tags: [],
                    ordering: '-created_on',
                }}
                renderFilterFields={(filters, setFilters, visible) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('date_from')}
                                value={filters.contacted_after}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setFilters((prev) => ({ ...prev, contacted_after: value }))}
                            />
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('date_to')}
                                value={filters.contacted_before}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) =>
                                    setFilters((prev) => ({ ...prev, contacted_before: value }))
                                }
                            />
                        </Form.Group>
                        <DatePresets
                            from={filters.valid_fro}
                            to={filters.contacted_before}
                            onSelection={(firstMonthDay, lastMonthDay, year) => {
                                setFilters((prev) => ({
                                    ...prev,
                                    contacted_after: firstMonthDay,
                                    contacted_before: lastMonthDay,
                                }))
                            }}
                        />
                        <Divider />

                        <SuperField
                            as="choice"
                            search
                            multiple
                            label={t('assigned_to')}
                            endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                            text="fullname"
                            value={filters.assigned_to}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    assigned_to: value,
                                })
                            }
                        />
                        <SuperField
                            search
                            multiple
                            as="choice"
                            text="name"
                            label={t('tags')}
                            value={filters.tags}
                            endpoint={API.ORDERS + 'labels/?lead_tag=true'}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    tags: value,
                                })
                            }
                        />

                        <SuperField
                            as="choice"
                            search
                            multiple
                            label={t('source')}
                            text="title"
                            endpoint={API.ORDERS + 'sale_sources/'}
                            value={filters.source}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    source: value,
                                })
                            }
                        />

                        <SuperField
                            search
                            as="choice"
                            label={t('number_of_employees')}
                            value={filters.number_of_employees}
                            type="company_size_choices"
                            onChange={(e, { value }) => setFilters({ ...filters, number_of_employees: value })}
                        />

                        <SuperField
                            as="choice"
                            search
                            value={filters.count_number_value}
                            label={t('custom_metric_parameter')}
                            onChange={(e, { value }) => {
                                if (value) {
                                    return setFilters((prev) => ({ ...prev, count_number_value: value }))
                                } else {
                                    return setFilters((prev) => ({
                                        ...prev,
                                        count_number_value: '',
                                        count_number_min: '',
                                        count_number_max: '',
                                    }))
                                }
                            }}
                            customOptions={metricValues.map((item, idx) => ({ key: idx, value: item, text: item }))}
                        />

                        {filters.count_number_value && (
                            <>
                                <Form.Group widths="equal">
                                    <SuperField
                                        as="input"
                                        label={t('min_value')}
                                        value={filters.count_number_min}
                                        onChange={(e, { value }) =>
                                            setFilters((prev) => ({ ...prev, count_number_min: value }))
                                        }
                                    />
                                    <SuperField
                                        as="input"
                                        label={t('max_value')}
                                        value={filters.count_number_max}
                                        onChange={(e, { value }) =>
                                            setFilters((prev) => ({ ...prev, count_number_max: value }))
                                        }
                                    />
                                </Form.Group>
                                <Divider />
                            </>
                        )}

                        <Form.Group widths="equal">
                            <SuperField
                                as="choice"
                                label={t('state')}
                                type="lead_state_choices"
                                value={filters.state?.toString() || ''}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        state: value,
                                        stage: '',
                                    })
                                }
                            />

                            <SuperField
                                as="choice"
                                search
                                value={filters.unit}
                                endpoint={
                                    API.UNITS + '?query={id, name}&only_from_active_company=true&only_basic_info=true'
                                }
                                text="name"
                                label={t('unit')}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        unit: value,
                                    })
                                }
                            />
                        </Form.Group>

                        <SuperField
                            as="checkbox"
                            label={t('only_leads_that_were_not_converted_to_opportunity')}
                            checked={filters.is_opportunity === false}
                            onChange={() =>
                                setFilters((prev) => ({
                                    ...prev,
                                    is_opportunity: filters.is_opportunity === false ? '' : false,
                                }))
                            }
                        />
                        <Divider />
                        <Form.Group widths="equal">
                            <SuperField
                                as="checkbox"
                                label={t('has_www')}
                                checked={filters.has_www === true}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        has_www: filters.has_www === true ? '' : true,
                                    }))
                                }
                            />
                            <SuperField
                                as="checkbox"
                                label={t('has_not_www')}
                                checked={filters.has_www === false}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        has_www: filters.has_www === false ? '' : false,
                                    }))
                                }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField
                                as="checkbox"
                                label={t('has_phone')}
                                checked={filters.has_phone === true}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        has_phone: filters.has_phone === true ? '' : true,
                                    }))
                                }
                            />
                            <SuperField
                                as="checkbox"
                                label={t('has_not_phone')}
                                checked={filters.has_phone === false}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        has_phone: filters.has_phone === false ? '' : false,
                                    }))
                                }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField
                                as="checkbox"
                                label={t('has_email')}
                                checked={filters.has_email === true}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        has_email: filters.has_email === true ? '' : true,
                                    }))
                                }
                            />
                            <SuperField
                                as="checkbox"
                                label={t('has_not_email')}
                                checked={filters.has_email === false}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        has_email: filters.has_email === false ? '' : false,
                                    }))
                                }
                            />
                        </Form.Group>
                    </>
                )}
                exports={[
                    {
                        endpoint: API.EXPORTS + 'dynamic_leads/',
                        permissions: ['orders.c_view_offer', 'orders.c_view_user_offer'],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: 'leads',
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create'),
                        modalSize: 'small',
                        permissions: ['orders.c_manage_offer'],
                        modal: (
                            <LeadForm
                                isOpportunity={false}
                                handleRequest={(response, setData, setTotal) => onCreate(response, setData, setTotal)}
                            />
                        ),
                    },
                    // {
                    //     name: t('convert_to_opportunity'),
                    //     type: "custom",
                    //     as: "confirm",
                    //     icon: "sync-outline",
                    //     text: t('convert_to_opportunity'),
                    //     permissions: ['orders.c_manage_offer'],
                    //     isHidden: (item) => item.is_opportunity,
                    //     onClick: async (item, setData, setTotal) => {
                    //         let data = {
                    //             is_opportunity: true,
                    //             state: 5,
                    //             stage: 1
                    //         }
                    //         const request = await requests.patch(API.LEADS + item.id + "/", data)

                    //         if (request.status === 200) {
                    //             toast({
                    //                 type: 'success',
                    //                 icon: 'check circle',
                    //                 title: t('converted_to_opportunity'),
                    //                 animation: 'pulse',
                    //                 time: 2000,
                    //             })

                    //             setData(prev => prev.map(lead => {
                    //                 if (lead.id === item.id) {
                    //                     lead = request.response
                    //                 }

                    //                 return lead
                    //             }))
                    //         }
                    //     }
                    // },
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'small',
                        permissions: ['orders.c_manage_offer'],
                        modal: (item, setData) => (
                            <LeadForm
                                isOpportunity={false}
                                data={item}
                                setData={setData}
                                handleRequest={(response, setData, setTotal) => onUpdate(response, setData, setTotal)}
                            />
                        ),
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure'),
                        permissions: ['orders.c_delete_offer'],
                    },
                ]}
                tableHeaders={[
                    { title: t('fullname'), orderBy: 'lastname' },
                    { title: t('position'), orderBy: 'title' },
                    { title: t('company_name'), orderBy: 'company_name' },
                    { title: t('contact') },
                    { title: t('last_contacted'), orderBy: 'last_contacted' },
                    { title: t('tracking_params') },
                    { title: t('tags') },
                    { title: t('source'), orderBy: 'source__title' },
                    { title: t('assigned_to') },
                    { title: t('description') },
                    { title: t('notes') },
                    { title: t('attachments') },
                    { title: t('state'), orderBy: 'state' },
                    { title: t('created') },
                ]}
                renderCells={(lead, setData) => [
                    {
                        width: 1,
                        content: `${lead.firstname || ''} ${lead.lastname || ''}`,
                    },
                    {
                        width: 2,
                        content: lead.title ? <ShortenText text={lead.title || ''} /> : '--',
                    },
                    {
                        content: (
                            <span>
                                <span>
                                    <ShortenText text={lead.company_name || ''} />
                                </span>
                                <br />
                                <small>{lead.company_id || ''}</small>
                            </span>
                        ),
                    },
                    {
                        content: (
                            <>
                                <div>
                                    {lead.contacts.length === 0 ? (
                                        <strong style={{ color: 'var(--danger)' }}> {t('no_active_contact')} </strong>
                                    ) : (
                                        <>
                                            {lead.contacts[0]?.contact_person_name ? (
                                                <strong>
                                                    {lead.contacts[0]?.contact_person_name} <br />
                                                </strong>
                                            ) : (
                                                ''
                                            )}
                                            {lead.contacts[0]?.phone ? (
                                                <>
                                                    <a href={'callto:' + lead.contacts[0]?.phone}>
                                                        {' '}
                                                        <Icon
                                                            name="call-outline"
                                                            style={{ marginRight: '0.2rem' }}
                                                        />{' '}
                                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                            {formatPhoneNumber(lead.contacts[0]?.phone)}
                                                        </span>{' '}
                                                    </a>{' '}
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {lead.contacts[0]?.email ? (
                                                <>
                                                    <a href={'mailto:' + lead.contacts[0]?.email}>
                                                        {' '}
                                                        <Icon
                                                            name="mail-outline"
                                                            style={{ marginRight: '0.2rem' }}
                                                        />{' '}
                                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                            {lead.contacts[0]?.email}
                                                        </span>{' '}
                                                    </a>{' '}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {lead.contacts.length > 1 && (
                                                <div>
                                                    <SuperDuperModal
                                                        size="tiny"
                                                        content={
                                                            <div>
                                                                {lead.contacts.map((contact, idx) => (
                                                                    <div key={idx}>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                itemsAlign: 'start',
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                {contact.contact_person_name !== '' && (
                                                                                    <strong>
                                                                                        {contact.contact_person_name}{' '}
                                                                                        <br />
                                                                                    </strong>
                                                                                )}
                                                                                {contact.phone !== '' ? (
                                                                                    <>
                                                                                        <a
                                                                                            href={
                                                                                                'callto:' +
                                                                                                contact.phone
                                                                                            }
                                                                                        >
                                                                                            {' '}
                                                                                            <Icon
                                                                                                name="call-outline"
                                                                                                style={{
                                                                                                    marginRight:
                                                                                                        '0.2rem',
                                                                                                }}
                                                                                            />{' '}
                                                                                            <span
                                                                                                style={{
                                                                                                    position:
                                                                                                        'relative',
                                                                                                    top: '-0.2rem',
                                                                                                }}
                                                                                            >
                                                                                                {formatPhoneNumber(
                                                                                                    contact.phone
                                                                                                )}
                                                                                            </span>{' '}
                                                                                        </a>{' '}
                                                                                        <br />
                                                                                    </>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {contact?.email !== '' ? (
                                                                                    <>
                                                                                        <a
                                                                                            href={
                                                                                                'mailto:' +
                                                                                                contact.email
                                                                                            }
                                                                                        >
                                                                                            {' '}
                                                                                            <Icon
                                                                                                name="mail-outline"
                                                                                                style={{
                                                                                                    marginRight:
                                                                                                        '0.2rem',
                                                                                                }}
                                                                                            />{' '}
                                                                                            <span
                                                                                                style={{
                                                                                                    position:
                                                                                                        'relative',
                                                                                                    top: '-0.2rem',
                                                                                                }}
                                                                                            >
                                                                                                {contact.email}
                                                                                            </span>{' '}
                                                                                        </a>{' '}
                                                                                    </>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {contact?.is_invoicing && (
                                                                                    <div
                                                                                        style={{
                                                                                            opacity: 0.8,
                                                                                            fontSize: '0.9rem',
                                                                                            fontWeight: 'bold',
                                                                                            marginTop: '0.2rem',
                                                                                        }}
                                                                                    >
                                                                                        {t('used_for_invoicing')}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div style={{ textAlign: 'right' }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontWeight: 'bold',
                                                                                        opacity: 0.5,
                                                                                        marginRight: '1rem',
                                                                                        position: 'relative',
                                                                                        top: '-0.1rem',
                                                                                    }}
                                                                                >
                                                                                    #{idx + 1}.
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <Divider />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                        trigger={
                                                            <span className="ref-link" style={{ color: 'var(--dark)' }}>
                                                                {t('show_more_contacts')}
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {lead?.url && (
                                    <div>
                                        <Icon
                                            name="globe"
                                            style={{
                                                marginRight: '0.5rem',
                                                color: 'var(--primary)',
                                                position: 'relative',
                                                top: '0.1rem',
                                            }}
                                        />
                                        <a href={handleURL(lead.url)} target="_blank" rel="noopener noreferrer">
                                            {handleURL(lead.url)}
                                        </a>
                                    </div>
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                {lead?.last_contacted ? (
                                    <strong>
                                        {' '}
                                        {moment(lead.last_contacted).format('HH:mm') === '00:00'
                                            ? moment(lead?.last_contacted).format(dateFormat)
                                            : tzDateTime(lead?.last_contacted).format(dateFormat + ' HH:mm')}{' '}
                                    </strong>
                                ) : (
                                    t('not_contacted')
                                )}{' '}
                                <br />
                                <SuperDuperModal
                                    header={t('add_last_contacted')}
                                    trigger={
                                        <small className="ref-link" style={{ color: 'var(--primary)' }}>
                                            {t('add_date')}
                                        </small>
                                    }
                                    content={<LastContactedForm lead={lead} setData={setData} />}
                                />
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                {!lead.number_of_employees && !lead.count_number_value && (
                                    <div style={{ opacity: '0.5' }}>{t('no_params')}</div>
                                )}
                                {lead.number_of_employees && (
                                    <div>
                                        <strong>
                                            {t('number_of_employees')}: {lead.number_of_employees}
                                        </strong>
                                    </div>
                                )}

                                {lead.count_number_value && (
                                    <div>
                                        <strong>{lead.count_number_value}</strong>: {lead.count_number}
                                    </div>
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <LabelsList
                                key={lead.id}
                                rowID={lead.id}
                                labels={lead.tags}
                                endpoint={API.LEADS}
                                additionalFilters="&lead_tag=true"
                                mark="lead_tag"
                                endpointLabels={API.ORDERS + 'labels/?lead_tag=true'}
                                label_key="tags"
                            />
                        ),
                    },
                    {
                        content: lead?.source?.id ? (
                            <Label
                                style={{
                                    color: 'var(--dark)',
                                    background: lead.source?.color === '' ? 'var(--dark)' : lead.source?.color + '80',
                                }}
                            >
                                <ShortenText text={lead.source?.title} />
                            </Label>
                        ) : (
                            '--'
                        ),
                    },
                    {
                        content: (
                            <AvatarList
                                size="25"
                                alignDirection="start"
                                persons={lead.assigned_to.map((item) => ({
                                    id: item.id,
                                    name: item.fullname,
                                    avatar: item.profile_picture,
                                    displayName: item?.fullname_with_titles || item.fullname,
                                }))}
                            />
                        ),
                    },
                    {
                        content: lead.description ? (
                            <Popup
                                hoverable
                                size="tiny"
                                position="left center"
                                trigger={<Icon name="document-text-outline" style={{ fontSize: '1.3rem' }} />}
                                content={lead.description}
                            />
                        ) : (
                            ''
                        ),
                    },
                    { content: <QuickNotes lead={lead} setData={setData} /> },
                    {
                        content: (
                            <Label basic>
                                <Popup
                                    basic
                                    hoverable
                                    position="left center"
                                    open={attachmentsOpen === lead.id}
                                    style={{ zIndex: '1000', minWidth: '450px' }}
                                    onOpen={() => setAttachmentsOpen(lead.id)}
                                    trigger={
                                        <div style={{ cursor: 'pointer' }}>
                                            <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                {lead.attachments.length}
                                            </span>
                                        </div>
                                    }
                                    content={
                                        <Attachments
                                            viewOnly={!canManage}
                                            allowFolders
                                            record={lead}
                                            moduleName={'leads'}
                                            setData={setData}
                                            sourceEndpoint={API.LEADS}
                                            closeView={() => setAttachmentsOpen(0)}
                                        />
                                    }
                                />
                            </Label>
                        ),
                    },
                    {
                        content: (
                            <>
                                {canManage ? (
                                    <>
                                        <Popup
                                            hoverable
                                            position="bottom left"
                                            open={openPopup === lead.id || focusedPopup === lead.id}
                                            onOpen={() => setOpenPopup(lead.id)}
                                            onClose={() => setOpenPopup(0)}
                                            trigger={
                                                <span>
                                                    <Icon
                                                        name="ellipse"
                                                        style={{
                                                            marginRight: '0.5rem',
                                                            color: determinateColor(lead.state),
                                                        }}
                                                    />
                                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                        <ShortenText text={lead.state_display} />
                                                    </span>
                                                </span>
                                            }
                                            content={
                                                <div>
                                                    <strong>{t('change_state')}:</strong>
                                                    <List divided selection style={{ width: '250px' }}>
                                                        <List.Item
                                                            active={lead.state === 1}
                                                            onClick={() => onStateChange(1, lead, setData)}
                                                        >
                                                            <Icon
                                                                name="ellipse"
                                                                style={{
                                                                    marginRight: '0.5rem',
                                                                    color: determinateColor(1),
                                                                }}
                                                            />
                                                            <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                                {t('new')}
                                                            </span>
                                                        </List.Item>
                                                        <List.Item
                                                            active={lead.state === 2}
                                                            onClick={() => onStateChange(2, lead, setData)}
                                                        >
                                                            <Icon
                                                                name="ellipse"
                                                                style={{
                                                                    marginRight: '0.5rem',
                                                                    color: determinateColor(2),
                                                                }}
                                                            />
                                                            <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                                {t('assigned')}
                                                            </span>
                                                        </List.Item>
                                                        <List.Item
                                                            active={lead.state === 3}
                                                            onClick={() => onStateChange(3, lead, setData)}
                                                        >
                                                            <Icon
                                                                name="ellipse"
                                                                style={{
                                                                    marginRight: '0.5rem',
                                                                    color: determinateColor(3),
                                                                }}
                                                            />
                                                            <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                                {t('in_process')}
                                                            </span>
                                                        </List.Item>
                                                        <List.Item
                                                            active={lead.state === 4}
                                                            onClick={() => onStateChange(4, lead, setData)}
                                                        >
                                                            <Icon
                                                                name="ellipse"
                                                                style={{
                                                                    marginRight: '0.5rem',
                                                                    color: determinateColor(4),
                                                                }}
                                                            />
                                                            <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                                {t('recycled')}
                                                            </span>
                                                        </List.Item>
                                                        <ConfirmModal
                                                            description={t('convert_to_opportunity')}
                                                            button={
                                                                <List.Item
                                                                    disabled={lead.state === 5}
                                                                    active={lead.state === 5}
                                                                    onClick={() => setFocusedPopup(lead.id)}
                                                                >
                                                                    <Icon
                                                                        name="ellipse"
                                                                        style={{
                                                                            marginRight: '0.5rem',
                                                                            color: determinateColor(5),
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            position: 'relative',
                                                                            top: '-0.10rem',
                                                                        }}
                                                                    >
                                                                        {t('converted_lead')}
                                                                    </span>
                                                                </List.Item>
                                                            }
                                                            onConfirm={async () => {
                                                                const request = await requests.patch(
                                                                    API.LEADS + lead.id + '/',
                                                                    {
                                                                        is_opportunity: true,
                                                                        state: 5,
                                                                        stage: 1,
                                                                    }
                                                                )

                                                                if (request.status === 200) {
                                                                    toast({
                                                                        type: 'success',
                                                                        icon: 'check circle',
                                                                        title: t('converted_to_opportunity'),
                                                                        animation: 'pulse',
                                                                        time: 2000,
                                                                    })

                                                                    setData((prev) =>
                                                                        prev.map((item) => {
                                                                            if (item.id === lead.id) {
                                                                                item = request.response
                                                                            }

                                                                            return item
                                                                        })
                                                                    )
                                                                    setFocusedPopup(0)
                                                                }
                                                            }}
                                                            onRefuse={() => setFocusedPopup(0)}
                                                        />

                                                        <List.Item
                                                            active={lead.state === 6}
                                                            onClick={() => onStateChange(6, lead, setData)}
                                                        >
                                                            <Icon
                                                                name="ellipse"
                                                                style={{
                                                                    marginRight: '0.5rem',
                                                                    color: determinateColor(6),
                                                                }}
                                                            />
                                                            <span style={{ position: 'relative', top: '-0.10rem' }}>
                                                                {t('dead')}
                                                            </span>
                                                        </List.Item>
                                                    </List>
                                                </div>
                                            }
                                        />
                                        {lead.is_opportunity === true && (
                                            <Popup
                                                position="left center"
                                                trigger={
                                                    <Icon
                                                        name="checkmark-circle"
                                                        style={{
                                                            color: 'var(--dark)',
                                                            marginLeft: '0.5rem',
                                                            position: 'relative',
                                                            top: '-0.2rem',
                                                        }}
                                                    />
                                                }
                                                content={
                                                    <div>
                                                        {t('converted_to_opportunity')}:
                                                        {lead?.converted_status_update_date && (
                                                            <strong>
                                                                {' '}
                                                                <br />{' '}
                                                                {tzDateTime(lead?.converted_status_update_date).format(
                                                                    dateFormat + ' HH:mm'
                                                                )}{' '}
                                                            </strong>
                                                        )}
                                                    </div>
                                                }
                                            />
                                        )}
                                    </>
                                ) : (
                                    <div>
                                        <Icon
                                            name="ellipse"
                                            style={{ marginRight: '0.5rem', color: determinateColor(lead.state) }}
                                        />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {lead.state_display}{' '}
                                            {lead.is_opportunity === true && (
                                                <Popup
                                                    position="left center"
                                                    trigger={
                                                        <Icon
                                                            name="checkmark-circle"
                                                            style={{ color: 'var(--dark)', marginLeft: '0.2rem' }}
                                                        />
                                                    }
                                                    content={
                                                        <div>
                                                            {t('converted_to_opportunity')}:
                                                            {lead?.converted_status_update_date && (
                                                                <strong>
                                                                    {' '}
                                                                    <br />{' '}
                                                                    {tzDateTime(
                                                                        lead?.converted_status_update_date
                                                                    ).format(dateFormat + ' HH:mm')}{' '}
                                                                </strong>
                                                            )}
                                                        </div>
                                                    }
                                                />
                                            )}
                                        </span>
                                    </div>
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <div>
                                {tzDateTime(lead.created_on).format(dateFormat + ' HH:mm')} <br />
                                <span>
                                    {t('created_by')} <strong>{lead.created_by?.name || '--'}</strong>
                                </span>
                            </div>
                        ),
                    },
                ]}
                renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                    <LeadListStats
                        data={data}
                        total={total}
                        params={params}
                        filters={filters}
                        setFilters={setFilters}
                        fetchData={fetchData}
                    />
                )}
            />
        </CanView>
    )
}

export default Leads
