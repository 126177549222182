import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
import { toast } from 'react-semantic-toasts'
// components
import Icon from '@components/Icon'
import { Grid, Form, Message, Button, Header, Label, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import CanView from '@components/perms/CanView'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import CustomFormDivider from '@components/general/CustomFormDivider'
import LabelForm from './LabelForm'

const ProjectUpdateForm = ({ project, setProject, listView, setData, handleRequest }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: project.name ? project.name : null,
        identifier: project.identifier ? project.identifier : null,
        description: project.description ? project.description : null,
        budget_amount: project.budget_amount ? project.budget_amount : null,
        project_manager: project.project_manager ? project.project_manager.id : null,
        from_date: project.from_date ? project.from_date : null,
        due_date: project.due_date ? project.due_date : null,
        end_date: project.end_date ? project.end_date : null,
        is_active: project.is_active ? project.is_active : null,
        unit: project.unit ? project.unit?.id : null,
    })

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)

        const request = await requests.patch(API.PROJECTS + project.id + '/', {
            ...form,
            end_date: !form.is_active ? moment().format('YYYY-MM-DD') : null,
        })

        if (request.status === 200) {
            setProject(request.response)
            toast({
                type: 'success',
                title: t('updated'),
                description: t('project_was_sucessfully_updated'),
                animation: 'bounce',
                time: 5000,
            })
        } else if (request.status === 400) {
            setErrors(request.response)
            toast({
                type: 'error',
                icon: 'warning',
                title: t('something_went_wrong'),
                description: t('something_went_wrong'),
                animation: 'bounce',
                time: 5000,
            })
        }

        setIsProcessing(false)
    }

    const onLabelsAdd = async (id, labelID) => {
        const request = await requests.patch(API.PROJECTS + id + '/?query={id, labels}', {
            labels: {
                add: [labelID],
            },
        })
        if (request.status === 200) {
            setProject((prev) => ({ ...prev, labels: request.response.labels }))
        }
    }

    const onLabelRemove = async (id, labelID) => {
        const request = await requests.del(API.PROJECTS + 'labels/' + labelID + '/')
        if (request.status === 204) {
            setProject((prev) => ({ ...prev, labels: prev.labels.filter((item) => item.id !== labelID) }))
        }
    }

    const handleOpenState = async () => {
        setIsProcessing(true)
        const request = await requests.patch(API.PROJECTS + project.id + '/', {
            is_active: !project.is_active,
            end_date: !project.is_active === true ? moment().format('YYYY-MM-DD') : null,
        })

        if (request.status === 200) {
            setProject(request.response)
        }
        setIsProcessing(false)
    }

    return (
        <>
            <Grid columns={3}>
                <Grid.Row>
                    <Grid.Column width={listView ? 16 : 10}>
                        <Header as="h3">
                            <Header.Content>
                                <span style={{ position: 'relative', top: '-0.1rem' }}>{t('project_details')}</span>
                            </Header.Content>
                        </Header>
                        <Divider />
                        <Form onSubmit={handleSubmit}>
                            <Message
                                error
                                visible={errors?.non_field_errors?.length > 0}
                                header={t('error_submission_message')}
                                list={errors?.non_field_errors}
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <SuperField
                                        as="input"
                                        required
                                        autoFocus
                                        label={t('name')}
                                        placeholder={t('name')}
                                        value={form.name}
                                        error={errors?.name || false}
                                        onChange={(e, { value }) => setForm({ ...form, name: value })}
                                    />
                                    <Form.Group widths="equal">
                                        <CanView permissions={['projects.c_assign_project_manager']}>
                                            <SuperField
                                                as="choice-select"
                                                search
                                                style={{ marginTop: '4px' }}
                                                text="fullname"
                                                label={t('project_manager')}
                                                value={form?.project_manager}
                                                error={errors?.project_manager || false}
                                                onChange={(e, { value }) =>
                                                    setForm({ ...form, project_manager: value })
                                                }
                                                endpoint={API.EMPLOYEES}
                                                additionalFilters="&only_basic_info=true"
                                                initialOptions={{
                                                    attribute: 'fullname',
                                                    source: project?.project_manager,
                                                }}
                                            />
                                        </CanView>
                                        <SuperField
                                            as="choice-select"
                                            search
                                            text="name"
                                            label={t('unit')}
                                            value={form.unit}
                                            endpoint={API.UNITS}
                                            additionalFilters="&query={id, name}&only_from_active_company=true"
                                            onChange={(e, { value }) => setForm({ ...form, unit: value })}
                                            initialOptions={{
                                                attribute: 'name',
                                                source: project?.unit,
                                            }}
                                        />
                                    </Form.Group>

                                    <SuperField
                                        as="textarea"
                                        label={t('description')}
                                        placeholder={t('description')}
                                        value={form?.description}
                                        error={errors?.description || false}
                                        onChange={(e, { value }) => setForm({ ...form, description: value })}
                                    />

                                    <Form.Group widths={2}>
                                        <SuperField
                                            as="datepicker"
                                            label={t('start_date')}
                                            value={form?.from_date}
                                            error={errors?.from_date || false}
                                            onChange={(e, { value }) => {
                                                value === '' || value === null
                                                    ? setForm({ ...form, from_date: null })
                                                    : setForm({ ...form, from_date: value })
                                            }}
                                        />
                                        <SuperField
                                            as="datepicker"
                                            label={t('due_date')}
                                            value={form?.due_date}
                                            error={errors?.due_date || false}
                                            onChange={(e, { value }) => {
                                                value === '' || value === null
                                                    ? setForm({ ...form, due_date: null })
                                                    : setForm({ ...form, due_date: value })
                                            }}
                                        />
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <CanView
                                permissions={['projects.c_manage_all_projects', 'projects.c_manage_user_projects']}
                            >
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field style={{ textAlign: 'right', margin: '1rem' }}>
                                            <Button
                                                style={{
                                                    background: project.is_active ? 'var(--danger)' : 'var(--dark)',
                                                    color: 'var(--white)',
                                                }}
                                                disabled={isProcessing}
                                                loading={isProcessing}
                                                size="large"
                                                type="button"
                                                onClick={() => handleOpenState(project.is_active)}
                                            >
                                                {project.is_active ? t('close_project') : t('open_project')}
                                            </Button>
                                            <Button disabled={isProcessing} loading={isProcessing} size="large" primary>
                                                {' '}
                                                {t('save')}{' '}
                                            </Button>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </CanView>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width="6">
                        <CustomFormDivider
                            header={
                                <Header as="h3">
                                    <Header.Content
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('labels')}</span>
                                        <CanView permissions={['projects.c_manage_labels']}>
                                            <SuperDuperModal
                                                header={t('add_label')}
                                                trigger={
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <Icon
                                                            name="add-outline"
                                                            style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                                                        />{' '}
                                                        <span
                                                            style={{
                                                                position: 'relative',
                                                                top: '-0.2rem',
                                                                fontSize: '0.9rem',
                                                            }}
                                                        >
                                                            {t('add_label')}
                                                        </span>
                                                    </div>
                                                }
                                                content={
                                                    <LabelForm onAdd={(label) => onLabelsAdd(project.id, label.id)} />
                                                }
                                            />
                                        </CanView>
                                    </Header.Content>
                                </Header>
                            }
                            content={
                                <>
                                    {project.labels?.map((label, index) => (
                                        <div
                                            key={label.id}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '1rem',
                                                marginBottom: '0.2rem',
                                                background: 'var(--white)',
                                                boxShadow: '0 1px 3px 0 #d4d4d5,0 0 0 1px #d4d4d500',
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Label
                                                    style={{
                                                        background: label.color,
                                                        height: '20px',
                                                        width: '20px',
                                                        marginRight: '1rem',
                                                    }}
                                                />{' '}
                                                <strong>{label.name}</strong>
                                            </div>
                                            <CanView permissions={['projects.c_manage_labels']}>
                                                <div>
                                                    <SuperDuperModal
                                                        header={t('update_label')}
                                                        trigger={
                                                            <Icon
                                                                name="pencil-outline"
                                                                style={{
                                                                    marginLeft: '0.5rem',
                                                                    fontSize: '1.2rem',
                                                                    color: 'var(--dark)',
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        }
                                                        content={<LabelForm label={label} setProject={setProject} />}
                                                    />

                                                    <Icon
                                                        name="close-outline"
                                                        style={{
                                                            marginLeft: '1rem',
                                                            fontSize: '1.2rem',
                                                            color: 'var(--danger)',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => onLabelRemove(project.id, label.id)}
                                                    />
                                                </div>
                                            </CanView>
                                        </div>
                                    ))}
                                </>
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default ProjectUpdateForm
