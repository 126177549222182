import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// store
import { addCurrentBankAccount } from '@store/actions/'
import { formatIBAN } from '@helpers/functions'
// components
import { Header, Divider, Button } from 'semantic-ui-react'
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable'

const SwitchBankAccounts = ({ bankAccounts, currentBankAccount, setRefetch }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const switch_account = (account) => {
        dispatch(addCurrentBankAccount(account.accountId))
        setRefetch(true)
    }

    return (
        <>
            <Header as="h3" content={t('switch_bank_account')} />
            <Divider />
            <FlexTable>
                <FlexRow>
                    <FlexHeader content={t('name')} />
                    <FlexHeader content={t('bank_orga')} />
                    <FlexHeader content={t('iban')} />
                    <FlexHeader content={t('currency')} />
                    <FlexHeader content={t('actions')} />
                </FlexRow>
                {bankAccounts.map((account) => (
                    <FlexRow key={account.accountId} background="transparent" fontSize="0.9rem">
                        <FlexItem content={account.displayName} />
                        <FlexItem content={account.bankName + ' / ' + account.bankCode} />
                        <FlexItem content={formatIBAN(account.accountReference.iban)} />
                        <FlexItem content={account.accountReference.currency} />
                        <FlexItem>
                            {account.accountId === currentBankAccount.accountId ? (
                                <strong> {t('currently_selected')} </strong>
                            ) : (
                                <Button
                                    primary
                                    size="small"
                                    content={t('switch')}
                                    onClick={() => switch_account(account)}
                                />
                            )}
                        </FlexItem>
                    </FlexRow>
                ))}
            </FlexTable>
        </>
    )
}

export default SwitchBankAccounts
