import React, { Fragment, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
// eslint-disable-next-line
import moment from 'moment'
// store
import { icons } from '@icons'
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { dates } from '@helpers/dates'
import { getUrlParameters, formatIBAN } from '@helpers/functions'
import { addCurrentBankAccount } from '@store/actions/'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Ref, Container, Segment, Button, Dropdown, Grid, Divider, Popup } from 'semantic-ui-react'
// module specific components
import Overview from './views/Overview'
import Transactions from './views/Transactions'
import PaymentRequests from './views/PaymentRequests'
import SwitchBankAccounts from './components/SwitchBankAccounts'
import PaymentRequestForm from './components/PaymentRequestForm'

const Payments = () => {
    const segmentRef = useRef()
    const { t } = useTranslation()
    // eslint-disable-next-line
    const dispatch = useDispatch()
    const history = useHistory()
    const bankAccount = useSelector((state) => state.bank_account)
    const reduxUser = useSelector((state) => state.user)
    const [loading, setLoading] = useState(true)
    const [refetch, setRefetch] = useState(true)
    const [connection, setConnection] = useState(null)
    // eslint-disable-next-line
    const [bankAccounts, setBankAccounts] = useState([])
    const [currentBankAccount, setCurrentBankAccount] = useState(null)
    const [premiumConnection, setPremiumConnection] = useState(null)
    const [view, setView] = useState(1)

    useEffect(() => {
        async function fetchConnections() {
            if (refetch === false) return

            const request = await requests.get(API.INVOICES_CONNECTIONS)
            if (request.status === 200) {
                if (request.response.length > 0) {
                    setConnection(request.response[0])

                    // attempt to fetch connection for Premium API:
                    const requestPremiumConnections = await requests.get(
                        API.INVOICES + 'banking/tb/premium/connected/?connection=' + request.response[0].connection_id
                    )
                    if (requestPremiumConnections.status === 200) {
                        if (
                            expiresIn(
                                requestPremiumConnections.response.expires_in,
                                dates.convertUTCDateToLocalDate(requestPremiumConnections.response.authorized_when)
                            ) < 2
                        ) {
                            // if expiration is less then 2 days refresh
                            const refreshPremiumConnection = await requests.post(
                                API.INVOICES +
                                    'banking/tb/premium/refresh/?connection=' +
                                    request.response[0].connection_id
                            )

                            if (refreshPremiumConnection.status === 200) {
                                const refetchPremiumConnection = await requests.get(
                                    API.INVOICES +
                                        'banking/tb/premium/connected/?connection=' +
                                        request.response[0].connection_id
                                )

                                if (refetchPremiumConnection.status === 200) {
                                    setPremiumConnection(requestPremiumConnections.response)
                                    await fetchBankAccounts(request.response[0].connection_id)
                                }
                            }
                        } else {
                            setPremiumConnection(requestPremiumConnections.response)
                            await fetchBankAccounts(request.response[0].connection_id)
                        }
                    }
                }
            }

            setTimeout(function () {
                setLoading(false)
                setRefetch(false)
            }, 500)
        }

        fetchConnections()
        // eslint-disable-next-line
    }, [reduxUser, refetch])

    useEffect(() => {
        const params = getUrlParameters()
        if (params?.payment !== undefined && params?.status !== undefined) {
            setView(3)
        }
    }, [])

    const fetchBankAccounts = async (connection_id) => {
        const request = await requests.get(API.INVOICES + 'banking/tb/premium/accounts/?connection=' + connection_id)
        if (request.status === 200) {
            const allowed_bank_accounts =
                request.response?.accounts?.filter((account) => account.permissions !== false) || []

            setBankAccounts(allowed_bank_accounts)
            if (bankAccount === '') {
                setCurrentBankAccount(allowed_bank_accounts?.[0] || null)
                dispatch(addCurrentBankAccount(allowed_bank_accounts?.[0]?.accountId || ''))
            } else {
                // attemp to find in list of bank accounts this one:
                const foundedBankAccount = allowed_bank_accounts?.find((account) => account?.accountId === bankAccount)
                if (foundedBankAccount !== undefined) {
                    setCurrentBankAccount(foundedBankAccount)
                    dispatch(addCurrentBankAccount(foundedBankAccount?.accountId || ''))
                } else {
                    setCurrentBankAccount(allowed_bank_accounts?.[0] || null)
                    dispatch(addCurrentBankAccount(allowed_bank_accounts?.[0]?.accountId || ''))
                }
            }
        }
    }

    const expiresIn = (expiresInSeconds, authorizedDate) => {
        const expiresInDate = moment(authorizedDate, 'YYYY-MM-DD HH:mm:ss').add(expiresInSeconds, 'seconds')
        const currentDate = moment(moment().format('YYYY-MM-DD HH:mm:ss'))

        return expiresInDate.diff(currentDate, 'days')
    }

    const handlePremiumConnection = async () => {
        setView(1)
        const domain = window.location.href
        const request = await requests.get(
            API.INVOICES + 'banking/tb/premium/authorize/?connection=' + connection.connection_id + '&domain=' + domain
        )

        if (request.status === 200) {
            window.location.href = request.response.authorize_url
        }
    }

    const HeaderPanel = () => {
        const { t } = useTranslation()

        return (
            <>
                <Grid>
                    <Grid.Row verticalAlign="middle" style={{ padding: 0 }} columns={2}>
                        <Grid.Column computer={8} mobile={16} style={{ textAlign: 'left' }}>
                            <Button.Group
                                basic
                                size="small"
                                style={{ borderRadius: '0' }}
                                className="custom-filter-button-group"
                            >
                                <Button active={view === 1} onClick={() => setView(1)}>
                                    <Icon name="bar-chart-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.2rem' }}>{t('overview')}</span>
                                </Button>
                                {currentBankAccount?.permissions?.can_view_account_transactions && (
                                    <Button active={view === 2} onClick={() => setView(2)}>
                                        <Icon name="list-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {t('transactions')}
                                        </span>
                                    </Button>
                                )}
                                {currentBankAccount?.permissions?.can_view_account_payments && (
                                    <Button active={view === 3} onClick={() => setView(3)}>
                                        <Icon name="document-text-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>{t('payments')}</span>
                                    </Button>
                                )}
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: 'right' }} computer={8} mobile={16}>
                            <Button.Group
                                size="small"
                                style={{ borderRadius: '0', textAlign: 'right' }}
                                className="custom-filter-button-group"
                            >
                                <Button.Group>
                                    <Button
                                        style={{ background: 'var(--dark)', color: 'var(--white)', cursor: 'default' }}
                                    >
                                        <Popup
                                            position="left center"
                                            trigger={
                                                <div>
                                                    <Icon
                                                        name="ellipse"
                                                        style={{ marginRight: '0.5rem', color: 'var(--success)' }}
                                                    />
                                                    {currentBankAccount?.bankCode || '--'} |{' '}
                                                    {currentBankAccount?.displayName || '--'}
                                                </div>
                                            }
                                            content={
                                                currentBankAccount?.accountReference?.iban
                                                    ? formatIBAN(currentBankAccount?.accountReference?.iban)
                                                    : '--'
                                            }
                                        />
                                    </Button>
                                    <Dropdown
                                        simple
                                        style={{
                                            background: 'var(--dark-hover)',
                                            color: 'var(--white)',
                                            marginRight: '0.5rem',
                                        }}
                                        className="button icon"
                                        floating
                                        trigger={<Icon name="settings-outline" style={{ marginRight: '0.5rem' }} />}
                                    >
                                        <Dropdown.Menu style={{ marginTop: '0', zIndex: 999 }}>
                                            <SuperDuperModal
                                                size="large"
                                                trigger={
                                                    <Dropdown.Item>
                                                        <Icon
                                                            name="swap-vertical-outline"
                                                            style={{ marginRight: '0.5rem' }}
                                                        />
                                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                            {t('switch_bank_account')}
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <SwitchBankAccounts
                                                        bankAccounts={bankAccounts}
                                                        currentBankAccount={currentBankAccount}
                                                        setRefetch={setRefetch}
                                                    />
                                                }
                                            />
                                            {reduxUser.role !== 4 && (
                                                <Dropdown.Item onClick={() => handlePremiumConnection()}>
                                                    <Icon name="add-outline" style={{ marginRight: '0.5rem' }} />
                                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                        {t('add_bank_account')}
                                                    </span>
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Button.Group>
                                {currentBankAccount?.permissions?.can_manage_account_payments && (
                                    <Button.Group>
                                        <SuperDuperModal
                                            size="small"
                                            closeOnDimmerClick={false}
                                            closeOnDocumentClick={false}
                                            trigger={
                                                <Button primary>
                                                    <Icon
                                                        name="document-text-outline"
                                                        style={{ marginRight: '0.5rem' }}
                                                    />
                                                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                        {t('create_payment_request')}
                                                    </span>
                                                </Button>
                                            }
                                            content={
                                                <PaymentRequestForm
                                                    connection={connection}
                                                    bankAccount={currentBankAccount}
                                                    setView={setView}
                                                />
                                            }
                                        />
                                    </Button.Group>
                                )}
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
            </>
        )
    }

    return (
        <Fragment>
            <BreadcrumbNav items={[{ name: t('payments'), icon: icons.PAYMENTS, href: '' }]} />
            <Ref innerRef={segmentRef}>
                <Container fluid>
                    <Segment
                        loading={loading || refetch}
                        style={{
                            padding: 0,
                            background: 'transparent',
                            boxShadow: 'none',
                            border: 'none',
                            marginBottom: '1rem',
                            marginTop: '2rem',
                        }}
                    >
                        {(loading || refetch) && (
                            <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                                {' '}
                                {t('message_performing_verification')}{' '}
                            </p>
                        )}

                        {/* If Connection is not found, then factoring service is not properly connected */}
                        {connection === null && !loading && !refetch && (
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    paddingTop: '2rem',
                                }}
                            >
                                <Icon name="exclamation circle" style={{ fontSize: '5rem', color: 'var(--danger)' }} />{' '}
                                <br />
                                <p style={{ marginBottom: '0.5rem' }}>
                                    {' '}
                                    {t('error_hint_message_no_active_connection_part_1')}{' '}
                                </p>
                                <p>
                                    {t('error_hint_message_no_active_connection_part_2')}{' '}
                                    <Link to={routes.INVOICES} style={{ fontWeight: 'bold' }}>
                                        <u> {t('error_hint_message_no_active_connection_part_3')}</u>
                                    </Link>
                                </p>
                            </div>
                        )}

                        {premiumConnection === null && connection !== null && !loading && !refetch && (
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    paddingTop: '2rem',
                                }}
                            >
                                <Icon name="exclamation circle" style={{ fontSize: '5rem', color: 'var(--danger)' }} />{' '}
                                <br />
                                <p style={{ marginBottom: '0.5rem' }}>
                                    {' '}
                                    {t('error_hint_message_no_active_bank_account_part_1')}{' '}
                                </p>
                                <Button
                                    onClick={() => handlePremiumConnection()}
                                    style={{ marginTop: '0.5rem' }}
                                    primary
                                    content={t('connect_your_bank_accounts')}
                                />
                                <p style={{ fontSize: '1rem', fontWeight: 'normal', marginTop: '1rem' }}>
                                    {' '}
                                    {t('error_hint_message_no_active_bank_account_part_2')}{' '}
                                </p>
                            </div>
                        )}

                        {bankAccounts.length === 0 &&
                            premiumConnection !== null &&
                            connection !== null &&
                            !loading &&
                            !refetch && (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        fontWeight: 'bold',
                                        paddingTop: '2rem',
                                    }}
                                >
                                    <Icon
                                        name="exclamation circle"
                                        style={{ fontSize: '5rem', color: 'var(--danger)' }}
                                    />{' '}
                                    <br />
                                    <p style={{ marginBottom: '0.5rem' }}>
                                        {' '}
                                        {t('error_hint_message_no_granted_access_to_bank_accounts')}{' '}
                                    </p>
                                    <CanView permissions={['integrations.c_view_all_bank_account_allowed_users']}>
                                        <Button
                                            onClick={() => history.push(routes.SETTINGS + 'finances')}
                                            style={{ marginTop: '0.5rem' }}
                                            primary
                                            content={t('manage_finance_access')}
                                        />
                                    </CanView>
                                </div>
                            )}

                        {/* If Connection is active, then render views */}
                        {connection !== null && premiumConnection !== null && !loading && !refetch && (
                            <div>
                                {currentBankAccount?.accountId && (
                                    <>
                                        <HeaderPanel />
                                        {view === 1 && (
                                            <Overview bank_account={currentBankAccount} connection={connection} />
                                        )}
                                        {view === 2 && (
                                            <Transactions bank_account={currentBankAccount} connection={connection} />
                                        )}
                                        {view === 3 && (
                                            <PaymentRequests
                                                bank_account={currentBankAccount}
                                                connection={connection}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </Segment>
                </Container>
            </Ref>
        </Fragment>
    )
}

export default Payments
