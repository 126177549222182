// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import { Form, Divider, Checkbox, Label } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const FillRemainingHoursForm = ({ day, contract, onClose, record, planRecord, setContracts, setAttendanceRecords }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [hours, setHours] = useState('')
    const [mode, setMode] = useState(1)

    const [breakPreferences, setBreakPreferences] = useState({
        firstBreak: 4,
        secondBreak: 8,
    })

    const [selectedBank, setSelectedBank] = useState('overtime')

    const handleSubmit = async () => {
        setProcessing(true)

        let start = mode === 1 ? record.start : createdStartTime()
        let end = mode === 1 ? createdEndTime() : record.end

        const hoursDiff = calculateHourDiff()
        let request = null
        if (!record.is_corrected && record.original_record?.id === undefined) {
            // so we are creating new correction record but do we convert absence as well??
            let absenceParams = {}
            if (record.absence_type !== null) {
                // is absence type vacation?? we should first increase fond and then when new record is created it will deduct
                absenceParams = {
                    absence_type: record.absence_type?.id,
                    vacation: parseFloat(record?.vacation || 0),
                    vacation_hours: record.vacation_hours,
                    paragraph: record.paragraph,
                    sick_day: record.sick_day,
                    absention: record.absention,
                    care_for_a_family_member: record.care_for_a_family_member,
                    family_member_paragraph: record.family_member_paragraph,
                }

                if (record.absence_type?.source === 'vacation') {
                    // handle return to available vacation fond
                    if (parseFloat(record.vacation) > 0) {
                        // eslint-disable-next-line
                        const vacationRestoreRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                            type: 7, // vacation fond
                            operation: 1, // increase
                            attendance_record: record.id,
                            amount: parseFloat(record?.vacation || 0),
                            contract: record.contract,
                        })
                    }
                }
            }

            request = await requests.post(API.ATTENDANCE_BASE + 'records/manual/', {
                date: record.date,
                start: hoursDiff > 0 ? start : null,
                end: hoursDiff > 0 ? end : null,
                // duration: hoursDiff === 0 ? 0 : duration,
                contract: record.contract,
                employee: record.employee,
                closed: true,
                is_corrected: true,
                ...absenceParams,
            })
        } else {
            request = await requests.patch(API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true', {
                start: hoursDiff > 0 ? start : null,
                end: hoursDiff > 0 ? end : null,
            })
        }

        if (request.status === 201 || request.status === 200) {
            let requestUpdate = null
            if (request.status === 200) {
                requestUpdate = {
                    status: 200,
                    response: request.response,
                }
            } else {
                requestUpdate = await requests.patch(
                    API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true',
                    {
                        correction: request.response.id,
                    }
                )
            }

            if (requestUpdate.status === 200) {
                // update contract bank + create history
                let latestContractVersion = null
                const updateBankOvertime = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: selectedBank === 'oversize' ? 1 : 6, // overtime or bank_hours
                    operation: 2, // decrease
                    attendance_record: record.id,
                    amount: parseFloat(hours),
                    contract: record.contract,
                })

                if (updateBankOvertime.status === 201) {
                    latestContractVersion = updateBankOvertime.response.contract
                }

                // fetch corrected record
                const requestCorrectedRecord = await requests.get(
                    API.ATTENDANCE_BASE + 'records/' + request.response.id + '/'
                )
                if (requestCorrectedRecord.status === 200) {
                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('hours_were_added_to_attendance_record'),
                        animation: 'pulse',
                        time: 2000,
                    })
                    // update contract banks
                    if (latestContractVersion !== null) {
                        setContracts((prev) =>
                            prev.filter((item) => {
                                if (item.id === latestContractVersion.id) {
                                    item['bank_overtime'] = latestContractVersion.bank_overtime
                                    item['bank_hours'] = latestContractVersion.bank_hours
                                    item['available_vacation_fond'] = latestContractVersion.available_vacation_fond
                                }
                                return item
                            })
                        )
                    }

                    // update attendancerecords listing to include corrected record
                    setAttendanceRecords((prev) =>
                        prev.map((item) => {
                            if (item.id === record.id) {
                                item = {
                                    id: requestCorrectedRecord.response.id,
                                    employee: requestCorrectedRecord.response.employee?.id,
                                    contract: requestCorrectedRecord.response.contract?.id,
                                    date: requestCorrectedRecord.response.date,
                                    start: requestCorrectedRecord.response.start,
                                    end: requestCorrectedRecord.response.end,
                                    night_work_hours: requestCorrectedRecord.response?.night_work_hours || 0,
                                    duration: requestCorrectedRecord.response?.work_hours || 0,
                                    duration_real: requestCorrectedRecord.response?.duration || 0,
                                    closed: requestCorrectedRecord.response.closed,
                                    vacation: requestCorrectedRecord.response.vacation,
                                    vacation_hours: requestCorrectedRecord.response.vacation_hours,
                                    paragraph: requestCorrectedRecord.response.paragraph,
                                    sick_day: requestCorrectedRecord.response.sick_day,
                                    absention: requestCorrectedRecord.response.absention,
                                    care_for_a_family_member: requestCorrectedRecord.response.care_for_a_family_member,
                                    family_member_paragraph: requestCorrectedRecord.response.family_member_paragraph,
                                    absence_type: requestCorrectedRecord.response.absence_type,
                                    original_record: requestCorrectedRecord.response.original_record,
                                    correction: requestCorrectedRecord.response.correction,
                                    is_corrected: requestCorrectedRecord.response.is_corrected,
                                    is_saturday: requestCorrectedRecord.response.is_saturday,
                                    is_sunday: requestCorrectedRecord.response.is_sunday,
                                    is_holiday: requestCorrectedRecord.response.is_holiday,
                                    week: moment(requestCorrectedRecord.response.date).isoWeek(),
                                }
                            }
                            return item
                        })
                    )
                    onClose()
                }
            } else {
                await requests.del(API.ATTENDANCE_BASE + 'records/' + request.response.id + '/')
            }
        }

        setProcessing(false)
    }

    const calculateHourDiff = () => {
        const diff = parseFloat(parseFloat(record.duration) + parseFloat(hours || 0)).toFixed(2)

        if (!isNaN(diff)) return parseFloat(diff)

        return 0
    }

    const createdEndTime = () => {
        let end = null
        const hourDiff = parseFloat(calculateHourDiff() || 0)
        const minDiff = hourDiff * 60
        const start = record.start ? moment(record.start, 'YYYY-MM-DD HH:mm:ss') : null

        let breakAddition = 0
        if (hourDiff >= parseFloat(breakPreferences.firstBreak)) {
            breakAddition += 30 // 30 min. break addition
        }

        if (parseFloat(breakPreferences.secondBreak) !== 0 && hourDiff >= parseFloat(breakPreferences.secondBreak)) {
            breakAddition += 15 // 15 min. second addition
        }

        if (parseFloat(minDiff) > 0) {
            end = start?.add(parseFloat(minDiff + breakAddition), 'minutes') || null
        }

        if (parseFloat(minDiff) === 0) {
            end = start
        }

        return end ? end.format('YYYY-MM-DD HH:mm') : null
    }

    const createdStartTime = () => {
        let start = null
        const hourDiff = parseFloat(calculateHourDiff() || 0)
        const minDiff = hourDiff * 60
        const end = record.end ? moment(record.end, 'YYYY-MM-DD HH:mm') : null

        let breakAddition = 0
        if (hourDiff >= parseFloat(breakPreferences.firstBreak)) {
            breakAddition += 30 // 30 min. break addition
        }

        if (parseFloat(breakPreferences.secondBreak) !== 0 && hourDiff >= parseFloat(breakPreferences.secondBreak)) {
            breakAddition += 15 // 15 min. second addition
        }

        if (parseFloat(minDiff) > 0) {
            start = end.subtract(parseFloat(minDiff + breakAddition), 'minutes')
        }

        if (parseFloat(minDiff) === 0) {
            start = end
        }

        return start ? start.format('YYYY-MM-DD HH:mm') : null
    }

    useEffect(() => {
        async function fetchPreferences() {
            const request = await requests.get(API.PREFERENCES + '?section=attendance_preferences')
            if (request.status === 200) {
                const preferences = request.response
                const firstBreakPref = preferences.find((item) => item.name === 'global_first_break_requirement')
                const secondBreakPref = preferences.find((item) => item.name === 'global_second_break_requirement')
                setBreakPreferences({
                    firstBreak: firstBreakPref !== undefined ? firstBreakPref.value : 4,
                    secondBreak: secondBreakPref !== undefined ? secondBreakPref.value : 8,
                })
            }
        }

        fetchPreferences()
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ cursor: 'pointer' }}>
                    <Checkbox
                        checked={selectedBank === 'overtime'}
                        onChange={() => setSelectedBank('overtime')}
                        style={{ marginRight: '0.5rem', position: 'relative', top: '0.1rem' }}
                    />
                    <strong>{t('bank_overtime')}</strong>: {contract.bank_overtime}{' '}
                    {t('hours_shortcut').toLocaleLowerCase()}.
                </label>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ cursor: 'pointer' }}>
                    <Checkbox
                        checked={selectedBank === 'hours'}
                        onChange={() => setSelectedBank('hours')}
                        style={{ marginRight: '0.5rem', position: 'relative', top: '0.1rem' }}
                    />
                    <strong>{t('bank_hours')}</strong>: {contract.bank_hours} {t('hours_shortcut').toLocaleLowerCase()}.
                </label>
            </div>

            {selectedBank === 'hours' && (
                <>
                    <Divider />
                    <Icon
                        name="information-circle-outline"
                        style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                    />{' '}
                    <span>{t('bank_hours_can_store_negative_values')}.</span>
                </>
            )}

            <Divider />

            <SuperField
                as="input"
                required
                value={hours}
                placeholder={t('enter_hours')}
                label={t('enter_number_of_hours_to_use')}
                onChange={(e, { value }) => setHours(value.replace(',', '.'))}
                error={
                    (hours <= 0 ||
                        isNaN(hours) ||
                        (parseFloat(hours) > parseFloat(contract.bank_overtime) && selectedBank === 'overtime')) &&
                    hours !== ''
                        ? t('invalid_entry')
                        : false
                }
            />

            <Form.Group>
                <SuperField
                    as="checkbox"
                    label={t('adjust_departure')}
                    checked={mode === 1}
                    onChange={() => setMode(1)}
                />
                <SuperField
                    as="checkbox"
                    label={t('adjust_arrival')}
                    checked={mode === 2}
                    onChange={() => setMode(2)}
                />
            </Form.Group>

            <p>
                <strong>{t('corrected_record')}:</strong>
                <br />
                {calculateHourDiff() > 0 && (
                    <>
                        {t('arrival')}:{' '}
                        {mode === 1 ? (
                            <strong>{record.start ? tzDateTime(record.start).format('HH:mm') : '--:--'}</strong>
                        ) : (
                            <strong>
                                {createdStartTime() !== null ? tzDateTime(createdStartTime()).format('HH:mm') : '--:--'}
                            </strong>
                        )}
                        <br />
                        {t('departure')}:{' '}
                        {mode === 1 ? (
                            <strong>
                                {createdEndTime() !== null ? tzDateTime(createdEndTime()).format('HH:mm') : '--:--'}
                            </strong>
                        ) : (
                            <strong>{record.end ? tzDateTime(record.end).format('HH:mm') : '--:--'}</strong>
                        )}
                        <br />
                    </>
                )}
                {t('working_hours')}:{' '}
                <strong>
                    {calculateHourDiff()} {t('hours_shortcut')}
                </strong>
            </p>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={processing}
                    disabled={
                        processing ||
                        hours <= 0 ||
                        isNaN(hours) ||
                        (parseFloat(hours) > parseFloat(contract.bank_overtime) && selectedBank === 'overtime') ||
                        hours === ''
                    }
                />
            </Form.Field>
        </Form>
    )
}

export default FillRemainingHoursForm
