import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { formatPhoneNumber } from '@helpers/functions'
import { isEmailValid, isPhoneNumberValid } from '@helpers/validation'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import EmailField from '@components/forms/common/EmailField'
import PhoneField from '@components/forms/common/PhoneField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Divider, Header, Button, Form, Message } from 'semantic-ui-react'

const ContactList = ({ data, setData, isCompany }) => {
    const { t } = useTranslation()

    const onDelete = async (id) => {
        const request = await requests.del(API.CONTACTS + 'records/' + id + '/')
        if (request.status === 204) {
            setData((prev) => ({
                ...prev,
                contacts: prev.contacts.filter((contact) => contact.id !== id),
            }))
        }
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Header as="h3" content={t('contacts')} style={{ marginBottom: 0 }} />
                <CanView permissions={['contacts.c_manage_contacts']}>
                    <SuperDuperModal
                        size="tiny"
                        trigger={
                            <Button basic size="small" style={{ fontWeight: 'bold' }}>
                                <Icon name={'add-outline'} style={{ marginRight: '0.5rem' }} />
                                <span style={{ position: 'relative', top: '-0.2rem' }}>{t('add_contract')}</span>
                            </Button>
                        }
                        content={<ContactForm data={data} setData={setData} isCompany={isCompany} />}
                    />
                </CanView>
            </div>
            <Divider />
            <div>
                {data.contacts.length === 0 && <span style={{ opacity: 0.8 }}>{t('no_data')}</span>}
                {data.contacts.map((contact, idx) => (
                    <div key={idx}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                itemsAlign: 'start',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                {contact.contact_person_name !== '' && (
                                    <strong>
                                        {contact.contact_person_name} <br />
                                    </strong>
                                )}
                                {contact.contact_company_name !== '' && (
                                    <strong>
                                        {contact.contact_company_name} <br />
                                    </strong>
                                )}
                                {contact.phone !== '' ? (
                                    <>
                                        {formatPhoneNumber(contact.phone)} <br />
                                    </>
                                ) : (
                                    ''
                                )}
                                {contact?.email !== '' ? <>{contact?.email} </> : ''}
                                {contact?.is_invoicing && (
                                    <div
                                        style={{
                                            opacity: 0.8,
                                            fontSize: '0.9rem',
                                            fontWeight: 'bold',
                                            marginTop: '0.2rem',
                                            color: 'var(--primary)',
                                        }}
                                    >
                                        {t('used_for_invoicing')}
                                    </div>
                                )}
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        opacity: 0.5,
                                        marginRight: '1rem',
                                        position: 'relative',
                                        top: '-0.1rem',
                                    }}
                                >
                                    #{idx + 1}.
                                </span>
                                <CanView permissions={['contacts.c_manage_contacts']}>
                                    <SuperDuperModal
                                        size="tiny"
                                        trigger={
                                            <Icon
                                                name={'pencil-outline'}
                                                style={{ marginRight: '1rem', cursor: 'pointer', color: 'var(--dark)' }}
                                            />
                                        }
                                        content={
                                            <ContactForm
                                                data={data}
                                                contact={contact}
                                                setData={setData}
                                                isCompany={isCompany}
                                            />
                                        }
                                    />
                                </CanView>
                                <CanView permissions={['contacts.c_delete_contacts']}>
                                    <Icon
                                        onClick={() => onDelete(contact.id)}
                                        name={'close-outline'}
                                        style={{ marginRight: '1rem', cursor: 'pointer', color: 'var(--danger)' }}
                                    />
                                </CanView>
                            </div>
                        </div>
                        <Divider />
                    </div>
                ))}
            </div>
        </>
    )
}

const ContactForm = ({ contact, setData, data, isCompany, onClose }) => {
    const { t } = useTranslation()

    const [form, setForm] = useState({
        id: contact?.id || 0,
        phone: contact?.phone || '',
        email: contact?.email || '',
        is_primary: contact?.is_primary || false,
        is_invoicing: contact?.is_invoicing || false,
        contact_person_name: contact?.contact_person_name || contact?.contact_company_name || '',
    })

    const handleSubmit = async () => {
        const patchURL = isCompany ? API.COMPANIES : API.COMPANIES + 'units/'
        if (contact?.id === undefined) {
            const request = await requests.patch(patchURL + data.id + '/', {
                contacts: {
                    create: [
                        {
                            phone: form.phone,
                            email: form.email,
                            is_primary: form.is_primary,
                            is_invoicing: form.is_invoicing,
                            contact_person_name: form.contact_person_name,
                            contact_company_name: '',
                        },
                    ],
                },
            })

            if (request.status === 200) {
                setData((prev) => ({ ...prev, contacts: request.response.contacts }))
            }
        } else {
            const request = await requests.patch(patchURL + data.id + '/', {
                contacts: {
                    update: {
                        [contact.id]: {
                            phone: form.phone,
                            email: form.email,
                            is_primary: form.is_primary,
                            is_invoicing: form.is_invoicing,
                            contact_person_name: form.contact_person_name,
                            contact_company_name: '',
                        },
                    },
                },
            })

            if (request.status === 200) {
                setData((prev) => ({ ...prev, contacts: request.response.contacts }))
            }
        }
        onClose()
    }

    return (
        <Form>
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon
                            name="information-circle"
                            style={{ marginRight: '0.5rem', fontSize: '1.2rem', position: 'relative', top: '0.2rem' }}
                        />
                        {t('contact_form_hint')}.
                    </strong>
                }
            />
            <PhoneField
                hideType
                placeholder={t('phone')}
                value={form.phone}
                setValue={(e, { value }) => setForm((prev) => ({ ...prev, phone: value }))}
            />

            <EmailField
                label={t('email')}
                placeholder={t('email')}
                value={form.email}
                setValue={(e, { value }) => setForm((prev) => ({ ...prev, email: value }))}
            />
            <SuperField
                as="input"
                label={t('contact_person_name')}
                value={form.contact_person_name}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, contact_person_name: value }))}
            />

            <SuperField
                as="checkbox"
                label={t('used_for_invoicing')}
                checked={form.is_invoicing}
                onChange={() => setForm((prev) => ({ ...prev, is_invoicing: !form.is_invoicing }))}
            />

            {/* <SuperField as="checkbox"
                label={t('used_as_primary')}
                checked={form.is_primary}
                onChange={() => setForm(prev => ({...prev, is_primary: !form.is_primary}))}
            /> */}
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <Button
                    primary
                    type="button"
                    onClick={() => handleSubmit()}
                    disabled={
                        (form.phone === '' && form.email === '') ||
                        (!isEmailValid(form.email) && form.email !== '') ||
                        (!isPhoneNumberValid(form.phone) && form.phone !== '')
                    }
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default ContactList
